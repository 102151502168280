import {IDataSourceItem, IDemographics, IPriority, IPriorityResult, IResultRecord} from './interfaces';

export const convertSources = (sources: string[]): IDataSourceItem[] => {
  return sources.map((label: string) => {
    return {
      label: label,
      id: label,
    };
  });
};


export const createDemoFromAssessment = (data: IResultRecord): IDemographics => {
  const demo : IDemographics = {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    title: data.title,
    companyName: data.companyName,
    number_of_employees: data.number_of_employees,
    number_of_csms: data.number_of_csms,
    industry: data.industry,
    users_of_gainsight: data.users_of_gainsight,
    teams_using_gainsight: data.teams_using_gainsight,
    consent: data.consent,
  };
  return demo;
};

// export function generatePrioritiesFromKeys(keys: string[]): IPriority[] {
//   const returnPriorities: IPriority[] = [];
//   keys.forEach((key) => {
//     if (key in priorities) {
//       returnPriorities.push(priorities[key]);
//     }
//   });
//   return returnPriorities;
// }

/**
 * order priorities based on user input
 * @param {string[]} results
 * @return {IPriorityResult[]} priorities
 */
// export const computePriorityResults = (results: string[]): IPriorityResult[] => {
//   const mergedResults : IPriorityResult[] = results.map((result, i) => {
//     const p = prioritiesAsMap[result];
//     const priority = p.label as PRIORITY;
//     return {
//       priority,
//       label: p.label,
//       yourScore: i+1,
//       color: p.color,
//       peerScore: PeerScores[priority],
//     };
//   });
//   return mergedResults;
// };
