import React from 'react';
import {PageWrapper} from '@components/PageWrapper';

import {Title, Subtitle, Wrapper, LI1, P, OL, LI, BOLD, UL} from './Styles';
import {COLORS} from '@constants/style';

export const Privacy = () => (
  <PageWrapper shadow color={COLORS.WHITE}>
    <Wrapper>
      <Title>Privacy</Title>

      <Subtitle>INTRODUCTION</Subtitle>

      <P>At Dev Garage LLC (the &#39;Company&#39; or &#39;we&#39; or &#39;us&#39;) we are committed to
      protecting your privacy. This privacy policy (&#39;Privacy Policy&#39;) outlines our data
      collection, processing and usage practices. It also describes your ability to
      manage access and correction of your personal information.</P>

      <P>The Company operates the website (<a href="https://gainsight.valueplan.io">gainsight.valueplan.io</a>), additional
      sites connecting to this Privacy Policy (collectively, the &#39;Site&#39;), and our related
      services, including without limitation any web and mobile applications that we
      own or control and which posts or links to this Privacy Policy now existing or
      implemented in the future (the &#39;Services&#39;). This Privacy Policy covers all
      Personal Information collected by the Company from our customers and other
      visitors to the Site.</P>

      <P>By using the Site or the Services, you agree to the terms of this Privacy Policy and
      you expressly consent to the processing of your information in accordance with
      this Privacy Policy. This Privacy Policy covers all data collected by the Company
      through the Site or the Services, but is primarily intended to clarify how we
      collect and use information and through the Site.</P>

      <P>This Privacy Policy also explains how we process &#39;personal data&#39; about people in
      the European Union (&#39;EU&#39;), as required under the General Data Protection
      Regulation (&#39;GDPR&#39;). For people in California, we have also prepared a
      Supplemental Privacy Statement for California Consumers under CCPA
      (&#39;California Statement&#39;) in compliance with the California Consumer Privacy
      Act of 2018 (&#39;CCPA&#39;). Please review our California Statement attached below for
      more information.</P>

      <P>Please read this Privacy Policy carefully to understand our policies and practices
      regarding your information and how we will treat it. If you do not agree with our
      policies and practices, do not use any part of the Site or Services. We will review
      this policy regularly, and we may update it from time to time.</P>
      <ol style={{counterReset: 'counter'}}>

        <LI1>
          <BOLD>When You Visit our Site.</BOLD>
          <P>When you visit the Site, you are free to view the Site without providing any
            Personal information. In order to use the Services you may be required to
            provide certain Personal Information about yourself. Each time you visit the
            Site we may collect navigational Information on our Site.</P>
        </LI1>

        <LI1>
          <BOLD>Definitions.</BOLD>
          <OL>
            <LI>
              <P><BOLD>Personal Information - </BOLD>Personal Information means data,
              including personally identifiable information, about a Data Subject.</P>
            </LI>
            <LI>
              <P><BOLD>Usage Data - </BOLD>Usage Data is data collected automatically either
              generated by the use of the Site or from the Site&apos;s infrastructure itself (for
              example, the duration of a page visit).</P>
            </LI>
            <LI>
              <P><BOLD>Cookies - </BOLD>Cookies are small pieces of data stored on your device
              (computer or mobile device).</P>
            </LI>
            <LI>
              <P><BOLD>Data Controller - </BOLD>Data Controller means the person who (either
              alone or jointly or in common with other persons) determines the purposes for
              which and the manner in which any Personal Information are, or are to be,
              processed.</P>
              <P>For the purpose of this Privacy Policy, we are the Data Controller of your
              Personal information. You can contact us via email at privacy@dgarage.io.</P>
            </LI>
            <LI>
              <P><BOLD>Data Processors</BOLD> (or Service Providers) - Data Processor (or
            Service Provider) means any natural or legal person who processes the data on
            behalf of the Data Controller.</P>

              <P>We may use the services of various Service Providers in order to process
            your data more effectively.</P>
            </LI>
            <LI>
              <P><BOLD>Data Subject</BOLD> (or User) - Data Subject is any identified or
                identifiable individual who is using our Site and is the subject of Personal
                Information.</P>
            </LI>
          </OL>
        </LI1>

        <LI1>
          <BOLD>Information Collection and Use.</BOLD> We collect several different types of
          information for various purposes to provide and improve our service to you.
        </LI1>

        <LI1>

          <BOLD>Types of Data Collected.</BOLD>
          <OL>
            <LI>
              <P>
                <BOLD>Personal Information.</BOLD> We collect your Personal Information
            when you provide it to us. For example, we collect Personal Information when
            you communicate with us via email, when you enter your email address or login
            to access your account on the Site, when you complete any forms or provide other
            information on the Site or when you respond to our communications or requests
            for information. Personal Information may include, but is not limited to, your
            name, e-mail address, physical address, phone number, company and
            employment position. We may also automatically collect certain Personal
            Information when you use, access, or interact with our Site as described below.
              </P>
              <P>
            We may invite comments, questions, or feedback on certain public areas of
            the Site. If you provide information in those public areas of the Site, such
            information may be read, collected, and used by us and other users of the Site. If
            you provide information directly to us in other ways, such as by sending us an
            email, we may retain that information and add it with the other information we
            have collected from you.
              </P>
              <P>
            We may use your Personal information to contact you with newsletters,
            marketing materials and other information that may be of interest to you.
              </P>
            </LI>
            <LI>
              <P><BOLD>Usage Data.</BOLD> We may also collect information how the Site is
          accessed and used. This Usage Data may include information such as your
          computer&#39;s Internet Protocol address (e.g. IP address), browser type, browser
          version, the pages of the Site that you visit, the time and date of your visit, the
          time spent on those pages, unique device identifiers and other diagnostic data.
              </P>
            </LI>
            <LI><P>
              <BOLD> Types of Information Collected by Technology.</BOLD> We may
          receive and store certain types of information from you each time you use the
          Site. For example, we may automatically log your language preference, precise
          geographic location, the date and time of your visit to the Site, any actions you
          take on the Site, the date and time of your visit to specific listings on the Site,
          your Internet Protocol (IP) address, and the website you visited before our Site.
          In addition, we may receive and store information about your use of the Site. The
          technologies we use for this automatic data collection may include:
            </P>
            <UL>
              <LI>
                <P><BOLD>Cookies.</BOLD> Cookies are small data files stored on your hard drive by a
              website. Cookies are sent to your browser from a Site and stored on your
              device. Among other things, cookies help us improve our Site and improve
              your experience with our Site. Tracking technologies also used are beacons,
              tags, and scripts to collect and track information and to improve and
              analyze the Site.</P>

                <P>We may use both session cookies (which expire once you close your web
                browser) and persistent cookies (which stay on your computer until you
                delete them). You can set your browser to not accept cookies. Also, please
                be aware that if you visit other websites you may be required to accept
                cookies. The use of cookies by third parties is not covered by our Privacy
                Policy.</P>
              </LI>
              <LI>
                <BOLD>Web Beacons.</BOLD> Web beacons are electronic images that may be used on
              our Site or in our emails. We use web beacons to deliver cookies, count
              visits and understand usage.
              </LI>
              <LI>
                <BOLD>Clear Gifs.</BOLD> We may also use clear gifs or other data files or identifiers to
              collect information for statistical purposes and to improve and develop the
              Site. Clear gifs are tiny graphics with a unique identifier, similar in function
              to cookies. In contrast to cookies, which are placed on a hard drive, clear
              gifs are embedded invisibly on web pages.
              </LI>
            </UL>
            </LI>
            <LI>
              <P><BOLD>Analytics:</BOLD> We may use third-party Service Providers to monitor
            and analyze the use of our Site. The Service Providers use cookies to collect
            anonymous traffic data to help use analyze how users use the Site. The
            information generated by a cookie about your use of the Site (including your IP
            address) will be transmitted to and stored by the Service Providers on servers in
            the United States. The Service Provider may will use this information for the
            purpose of evaluating your use of the Site, compiling reports on Site activity for
            us and providing other services relating to Site activity and internet usage. The
            Service Provider may also transfer this information to third parties where
            required to do so by law, or where such third parties process the information on
            the Service Provider&quote;s behalf. By using the Site, you consent to the processing of
            data about you by Service Provider in the manner and for the purposes described
            in this Privacy Policy.</P>

              <P>You can opt-out of having made your activity on the Service available to a Service
            Provider. For more information on opting out, please contact the Service
            Provider directly.</P>
            </LI>
            <LI>
              <BOLD>Do Not Track.</BOLD> Some web browsers and devices permit you to
          broadcast a preference that you not be “tracked” online. At this time the
          Company does not modify your experience based upon whether such a signal is
          broadcast.
            </LI>
          </OL>
        </LI1>

        <LI1>
          <BOLD>Use of Your Data.</BOLD> In general, information you submit to us is to operate,
          provide or improve the Services, to respond to requests that you make or to aid us
          in serving you better. The Company uses and may use your information: to
          provide improved administration of our Site; to improve the quality of your
          experience when you interact with our Site; to send you e-mail notifications; to
          respond to your inquiries; to send Company updates and for other marketing
          purposes of the Company. We may create anonymous or aggregated data records
          from your information by excluding information that makes the data personally
          identifiable to you (such as your name) or by compiling information about you
          with information from other users. We use this anonymous or aggregated data to
          analyze request and usage patterns so that we may enhance the content of our
          Site and improve Site navigation.  We reserve the right to use and disclose
          anonymous and aggregate data to third party companies for any purpose in our
          sole discretion. If you provide feedback on our Site to us, we may use such
          feedback for any purpose, provided we will not associate such feedback with your
          information.
        </LI1>

        <LI1>
          <BOLD>Disclosure of Your Information.</BOLD>

          <OL>
            <LI><BOLD>Disclosures.</BOLD> Regardless of any choices you make regarding your
              information (as described below), the Company may disclose information if it
              believes in good faith that such disclosure is necessary to (a) comply with relevant
              laws or to respond to subpoenas or warrants served on the Company; or (b)
              protect or defend the rights or property of the Company or any other party.
            </LI>
            <LI><BOLD>Disclosure to Third Parties.</BOLD> We may share your information
            with third party service providers that are contractually bound to safeguard your
            information or with third party business partners to offer you certain products or
            services or for their promotional or marketing purposes, as permitted by law. We
            may make such disclosure in order to provide you with Company updates and
            invitations to Company events. We may also share your information with any
            other party with your express consent.</LI>
            <LI><BOLD>Third Party Websites.</BOLD> We may link to or frame third party
            websites. Our provision of a link to or frame of any other website or location is for
            your convenience and does not signify our endorsement of such other website or
            location or its contents. When you click on such a link, you will leave our Site and
            go to another site. If we display third party websites through a frame, you are on
            another site. During this process, another entity may collect information from
            you. We have no control over, do not review, and cannot be responsible for, these
            outside websites or their content or their privacy practices. Please be aware that
            the terms of this Privacy Policy do not apply to these outside websites or content,
            or framed websites, or to any collection of data after you click on links to such
            outside websites.</LI>
            <LI><BOLD>Use of Navigational Information.</BOLD> We may use Navigational Information to operate
            and improve the Site.</LI>
            <LI><BOLD>Testimonials and Comments.</BOLD> We may post customer
            testimonials and comments on our Site, which may contain Personal
            Information. We will obtain each customer&#39;s consent via email prior to posting
            the customer&#39;s name and testimonial.</LI>
            <LI><BOLD>Security of your Personal Information.</BOLD> The Site is hosted by
            Google. Please review the privacy policy to understand security
            protocols for the host – you can access the <a href="https://firebase.google.com/support/privacy">Google privacy policy here</a>
            &nbsp; and the <a href="https://www.godaddy.com/agreements/privacy">GoDaddy cookie policy here</a>. We are committed to protecting
            the security of your information. We use a variety of industry-standard security technologies
            and procedures to help protect your information from unauthorized access, use,
            or disclosure. but remember that no method of transmission over the Internet, or
            method of electronic storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal information, we cannot guarantee its
            absolute security.</LI>
          </OL>
        </LI1>

        <LI1>
          <BOLD>Your Choices Regarding Your Information.</BOLD>

          <OL>
            <LI><BOLD>Changes to Information.</BOLD> You may change any of your
            information in your account by sending an e-mail to us at privacy@dgarage.io.
            You may request deletion of your information by us, but please note that we may

            be required (by law or otherwise) to keep this information and not delete it (or to
            keep this information for a certain time, in which case we will comply with your
            deletion request only after we have fulfilled such requirements). When we delete
            any information, it will be deleted from the active database, but may remain in
            our archives.</LI>
            <LI><BOLD>To Unsubscribe from Our Communications.</BOLD> You may unsubscribe from our marketing
              communications by sending us email us at privacy@dgarage.io.
            </LI>
          </OL>
        </LI1>

        <LI1>
          <BOLD>Links to Other Site.</BOLD> Our Site may contain links to other sites that are
          not operated by us. If you click on a third-party link, you will be directed to that
          third party&#39;s site. We strongly advise you to review the privacy policy of every site
          you visit.
        </LI1>

        <LI1>
          <BOLD>Legal Basis for Processing Personal Information Under General Data Protection Regulation (GDPR)</BOLD>


          <OL>
            <LI><P>If you are from the European Economic Area (“EEA”), the
            Company&apos;s legal basis for collecting and using the Personal Information
            described in this Privacy Policy depends on the Personal Information we collect
            and the specific context in which we collect it.</P>
            <ul>
              <p>The Company may process your Personal Information because:</p>
              <li> We need to perform a contract with you.</li>
              <li>You have given us permission to do so.</li>
              <li>The processing is in our legitimate interests and it is not overridden by your rights.</li>
              <li>To comply with the law.</li>
            </ul>
            <P>The Company will retain your Personal Information only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will retain and use
            your Personal Information to the extent necessary to comply with our legal
            obligations (for example, if we are required to retain your data to comply with
            applicable laws), resolve disputes, and enforce our legal agreements and policies.</P>
            <P>The Company will also retain Usage Data for internal analysis purposes.</P>
            </LI>
            <LI>
              <P><BOLD>Transfer of Data.</BOLD> Your information, including Personal
              Information, may be transferred to, and maintained on, computers located
              outside of your state, province, country or other governmental jurisdiction where
              the data protection laws may differ than those from your jurisdiction.</P>
              <P>
              If you are located outside of the United States and choose to provide
              information to us, please note that we transfer the data, including Personal
              Information, to the United States and process it there.
              </P>
              <P>
              Your consent to this Privacy Policy followed by your submission of such
              information represents your agreement to that transfer.
              </P>
              <P>
              The Company will take all steps reasonably necessary to ensure that your
              data is treated securely and in accordance with this Privacy Policy and no transfer
              of your Personal Information will take place to an organization or a country
              unless there are adequate controls in place including the security of your data and
              other Personal Information.
              </P>
            </LI>
            <LI><P style={{fontSize: '1.2rem', fontWeight: 600}}>Disclosure of Data</P>
              <P>Legal Requirements</P>
              <P>The Company may disclose your Personal Information in the good faith
                belief that such action is necessary to:</P>
              <ul>
                <li>To comply with a legal obligation</li>
                <li>To protect and defend the rights or property of the Company</li>
                <li>To prevent or investigate possible wrongdoing in connection with the Site</li>
                <li>To protect the personal safety of users of the Site or the public</li>
                <li>To protect against legal liability</li>
              </ul>
            </LI>
            <LI> <P><BOLD>Your Data Protection Rights Under General Data Protection Regulation (GDPR).</BOLD>
          &nbsp;If you are a resident of the EEA, you have certain data protection rights. The Company
          aims to take reasonable steps to allow you to correct, amend, delete, or limit the use
          of your Personal Information. If you wish to be informed what Personal Information we
          hold about you and if you want it to be removed from our systems, please contact us at
          privacy@dgarage.io.</P>

            <P>In certain circumstances, you have the following data protection rights:</P>
            <ul>
              <li>The right to access, update or to delete the information we have on you. If
                you are unable to perform these actions yourself, please contact us to assist you at
                privacy@dgarage.io.</li>
              <li>The right of rectification. You have the right to have your information
                rectified if that information is inaccurate or incomplete.
                The right to object. You have the right to object to our processing of your
                Personal Information.</li>
              <li>The right of restriction. You have the right to request that we restrict the
                processing of your Personal Information.</li>
              <li>The right to data portability. You have the right to be provided with a copy
                of the information we have on you in a structured, machine-readable and
                commonly used format.</li>
              <li>The right to withdraw consent. You also have the right to withdraw your
                consent at any time where the Company relied on your consent to process your
                Personal Information.</li>
            </ul>


            <P>Please note that we may ask you to verify your identity before responding
          to such requests.</P>

            <P>Please note that there are circumstances in which we may not be required
          to provide you with the information contained in this Privacy Policy, for example
          if you already have the information.</P>

            <P>In addition, where we obtain Personal Information about you from a
          source other than yourself, we are not required to provide you with certain
          information contained in this Privacy Policy if:</P>

            <ul>
              <li>You already have the information</li>
              <li>Providing you with the information would prove impossible or would
          involve a disproportionate effort</li>
              <li>We are under an EU or EU member state law obligation to obtain or
          disclose the information which provides appropriate measures to protect your
          legitimate interests</li>
              <li>We are obliged to keep the information confidential as a result of an
          obligation of professional secrecy regulated by EU or EU member state law
          You have the right to complain to a Data Protection Authority about our
          collection and use of your Personal Information. For more information, please
          contact your local data protection authority in the EEA.</li>
            </ul>
            </LI>
          </OL>
        </LI1>

        <LI1><BOLD>Supplemental Privacy Statement for California Consumers under CCPA</BOLD>

          <OL>
            <LI>
              <P style={{fontSize: '1.2rem', fontWeight: 600}}>Introduction.</P>
              <P>Our business proudly serves or will serve certain natural persons residing
              in the State of California (each, a “consumer”). This California Statement explains
              rights that California consumers may have under the CCPA.</P>

              <P>When we use the term “Personal Information” in this supplemental
              California Statement, we are using that term as CCPA defines it, which CCPA
              generally defines “Personal Information” to mean information that identifies,
              relates to, describes, is capable of being associated with, or could reasonably be
              linked, directly or indirectly, with a particular consumer or household. However,
              Personal Information does not include publicly available, deidentified, or aggregate
              consumer information (which are all defined in CCPA). Notably, the
              definition of “Personal Information” also does not apply to the collection of
              Personal Information from job applicants, employees – whether you are our
              employee or any employee of the entity arranging access to our Services for you,
              business owners, directors, officers, staff, or contractors.</P>
            </LI>
            <LI><BOLD>Categories of Personal Information we collect, the sources
            of that information, purposes for why we collect it, who we share it
            with, and whether we sell that Personal Information.</BOLD> Where we act as a
            “business” under CCPA (meaning we determine the manner and reasons for why
            we process your Personal Information), we may be required to disclose additional
            information regarding the categories of Personal Information we collect, the
            sources where we obtain that information, the purposes for why we collect that
            information, who we share that information with, as well as whether we sell that
            Personal Information – all of which depends on the specific Service.</LI>

            <LI>
              <P style={{fontSize: '1.2rem', fontWeight: 600}}>Your privacy rights</P>
              <P>If you are a resident of California and are not a job applicant,
              employee/contractor, or employee/contractor of another company interacting
              with us in your job role, you have the right to request what information we
              collect, use, and disclose. You also have the right to request that we delete your
              information.</P>

              <P>To make a request, you can contact us at privacy@dgarage.io. Provide us
              enough information to verify your identify. We will use information you provide
              to us to verify your request. If we cannot initially verify your identity, we may
              request additional information to complete the verification process, such as, for
              example, a copy of your driver’s license and/or a recent utility or credit card bill.
              You can designate an agent to make a request on your behalf by either: (1) having
              your agent send us a letter, signed by you, certifying that the agent is acting on
              your behalf and showing proof that they are registered with the California
              Secretary of State; or (2) by you and the agent executing and sending us a
              notarized power of attorney stating that the agent is authorized to act on your
              behalf. Please note that we may still require you to verify your identity before we
              process a request submitted by your agent.</P>

              <P>California residents also have certain rights regarding the sale of Personal
              Information. California residents have the right to opt out of the sale of their
              Personal Information by contacting us at privacy@dgarage.io.</P>

              <P>We will not discriminate against you because you have exercised any of
              your privacy rights under the CCPA.</P>
            </LI>
          </OL>
        </LI1>

        <LI1>
          <BOLD>Children&#39;s Privacy</BOLD>
          <P>Our Site does not address anyone under the age of 18 (“Children”).</P>
          <P>We do not knowingly collect personally identifiable information from anyone
          under the age of 18. If you are a parent or guardian and you are aware that your
          Children has provided us with Personal information, please contact us. If we
          become aware that we have collected Personal information from children without
          verification of parental consent, we take steps to remove that information from
          our servers.
          </P>
        </LI1>

        <LI1>
          <BOLD>Change to This Privacy Policy</BOLD>
          <P>We may update our Privacy Policy from time to time. We will notify you of any
          changes by posting the new Privacy Policy on this page.</P>
          <P>We will let you know via email and/or a prominent notice on our Site, prior to the
          change becoming effective and update the “effective date” at the top of this
          Privacy Policy.</P>
          <P>You are advised to review this Privacy Policy periodically for any changes.
          Changes to this Privacy Policy are effective when they are posted on this page.</P>
        </LI1>

        <LI1>
          <P>
            <BOLD>Dispute Resolution.</BOLD> If you have any questions or concerns, please
            contact the Company by e-mail at privacy@dgarage.io. We will do our best to
            address your concerns. If you feel that your complaint has been addressed
            incompletely, we invite you to let us know for further investigation. If you and the
            Company are unable to reach a resolution to the dispute, you and the Company
            will settle the dispute exclusively under the rules of the American Arbitration
            Association (<a href="https://www.adr.org">www.adr.org</a>) as set forth in the Terms of Use found at&nbsp;
            <a href="/terms/">gainsight.valueplan.io/terms/</a>.</P>
        </LI1>

        <LI1>
          <P>
            <BOLD>Questions.</BOLD> If you have any questions or concerns, please contact the
          Company by e-mail at privacy@dgarage.io. We will do our best to address your
          concerns. If you feel that your complaint has been addressed incompletely, we
          invite you to let us know for further investigation.</P>
        </LI1>

      </ol>

    </Wrapper>
  </PageWrapper>
);
