import React from 'react';
import styled from 'styled-components';


interface AppProps {
    color?: string;
    fill?: boolean;
    size?: 'sm' | 'lg';
}

const BullsEye = ({size='sm', ...props}: AppProps) => {
  const Sizes = {
    sm: <Small {...props}/>,
    lg: <Large {...props}/>,
  };

  return Sizes[size];
};

const Small = ({color = 'black', fill=false, size='sm', ...props}: AppProps) => {
  const w = 27;
  const h = w;
  const cx = w/2 - 1;
  const cy = h/2 - 1;

  return (
    <SVGWrapper
      width={w}
      height={h}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={cx} cy={cy}
        fill="none"
        r={10}
        stroke={color}
        strokeWidth={5}
      />

      {fill && (
        <circle cx={cx} cy={cy}
          fill="white"
          r={10}
          stroke={color}
          strokeWidth={5}
        />
      )}

      <circle cx={cx} cy={cy}
        fill="none"
        r={5}
        stroke={color}
        strokeWidth={2}
      />
      <circle cx={cx} cy={cy}
        fill={color}
        r={2}
      />
    </SVGWrapper>
  );
};

const Large = ({color = 'black', fill=false, size='sm', ...props}: AppProps) => (
  <SVGWrapper
    width={46}
    height={46}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={23} cy={23}
      fill="none"
      r={19}
      stroke={color}
      strokeWidth={8}
    />

    <circle cx={23} cy={23}
      fill="white"
      r={19}
      stroke={color}
      strokeWidth={8}
    />


    <circle cx={23} cy={23}
      fill="none"
      r={9}
      stroke={color}
      strokeWidth={4}
    />

    <circle cx={23} cy={23}
      fill={color}
      r={3}
    />
  </SVGWrapper>
);

const SVGWrapper = styled.svg`
  padding: 0;
  margin: 0;
`;

export default BullsEye;
