import React from 'react';

import {COLORS} from '@constants/style';

const Download = ({color=COLORS.WHITE, ...props}: {color?: string, style?: any}) => (
  <svg width="96" height="102" viewBox="0 0 96 102" fill="none" {...props}>
    <path d="M90 90H6C2.68629 90 0 92.6863 0 96C0 99.3137 2.68629 102 6 102H90C93.3137 102 96 99.3137 96 96C96 92.6863 93.3137 90 90 90Z" fill={color}/>
    <path d="M0 84L0 96C0 99.3137 2.68629 102 6 102C9.31371 102 12 99.3137 12 96L12 84C12 80.6863 9.31371 78 6 78C2.68629 78 0 80.6863 0 84Z" fill={color}/>
    <path d="M84 84V96C84 99.3137 86.6863 102 90 102C93.3137 102 96 99.3137 96 96V84C96 80.6863 93.3137 78 90 78C86.6863 78 84 80.6863 84 84Z" fill={color}/>
    <path d="M47.9999 72C46.756 72.0095 45.5399 71.6321 44.5199 70.92L20.5199 54C19.2264 53.0824 18.3488 51.69 18.079 50.1272C17.8091 48.5643 18.169 46.9583 19.0799 45.66C19.5347 45.0111 20.1134 44.4587 20.7828 44.0347C21.4521 43.6106 22.1989 43.3234 22.9799 43.1895C23.7608 43.0556 24.5606 43.0777 25.333 43.2546C26.1054 43.4315 26.8351 43.7596 27.4799 44.22L47.9999 58.56L68.3999 43.2C69.673 42.2452 71.2732 41.8353 72.8485 42.0603C74.4238 42.2854 75.8452 43.127 76.7999 44.4C77.7547 45.6731 78.1647 47.2732 77.9396 48.8486C77.7146 50.4239 76.873 51.8452 75.5999 52.8L51.6 70.8C50.5614 71.579 49.2982 72 47.9999 72V72Z" fill={color}/>
    <path d="M48 60C46.4087 60 44.8826 59.3679 43.7574 58.2426C42.6321 57.1174 42 55.5913 42 54V6C42 4.4087 42.6321 2.88258 43.7574 1.75736C44.8826 0.632141 46.4087 0 48 0C49.5913 0 51.1174 0.632141 52.2426 1.75736C53.3679 2.88258 54 4.4087 54 6V54C54 55.5913 53.3679 57.1174 52.2426 58.2426C51.1174 59.3679 49.5913 60 48 60Z" fill={color}/>
  </svg>
);

export default Download;
