import {createSlice} from '@reduxjs/toolkit';
import {postAssessmentAsync, postDemoAsync, loadAssessmentByUUIDAndEmail, initAsync} from '@services/store/thunks';

export enum LOADING_STATES {
  NOTSTARTED,
  STARTED,
  COMPLETED,
  FAILED
}

const initialState = {
  isInitialized: false,
  progress: 0,
  isLoading: LOADING_STATES.NOTSTARTED,
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    init: (state, {payload}) => {
      state.isInitialized = payload;
    },
    updateProgress: (state, {payload}) => {
      state.progress = payload;
    },

  },
  extraReducers: {
    [initAsync.pending as any]: (state, action) => {
      // console.log('mainSlice', 'initAsync pending');
    },
    [initAsync.fulfilled as any]: (state, action) => {
      // console.log('mainSlice', 'initAsync fulfilled');
      state.isInitialized = true;
    },
    [initAsync.rejected as any]: (state, action) => {
      console.log({state}, {action});
      console.log('mainSlice', 'initAsync rejected', action.error.message);
    },

    [postAssessmentAsync.pending as any]: (state, action) => {
      // console.log('mainSlice', 'postAsync pending');
    },
    [postAssessmentAsync.fulfilled as any]: (state, action) => {
      // console.log('mainSlice', 'postAsync fulfilled');
    },
    [postAssessmentAsync.rejected as any]: (state, action) => {
      console.log('mainSlice', 'postAssessmentAsync rejected', action.error.message);
    },

    [postDemoAsync.pending as any]: (state, action) => {
      // console.log('mainSlice', 'postAsync pending');
    },
    [postDemoAsync.fulfilled as any]: (state, action) => {
      // console.log('mainSlice', 'postAsync fulfilled');
    },
    [postDemoAsync.rejected as any]: (_state, action) => {
      console.log('mainSlice', 'postDemoAsync rejected', action.error.message);
    },

    [loadAssessmentByUUIDAndEmail.pending as any]: (state, action) => {
      console.log('mainSlice', 'loadAssessmentByUUIDAndEmail pending');
      state.isLoading = LOADING_STATES.STARTED;
    },
    [loadAssessmentByUUIDAndEmail.fulfilled as any]: (state, action) => {
      console.log('mainSlice', 'loadAssessmentByUUIDAndEmail fulfilled');
      state.isLoading = LOADING_STATES.COMPLETED;
    },
    [loadAssessmentByUUIDAndEmail.rejected as any]: (state, action) => {
      console.log('mainSlice', 'loadAssessmentByUUIDAndEmail rejected', action.error.message);
      state.isLoading = LOADING_STATES.FAILED;
    },
  },
});

export const {init, updateProgress} = mainSlice.actions;

export default mainSlice.reducer;
