import React from 'react';
import styled from 'styled-components';

import {COLORS} from '@constants/style';

import ResultsTile from '../ResultsTile';
import {IDataSourceItem} from '@data/model/interfaces';

interface AppProps {
  dataSources: IDataSourceItem[],
  title: string;
}

export const DataSourceResultsTile = ({dataSources, title}: AppProps) => (
  <ResultsTile title={title}>
    <DataSourceList>
      {dataSources.map(({label}, i) => (
        <DataSourceItem key={i}>{label}</DataSourceItem>
      ))}
    </DataSourceList>
  </ResultsTile>
);

const Wrapper = styled.div`
    /* border: 1px solid red; */
`;

const DataSourceList = styled.ol`
  padding: 0;
  margin-top: 20px;
  list-style: none;
  counter-reset: SOURCES_COUNTER;
`;

const DataSourceItem = styled.li`
  margin: 6px 0;
  border: 1px solid ${COLORS.LIGHTS.GREY2};
  background: ${COLORS.LIGHTS.GREY};
  padding: 10px 20px;
  border-radius: 8px;
  counter-increment: SOURCES_COUNTER;
  &::before {
    content: counter(SOURCES_COUNTER)".";      
    padding-right: 10px;
  }
`;
