import {createGlobalStyle} from 'styled-components';
import CONSTANTS from '@constants';

// TODO consider using styled-components-normalize... not sure if it's needed
const GlobalStyle = createGlobalStyle`

    *, *::before, *::after {
        box-sizing: border-box;

        /** Alwasys add font changes here, NOT html/body*/
        font-family: 'Montserrat', sans-serif;    
    }
    
    * {
        margin: 0;
    }
    
    @media (prefers-reduced-motion: no-preference) {
        html {
            scroll-behavior: smooth;
            
        }
    }
    
    html, body {
        height: 100%;       
    }
    
    body {
        line-height: 1.5;
        -webkit-font-smoothing: antialiased;
    }
    
    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }
    
    input, button, textarea, select {
        font: inherit;
    }    
    
    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }    
    
    h1 {
        font-size: 38px;
        line-height: 48px;        
        font-weight: 400;
    }

    h2 {
        font-size: 32px;
        line-height: 42px;
        font-weight: 800;
    }

    h3 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
    }
    h4 {    
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
    }

    a {
        font-size: 16px;
        font-weight: 700;
    }

    #root, #__next {
        isolation: isolate;
        height: 100%;
    }
    
    body::after{
        /* position: absolute;
        top: 0px; left: 0px;
        content: "Mobile And UP";        
        background-color: var(--color-pink);
        padding: 4px 10px; */
    }

    @media ${CONSTANTS.QUERIES.MOBILE}{
        /* body::after{
            position: absolute;
            top: 0px; left: 0px;
            content: "Tablet And UP";
            background-color: var(--color-indigo);
            padding: 4px 10px;
        } */
    }
    @media ${CONSTANTS.QUERIES.TABLET}{
        /* body::after{
            position: absolute;
            top: 0px; left: 0px;
            content: "Laptop and UP";
            background-color: var(--color-red);
            padding: 4px 10px;
        } */
    }
    @media ${CONSTANTS.QUERIES.LAPTOP}{
        /* body::after{                        
            align-content: center;
            top: 0px; left: 0px;
            content: "Desktop and UP";
            background-color: var(--color-blue);
            padding: 4px 10px;
        } */
    }
 `;

export default GlobalStyle;
