
import React from 'react';
import styled from 'styled-components';

import {QUERIES, COLORS} from '@constants/style';

const User = (props: any) => (
  <SVGWrapper
    width={52}
    height={52}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={26} cy={26} r={26} fill="#5B6D86" />
    <path
      d="M26 25a7 7 0 1 0 0-14 7 7 0 0 0 0 14Zm0-12a5 5 0 1 1 0 10 5 5 0 0 1 0-10ZM27 27h-2a11 11 0 0 0-11 11 1 1 0 0 0 1 1h22a1 1 0 0 0 1-1 11 11 0 0 0-11-11ZM16.06 37A9 9 0 0 1 25 29h2a9 9 0 0 1 8.94 8H16.06Z"
      fill={COLORS.WHITE}
    />
  </SVGWrapper>
);

const SVGWrapper = styled.svg`
  margin: 0 20px;
  cursor: pointer;
  &:active{
    transform: translateY(2px);    
  }
  
  @media ${QUERIES.TABLET} {       
    transform: scale(.7);
    &:active{
      transform: translateY(1px) scale(.7);
    }
  }
`;

export default User;
