import Button from '@components/Button';
import {COPY} from '@constants/copy';
import {ROUTE_PATH} from '@constants/routes';
import {useSetPageTitle} from '@utils/hooks';
import {logAnayltics} from '@services/FirebaseHelper';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {EVENTS} from 'ui/misc/Analytics';

export const IncompleteData = () => {
  useSetPageTitle('Not Found');
  const navigate = useNavigate();

  return (
    <Wrapper>
      <h1>{COPY.INCOMPLETE_TITLE}</h1>
      <h2>{COPY.INCOMPLETE_SUBTITLE}</h2>
      <p>{COPY.INCOMPLETE_COPY}</p>
      <Button onClick={() => {
        logAnayltics(EVENTS.SURVEY_STARTED);
        navigate(ROUTE_PATH.DEMO);
      }}>
        {COPY.INCOMPLETE_CTA}
      </Button>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  min-height: calc(100vh - 220px);
  h1 {
      font-size: 5rem;
      font-weight: 800;
      padding: 60px 0;
  }
  h2 {
      font-weight: 600;
  }
`;
