import {COLORS} from '@constants/style';
import {ErrorItem} from '@data/model/defaults';
import React from 'react';
import styled from 'styled-components';

interface SelectTeamsProps {
    options: string[];
    selected: string[];
    isError?: ErrorItem;
    onChange: (selected: string) => void;
}

export const SelectTeams = ({options, selected, onChange, isError={status: false, msg: ''}} : SelectTeamsProps) => {
  return (
    <Wrapper isError={isError}>
      {options.map((option, i) => {
        return (
          <Row key={i}>
            <Checkbox type="checkbox" name={option} id={option} value={option} checked={selected.includes(option)} onChange={() => onChange(option)}/>
            <Label htmlFor={option}>{option}</Label>
          </Row>);
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div<{isError: ErrorItem}>`
    display: flex;
    /* padding: 6px 0; */
    flex-direction: column;
    /* gap: 8px; */
    border: 1px solid ${({isError})=> isError.status? 'red' : COLORS.LIGHTS.GREY3};
    border-radius: 3px;    
    overflow-y: scroll;    
  `;

const Checkbox = styled.input`    
    margin: 0 10px;   
    height: 20px;
    width: 20px;
    :hover{
        cursor: pointer;
    }
  `;

const Row = styled.div` 
    display: flex;
    align-items: center;
    :hover {
        cursor: pointer;
        background: ${COLORS.LIGHTS.LIGHT_GREY};
    }
`;

const Label = styled.label`
  font-style: normal;
  padding: 8px 0;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  flex: 1;
  :hover {
        cursor: pointer;
        color: black;
    }
`;
