import React from 'react';
import Button from '@components/Button';
import {SurveyPageWrapper} from '@components/SurveyPageWrapper';
import {COPY} from '@constants/copy';
import {COLORS, QUERIES, WIDTHS} from '@constants/style';
import {useSetPageTitle} from '@utils/hooks';
import DragDropPriorities from '@views/Priorities/DragDropPriorities';
import Processing from '@views/Priorities/Processing';
import styled from 'styled-components';
import {PrioritiesContext, useHook} from './useHook.Priorities';

export const Priorities = () => {
  useSetPageTitle('Priorities');
  const {isDisabled, isProcessing, startList, endList, onDragEnd, nextClick} = useHook();

  function Main() {
    return (

      <SurveyPageWrapper
        title={COPY.PRIORITIES_PAGE_TITLE}
        subtitle={COPY.PRIORITIES_PAGE_SUBTITLE}
        copy={COPY.PRIORITIES_PAGE_COPY}
        color={COLORS.PRIMARY.BLUE}
        nextButton={<Button disabled={isDisabled} onClick={nextClick}>{COPY.PRIORITIES_SUBMIT}</Button>}
      >

        <DragDropPriorities />

      </SurveyPageWrapper>
    );
  }

  return (
    <PrioritiesContext.Provider
      value={{
        startList,
        endList,
        onDragEnd,
      }}>
      {isProcessing ? <Processing /> : <Main />}
    </PrioritiesContext.Provider>
  );
};


const Wrapper = styled.div`  
  padding: 20px 40px 40px;
  @media ${QUERIES.TABLET} {
    padding: 10px 20px 20px;
  }
  
  @media ${QUERIES.MOBILE} {
    padding: 4px 6px;
  }

  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: ${WIDTHS.BASE};
  margin: 0 auto;
`;

const Bottom = styled.div`
  padding: 20px; 
  display: grid;
  place-content: center;
`;
