import {ErrorItem} from '@data/model/defaults';
import {isPersonalEmail, isValidEmail} from '@data/validation';
import {COPY} from './copy';

export enum DEMO_FIELDS{
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  COMPANY_NAME = 'COMPANY_NAME',
  TITLE = 'JOB_TITLE',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  TOTAL_EMPLOYEES = 'TOTAL_EMPLOYEES',
  CUSTOMER_SUCCESS_EMPLOYEES = 'CUSTOMER_SUCCESS_EMPLOYEES',
  INDUSTRY = 'INDUSTRY',
  EMPLOYEES_USE_GAINSIGHT = 'EMPLOYEES_USE_GAINSIGHT',
  TEAMS_USE_GAINSIGHT = 'TEAMS_USE_GAINSIGHT',
}

export const DEMO_FIELDS_LABELS = {
  [DEMO_FIELDS.FIRST_NAME]: 'First Name',
  [DEMO_FIELDS.LAST_NAME]: 'Last Name',
  [DEMO_FIELDS.COMPANY_NAME]: 'Company Name',
  [DEMO_FIELDS.TITLE]: 'Job TItle',
  [DEMO_FIELDS.PHONE]: 'Phone',
  [DEMO_FIELDS.EMAIL]: 'Work Email',
  [DEMO_FIELDS.TOTAL_EMPLOYEES]: 'Company Size',
  [DEMO_FIELDS.CUSTOMER_SUCCESS_EMPLOYEES]: 'Customer Success Employees',
  [DEMO_FIELDS.INDUSTRY]: 'Industry',
  [DEMO_FIELDS.EMPLOYEES_USE_GAINSIGHT]: 'Initially, how many employees will use Gainsight?',
  [DEMO_FIELDS.TEAMS_USE_GAINSIGHT]: 'What teams do you expect to use Gainsight?',
};


type Validate = (v: string) => ErrorItem;

interface IValidator{
  firstName: Validate;
  lastName: Validate;
  companyName: Validate;
  email: Validate;
}

export const DEMO_FIELD_VALIDATOR: IValidator = {
  // firstName: (v: string) => {status: v.length < 1},
  firstName: (v: string) => {
    const status = v.length < 1;
    return {
      status,
      msg: '',
    };
  },
  lastName: (v: string) => {
    const status = v.length < 1;
    return {
      status,
      msg: '',
    };
  },
  companyName: (v: string) => {
    const status = v.length < 1;
    return {
      status,
      msg: '',
    };
  },
  email: (v: string) => {
    // non-valid format error
    const isValidFormat = isValidEmail(v);
    if (isValidFormat) {
      return {
        status: isValidFormat,
        msg: '',
      };
    }

    // personal email check
    const isPersonal = isPersonalEmail(v);
    if (isPersonal) {
      return {
        status: true,
        msg: COPY.ERROR_MSG_PERSONAL_EMAIL,
      };
    }

    return {
      status: false,
      msg: '',
    };
  },


};


