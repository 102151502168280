import React from 'react';
import styled from 'styled-components';

import Footer from '@components/Footer';
import Header from '@components/Header';
import {COLORS} from '@constants/style';

export interface PageWrapperProps {
  children: React.ReactNode;
  fullwidth?: boolean;
  shadow?: boolean;
  showProfileButton?: boolean;
  color?: string;
}

interface Fullwidth {
  fullwidth?: boolean;
}

export const PageWrapper = ({children, fullwidth, color=COLORS.WHITE, shadow, showProfileButton}: PageWrapperProps) => (
  <Wrapper>
    <Header shadow={shadow} showProfileButton={showProfileButton}/>
    <BodyWrapper color={color} fullwidth={fullwidth}>
      {children}
    </BodyWrapper>
    <Footer />
  </Wrapper>
);

const Wrapper = styled.div`
  min-width: fit-content;
  width: 100%;
`;

const BodyWrapper = styled.main<Fullwidth>`
  background-color: ${({color}) => color};
  min-height: fit-content;
  height: fit-content;
  margin: auto;    
`;

