import React from 'react';
import styled from 'styled-components';

import {FONTS, COLORS, QUERIES, SHADOWS} from '@constants/style';
import {IPriorityDisplay, IPriorityResult} from '@data/model/interfaces';

export const DraggableItem = ({priority}: {priority: IPriorityResult|IPriorityDisplay}) => {
  return (
    <Wrapper>
      <Grip/>
      <Middle>
        <PriorityLabel color={priority.color} prefix={priority.prefix} label={priority.label} />
        <Sub>{priority.desc}</Sub>
      </Middle>
      <Info color={priority.color} />
    </Wrapper>
  );
};

export const PriorityLabel = ({lineBreak=false, color, prefix, label}: {lineBreak?: boolean, color: string, label: string; prefix: string}) => (
  <LabelWrapper>
    {prefix.length > 0 && (<>{prefix}&nbsp;</>)}
    {lineBreak && <br/>}
    <span style={{color, textTransform: 'uppercase'}}>{label}</span>
  </LabelWrapper>
);


const Wrapper = styled.div`

  border: 1px solid ${COLORS.LIGHTS.GREY2};  
  display: grid;
  grid-template-columns: 30px auto 30px;
  border-radius: 10px;
  overflow: hidden;
  
  background-color: ${COLORS.WHITE};
  box-shadow: ${SHADOWS.SECONDARY};  
  user-select: none; 
`;

const Grip = styled.div`  
  display: grid;
  place-content: center;
  width: 40px;
  &::before{
    font: var(--fa-font-solid);
    font-size: 1.2rem;  
    content: "\f58e";
    color: ${COLORS.DARKS.DARK_GREY};    
  }
`;

const Middle = styled.div`
  padding: 10px 20px;
  height: 90px;  
  @media ${QUERIES.TABLET} {
    padding: 5px 10px;
    height: max-content;  
  }
`;


const Sub = styled.div`
  font-size: ${FONTS.FLEX_MEDIUM};
  line-height: 1rem;  
  
  @media ${QUERIES.TABLET} {
    font-size: ${FONTS.FLEX_SMALL};
  }

  color: ${COLORS.DARKS.DARK_GREY};
  width: 100%;  
  @media ${QUERIES.MOBILE} {
    display: none;
  }
`;
const Info = styled.div`
  background-color: ${(p) => p.color};
  display: grid;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: auto;
  }  
`;
const LabelWrapper = styled.span`
  font-size: clamp(.7rem, 1vw, 1.1rem); 
  font-weight: 600;
  color: ${COLORS.DARKS.CHARCOAL};
  margin: auto 0;

`;
