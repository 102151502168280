import React from 'react';

import CONSTANTS from '@constants';
import styled from 'styled-components';
import {SHADOWS, COLORS} from '@constants/style';

interface CardProps {
  title: string;
  body: string;
}

export const HomeCard = ({title, body}: CardProps) => (
  <Wrapper>
    <h2>{title}</h2>
    <p>{body}</p>
  </Wrapper>
);

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.WHITE};  
  counter-increment: listCounter;
  list-style: none;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  box-shadow: ${SHADOWS.BOX};
  &::before {
    content: "STEP " counter(listCounter);    
    font-size: 0.8rem;
    font-weight: 800;
    color: ${COLORS.PRIMARY.BLUE};
  }
  h2 {
    color: ${COLORS.PRIMARY.NAVY};
    font-size: 1.4rem;
    line-height: 1.6rem;
    padding: 10px 0;    
    width: 80%;
    text-align: center;
  }
  p {
    color: ${CONSTANTS.COLORS.GREY600};
    font-size: 0.8rem;
    width: 80%;
    text-align: center;
  }
`;
