import priorities, {peerScores} from './priorities';
import {CompleteModel, IContentModel} from '@data/model/interfaces';
import {getMetrics} from './metrics';
import {getOutcomes} from './outcomes';
import rulesProcessor from '../RulesProcessor';
import assessment from './assessment';

const version = '1.0.0';

export function getContentModel(): CompleteModel {
  const dimensions = Object.keys(priorities);

  const $ = rulesProcessor(dimensions);
  const outcomes = getOutcomes($);
  const metrics = getMetrics($);

  const model: IContentModel[] = [
    {
      key: priorities.RETENTION.key,
      challenge: priorities.RETENTION.name,
      color: priorities.RETENTION.color,
      outcomes: [
        outcomes.IDENTIFY_AND_RESOLVE_RISKS,
        outcomes.VALUE_REALIZATION,
        outcomes.SYSTEMATIC_RENEWAL_PROCESS_AND_FORCASTING,
      ],
      metrics: [
        metrics.GRR,
        metrics.RECOVERED_REVENUE,
      ],
    },
    {
      key: priorities.VISIBILITY.key,
      challenge: priorities.VISIBILITY.name,
      color: priorities.VISIBILITY.color,
      outcomes: [
        outcomes.SINGLE_SOURCE_OF_TRUTH,
        outcomes.ACCURATELY_GAUGE_CUSTOMER_HEALTH,
        outcomes.INSIGHTS_SHARED_ACROSS_COMPANY_AND_WITH_CUSTOMERS,
      ],
      metrics: [
        metrics.CSM_RATIOS,
        metrics.CUSTOMER_SATISFACTION,
        metrics.NUMBER_OF_TOOLS,
      ],
    },
    {
      key: priorities.EXPANSION.key,
      challenge: priorities.EXPANSION.name,
      color: priorities.EXPANSION.color,
      outcomes: [
        outcomes.DISCOVER_AND_QUALIFY_CUSTOMER_LEADS,
        outcomes.IMPROVE_CLOSE_RATES_AND_SPEED,
        outcomes.UNDERSTAND_WHY_CUSTOMERS_DONT_EXPAND,
      ],
      metrics: [
        metrics.NET_RETENTION_RATE,
        metrics.EXPANSION_REVENUE_SOURCED_BY_CS,
      ],
    },
    {
      key: priorities.ADOPTION.key,
      challenge: priorities.ADOPTION.name,
      color: priorities.ADOPTION.color,
      outcomes: [
        outcomes.UNDERSTAND_ADOPTION_TRENDS,
        outcomes.PROACTIVE_ENABLEMENT,
        outcomes.COURSE_CORRECT_ADOPTION_ISSUES,
      ],
      metrics: [
        metrics.PERCENT_OF_ARR_THAT_IS_MAU,
        metrics.AVERAGE_ADOPTION_DEPTH,
        metrics.AVERAGE_ADOPTION_BREADTH,
      ],
    },
    {
      key: priorities.EXPERIENCE.key,
      challenge: priorities.EXPERIENCE.name,
      color: priorities.EXPERIENCE.color,
      outcomes: [
        outcomes.CONSISTENTLY_DELIVER_LIFECYCLE,
        outcomes.UNDERSTAND_AND_ACT_ON_CUSTOMER_SENTIMENT,
        outcomes.MOBILIZE_ADVOCATES_FOR_GROWTH,
      ],
      metrics: [
        metrics.REVENUE_INFLUENCED_BY_ADVOCATES,
        metrics.NPS_CORRELATION_TO_RENEWAL_OUTCOME,
        metrics.TRUE_NPS_SCORE,
      ],
    },
    {
      key: priorities.SCALE.key,
      challenge: priorities.SCALE.name,
      color: priorities.SCALE.color,
      outcomes: [
        outcomes.OPTIMIZE_CSM_WORKFLOWS,
        outcomes.BLEND_TECH_AND_HUMAN_TOUCHES,
        outcomes.AUTOMATED_SIGNALS_AND_ALERTS,
      ],
      metrics: [
        metrics.CS_COST_PERCENTAGE_OF_REVENUE,
        metrics.PERCENTAGE_OF_CUSTOMERS_MANAGED,
      ],
    },
  ];

  model.forEach((item) => {
    item.metrics.forEach((metric) => {
      metric.color = item.color;
    });
  });

  return {
    model,
    priorities,
    assessment,
    outcomes,
    metrics,
    peerScores,
    version,
  };
}


