const pkg = require('../../package.json');

export enum ENVIRONMENTS {
    EMULATOR = 'emulator',
    PRODUCTIOn = 'production'
}

export const ENV = process.env.NODE_ENV === 'emulator' ? ENVIRONMENTS.EMULATOR : ENVIRONMENTS.PRODUCTIOn;

// if set to to true will write to assessments_staging and demos_staging tables
// it set to false will write to assessments and demos tables
export const WRITE_TO_STAGING_TABLES = false;

export const VERSION = pkg.version;

export const MAX_FIELD_LENGTH = 256;

export const ORIGINAL_DESTINATION = 'od';

export const EMAIL_FOR_SIGNIN = 'emailForSignIn';
