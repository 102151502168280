import React, {useEffect} from 'react';

import Router from '@components/Router';

import {isEnabled, Shim} from './ui/misc/Shim';
import {useAppDispatch} from '@services/store/hooks';
import {initAsync} from '@services/store/thunks';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorHandler} from 'ui/misc/ErrorHandler/ErrorHandler';


function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(initAsync());
  }, [dispatch]);

  if (isEnabled) return <Shim />;

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <Router />
    </ErrorBoundary>
  );
}

export default App;
