export const WIDTHS = {
  BASE: '1440px',
  MOBILE: '576px',
  TABLET: '993px',
  LAPTOP: '1200px',
};

export const QUERIES = {
  MOBILE: `(max-width: ${WIDTHS.MOBILE})`,
  TABLET: `(max-width: ${WIDTHS.TABLET})`,
  LAPTOP: `(max-width: ${WIDTHS.LAPTOP})`,
};

export const FONTS = {
  FLEX_H1: 'clamp(1.5rem, 2.25rem, 2.5rem)',
  FLEX_H2: 'clamp(1.25rem, 1.75rem, 2rem)',
  FLEX_h3: 'clamp(1rem, 1.5rem, 1.75rem)',
  FLEX_P: 'clamp(1rem, 1.5rem, 1.75rem)',
  FLEX_MEDIUM: 'clamp(.7rem, 1.1vw, .9rem)',
  FLEX_SMALL: 'clamp(.6rem, 1vw, .8rem)',
};

export const SHADOWS = {
  BOX: '0px 4px 3px hsl(0deg 0% 0% / 0.25)',
  PRIMARY: '0px 2px 2px hsl(0deg 0% 0% / 0.4)',
  SECONDARY: '0px 3px 6px hsl(0deg 0% 0% / 0.2)',
  HEADER: '0px 3px 4px hsl(0deg 0% 0% / 0.2)',
};


export const COLORS = {
  PRIMARY: {
    BLUE: 'hsla(201, 80%, 60%, 1)', // #47b1eb
    NAVY: 'hsla(207, 63%, 23%, 1)', // #163e60
    YELLOW: 'hsla(43, 97%, 62%, 1)', // #fcc740
    DARK_YELLOW: 'hsla(43, 97%, 52%, 1)', // #fcc740
    PURPLE: 'hsla(270, 31%, 54%, 1)', // #8a65ae
    PURPLE_LITE: 'hsla(270, 31%, 54%, 0.5)', // #8a65ae
    GREEN: 'hsla(162, 28%, 47%, 1)', // #569985
    PINK: 'hsla(338, 84%, 53%, 1)', // #ec226c
  },
  DARKS: {
    CHARCOAL: 'hsla(213, 9%, 20%, 1)',
    DARK_NAVY: 'hsla(207, 63%, 23%, 1)',
    DARK_GREY: 'hsla(215, 19%, 44%, 1)',
  },
  LIGHTS: {
    LIGHT_GREY: 'hsla(0, 0%, 92%, 1)', // #ebebeb
    GREY: 'hsla(40, 11%, 95%, 1)', // #262626
    GREY2: 'hsla(0, 0%, 88%, 1)',
    GREY3: 'hsla(0, 0%, 82%, 1)',
    OFF_WHITE: 'hsla(0, 0%, 98%, 1)',
    BLUE: 'hsla(201, 92%, 68%, 1)',
  },

  WHITE: 'hsla(0, 0%, 100%, 1)',
  BLACK: 'hsla(0, 0%, 0%, 1)',

  GREY100: 'hsla(0, 0%, 90%, 1)',
  GREY200: 'hsla(0, 0%, 80%, 1)',
  GREY300: 'hsla(0, 0%, 70%, 1)',
  GREY400: 'hsla(0, 0%, 60%, 1)',
  GREY500: 'hsla(0, 0%, 50%, 1)',
  GREY600: 'hsla(0, 0%, 40%, 1)',
  GREY700: 'hsla(0, 0%, 30%, 1)',
  GREY800: 'hsla(0, 0%, 20%, 1)',
  GREY900: 'hsla(0, 0%, 10%, 1)',
};


export const COLORS_ARRAY = [
  COLORS.PRIMARY.BLUE,
  COLORS.PRIMARY.NAVY,
  COLORS.PRIMARY.YELLOW,
  COLORS.PRIMARY.PINK,
  COLORS.PRIMARY.GREEN,
  COLORS.PRIMARY.PURPLE,
  COLORS.DARKS.CHARCOAL,
  COLORS.BLACK,
  COLORS.WHITE,
];
