import React, {useContext} from 'react';
import {COPY} from '@constants/copy';

import {ResultsContext} from '../useHook.Results';
import {PlanView} from './PlanView';
import {COLORS} from '@constants/style';
import {useSetPageTitle} from '@utils/hooks';

export const PlanViewSoon = () => {
  useSetPageTitle('Plan Soon');
  const {summaryModel} = useContext(ResultsContext);

  if (!summaryModel) return <></>;

  return (
    <PlanView
      model={summaryModel.model.Intermediate}
      title={COPY.PLAN_SOON_HERO_TITLE}
      subtitle={COPY.PLAN_SOON_HERO_SUBTITLE}
      color={COLORS.PRIMARY.NAVY}
    />
  );
};
