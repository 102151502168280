
import {SEQUENCE} from '@data/model/defaults';
import {ICompletedAssessment, IMetric} from '@data/model/interfaces';
import priorities from './priorities';

const version = 2;

export function getMetrics($: any) {
  const metrics = {
    GRR: {
      metric: 'GRR (Gross Renewal Rate)',
      description: 'Improve GRR with better risk resolution, managing to customer goals and disciplined renewals.',
      sequence: SEQUENCE.FOUNDATION,
    },
    NRR: {
      metric: 'NRR (Net Retention Rate)',
      description: 'This metric measures the total value of your renewed contracts as well as the revenue gained through upsells and cross-sells.',
      sequence: SEQUENCE.ADVANCED,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(66) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    NPS: {
      metric: 'True NPS Score',
      description: 'Improve CSM work experience by embedding CS workflows in their toolstack.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPERIENCE_ADOPTION).inTop(2) &&
          $(d).isAssessmentAll().greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },

    },
    PERCENT_ON_TIME_RENEWALS: {
      metric: 'Percentage On Time Renewals',
      description: 'Improve renewal predictability and increase on-time renewals by driving a standardized renewal process.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.RETENTION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    AT_RISK_DOLLARS_RECOVERED: {
      metric: 'At Risk Dollars Recovered',
      description: 'Increase revenue retained from accounts that had a risk that is resolved.',
      sequence: SEQUENCE.FOUNDATION,
    },
    ACCOUNT_RATIOS: {
      metric: 'Account Ratios',
      description: 'Increase CSM ratios by by providing a fast, easy, holistic view of customer health that effecitvely prioritize CSM activities.',
      sequence: SEQUENCE.FOUNDATION,
    },
    PERCENT_OF_ACCOUNTS_WITH_VERIFIED_OUTCOME: {
      metric: 'Percentage of Accounts w/ Verified Outcome',
      description: 'Increase size and frequency of customer value realization by aligning on goals and proactively communicating value delivered.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.RETENTION).inTop(2) &&
          $(d).isAssessmentAll().greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    PERCENT_OF_CUSTOMERS_WITH_ADVOCATES: {
      metric: 'Percentage of Customers w/ Advocates',
      description: 'Closed loop feedback process surfaces potential advocates and opportunities to further imporve user experience for further advocacy.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPERIENCE_ADOPTION).inTop(2) &&
          $(d).isAssessmentAll().greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    CUSTOMER_LIFETIME_VALUE: {
      metric: 'Customer Lifetime Value',
      description: 'Increase customer stickiness by proactively guiding customers though critical moments with your product.',
      sequence: SEQUENCE.FOUNDATION,
    },
    ACTIVE_USERS: {
      metric: 'Active Users',
      description: 'Use multi-channel campaigns to improve user, segment and feature adoption at scale.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPERIENCE_ADOPTION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    USER_RETENTION: {
      metric: 'User Retention',
      description: 'Improve user retention by guiding users to adopt sticky features aligned to their goals.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPERIENCE_ADOPTION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(33) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    REVENUE_INFLUENCED_BY_ADVOCATES: {
      metric: 'Revenue Influenced by Advocates',
      description: 'Accelerate expansion opportunities and increase expansion pipeline.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(66) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    PERCENT_WHITESPACE_REMAINING: {
      metric: 'Percentage of Whitespace Remaining',
      description: ' Decrease percent of whitespace with clear views into expansion triggers and product penetration.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    AVERAGE_TIME_TO_EXPAND: {
      metric: 'Average Time to Expansion',
      description: 'Effectively target customers for expansion based on their goals, current usage and potential solutions.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(66) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    EXPANSION_CLOSE_RATE: {
      metric: 'Expansion Close Rate',
      description: 'Generate highly qualified leads from your customers via your CS team and Sales/CS account planning.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(66) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    END_USER_NPS: {
      metric: 'End User NPS',
      description: 'Improve CSM work experience by embedding CS workflows in their toolstack.',
      sequence: SEQUENCE.FOUNDATION,
    },
    PERCENT_OF_CUSTOMERS_MANAGED: {
      metric: 'Percent of Customers Managed (CSM or Digital)',
      description: 'Increase CS coverage across all customer segments with higher ratios and digital-led customer success.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.SCALE_EFFICIENCY).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    COST_TO_SERVE: {
      metric: 'Cost to Serve',
      description: 'Decrease Cost to Serve by leveraging digital-led CS programs including self-service, pooled CS, in App and community programs.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.SCALE_EFFICIENCY).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(66)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    FEATURE_ADOPTION_RATE: {
      metric: 'Feature Adoption Rate',
      description: 'More effectively allocate development resources and make user-informed product roadmap decisions that are defensible in the market based on direct customer insight.',
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.SCALE_EFFICIENCY).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },

  // } as {[key: string]: IMetric};
  };

  return metrics;
}
