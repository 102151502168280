import React from 'react';
import {COLORS} from '@constants/style';
import {ROUTE_PATH} from '@constants/routes';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {COPY} from '@constants/copy';
import {QUERIES} from '@constants/style';

export const GoBack = () => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <h2>{COPY.PROFILE_GOBACK_TITLE}</h2>
      <p>{COPY.PROFILE_GOBACK_SUBTITLE}</p>
      <SectionWrapper>
        <GoBackBlock
          color={COLORS.PRIMARY.PURPLE}
          title={COPY.PROFILE_GOBACK_DATA_TITLE}
          image={COPY.PROFILE_GOBACK_DATA_IMG}
          onClick={() => navigate(ROUTE_PATH.SOURCES)}
        />
        <GoBackBlock
          color={COLORS.PRIMARY.PINK}
          title={COPY.PROFILE_GOBACK_ASSESS_TITLE}
          image={COPY.PROFILE_GOBACK_ASSESS_IMG}
          onClick={() => navigate(ROUTE_PATH.ASSESS)}
        />
        <GoBackBlock
          color={COLORS.PRIMARY.BLUE}
          title={COPY.PROFILE_GOBACK_PRIORITIZE_TITLE}
          image={COPY.PROFILE_GOBACK_PRIORITIZE_IMG}
          onClick={() => navigate(ROUTE_PATH.PRIORITIES)}
        />
      </SectionWrapper>
    </Wrapper>
  );
};

const GoBackBlock = ({color, title, image, onClick}: {color: string, title: string, image: string, onClick: ()=>void }) => (
  <Block color={color}>
    <GoBackBlockWrapper>
      <h2>{title}</h2>
      <Button onClick={onClick}>{COPY.PROFILE_GOBACK_CTA}</Button>
    </GoBackBlockWrapper>
    <img src={image} alt={image}/>
  </Block>
);

const GoBackBlockWrapper = styled.div`   
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: flex-start;
  min-width: 200px;
  height: 100%;
  padding-left: 20px;
`;

const Button = styled.button`
  background: none;
  border: none;
  text-align: left;
  color: ${COLORS.WHITE};
  font-weight: 600;    
  padding: 0;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
  &::after{
    font: var(--fa-font-solid);
    content: "\f061";    
    padding-left: 6px;
    text-decoration: none;
  }
`;

const Wrapper = styled.div`    
    margin-left: 20px;
    @media ${QUERIES.TABLET} {  
        margin-left: 0;
    }
    padding: 20px;
`;

const Block = styled.div<{color: string}>`
  background: ${({color}) => color};
  overflow: hidden;
  border-radius: 20px;
  color: ${COLORS.WHITE};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 10px;
  height: 130px;
`;
const SectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
`;
