import React, {Suspense, useContext} from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import {PulseLoader} from 'react-spinners';
import {ResultsContext} from '../useHook.Results';
import {COLORS} from '@constants/style';

const PDFReport = React.lazy(() => import('../PDFReport'));

export const PDFButton = () => {
  const {userDataModel, summaryModel} = useContext(ResultsContext);

  if (!userDataModel || !summaryModel) return <></>;

  return (
    <Suspense fallback={<Loading />}>
      <PDFReport
        assess={userDataModel.assessment}
        priorities={userDataModel.priorities}
        dataSources={userDataModel.sources}
        demo={userDataModel.demo}
        summaryModel={summaryModel}
      />
    </Suspense>
  );
};


const Loading = () => (
  <Button small color={COLORS.PRIMARY.PINK} onClick={() => {}}>
    <PulseLoader color='white' size={6}/>
  </Button>
);

const Wrapper = styled.div`
    border: 1px solid red;
`;
