
export enum DATA_SOURCE_TYPES {
    CRMS = 'CRM',
    CUSTOMER_EDUCATION = 'CUSTOMER_EDUCATION',
    PRODUCT_ANALYTICS = 'PRODUCT_ANALYTICS',
    OTHER_DATA_STORES = 'OTHER_DATA_STORES',
    SUPPORT_TICKETING = 'SUPPORT_TICKETING',
    PRODUCTIVITY = 'PRODUCTIVITY',
    COMMUNITY = 'COMMUNITY',
}


export const INTEGRATION_TYPES_LABELS = {
  [DATA_SOURCE_TYPES.CRMS]: 'CRM Systems',
  [DATA_SOURCE_TYPES.SUPPORT_TICKETING]: 'Support and Ticketing',
  [DATA_SOURCE_TYPES.PRODUCT_ANALYTICS]: 'Product Analytics',
  [DATA_SOURCE_TYPES.OTHER_DATA_STORES]: 'Other Data Stores',
  [DATA_SOURCE_TYPES.PRODUCTIVITY]: 'Productivity',
  [DATA_SOURCE_TYPES.CUSTOMER_EDUCATION]: 'Customer Education',
  [DATA_SOURCE_TYPES.COMMUNITY]: 'Community',
};

// ID is necessary for DND to work correctly
export const THIRD_PARTY_SOURCES = {
  SFDC_CRM: {label: 'Salesforce', id: '1'},
  HUBSPOT: {label: 'HubSpot CRM', id: '2'},
  ZOHO_CRM: {label: 'Zoho CRM', id: '3'},
  PIPEDRIVE: {label: 'Pipedrive', id: '4'},
  DYNAMICS: {label: 'Microsoft Dynamics', id: '5'},
  SAPC4C: {label: 'SAP C4C', id: '6'},

  SFDC_SERVICECLOUD: {label: 'SFDC ServiceCloud', id: '7'},
  FRESHDESK: {label: 'Freshdesk', id: '8'},
  ZENDESK: {label: 'Zendesk', id: '9'},
  JIRA: {label: 'Jira', id: '10'},

  GAINSIGHT_PX: {label: 'Gainsight PX', id: '11'},
  SEGMENT: {label: 'Segment', id: '12'},
  MIXPANEL: {label: 'Mixpanel', id: '13'},
  PENDO: {label: 'Pendo', id: '14'},

  BIGQUERY: {label: 'Big Query', id: '15'},
  AMAZON_S3: {label: 'Amazon S3', id: '16'},
  AMAZON_REDSHIFT: {label: 'Amazon Redshift', id: '17'},
  SNOWFLAKE: {label: 'Snowflake', id: '18'},

  SURVEY_MONKEY: {label: 'SurveyMonkey', id: '19'},
  ZUORA: {label: 'Zuora', id: '20'},
  GONGIO: {label: 'Gong.io', id: '21'},

  INTELLUM: {label: 'Intellum', id: '22'},
  SKILLJAR: {label: 'Skilljar', id: '23'},
  THOUGHTINDUSTRIES: {label: 'ThoughtIndustries', id: '24'},
  NORTHPASS: {label: 'Northpass', id: '25'},
  WORKRAMP: {label: 'WorkRamp', id: '26'},

  GAINSIGHT_INSIDED: {label: 'Gainsight InSided', id: '27'},
  KHOROS: {label: 'Khoros', id: '28'},
  SFDC_COMMUNITIES: {label: 'SFDC Communities', id: '29'},
  VANILLA_HIGHER_LOGIC: {label: 'Vanilla/Higher Logic', id: '30'},
};


export const INTEGRATIONS = {
  [DATA_SOURCE_TYPES.CRMS]: [
    THIRD_PARTY_SOURCES.SFDC_CRM,
    THIRD_PARTY_SOURCES.HUBSPOT,
    THIRD_PARTY_SOURCES.ZOHO_CRM,
    THIRD_PARTY_SOURCES.PIPEDRIVE,
    THIRD_PARTY_SOURCES.DYNAMICS,
    THIRD_PARTY_SOURCES.SAPC4C,
  ],
  [DATA_SOURCE_TYPES.CUSTOMER_EDUCATION]: [
    THIRD_PARTY_SOURCES.INTELLUM,
    THIRD_PARTY_SOURCES.SKILLJAR,
    THIRD_PARTY_SOURCES.THOUGHTINDUSTRIES,
    THIRD_PARTY_SOURCES.NORTHPASS,
    THIRD_PARTY_SOURCES.WORKRAMP,
  ],
  [DATA_SOURCE_TYPES.SUPPORT_TICKETING]: [
    THIRD_PARTY_SOURCES.SFDC_SERVICECLOUD,
    THIRD_PARTY_SOURCES.FRESHDESK,
    THIRD_PARTY_SOURCES.ZENDESK,
    THIRD_PARTY_SOURCES.JIRA,
  ],
  [DATA_SOURCE_TYPES.PRODUCT_ANALYTICS]: [
    THIRD_PARTY_SOURCES.GAINSIGHT_PX,
    THIRD_PARTY_SOURCES.SEGMENT,
    THIRD_PARTY_SOURCES.MIXPANEL,
    THIRD_PARTY_SOURCES.PENDO,
  ],
  [DATA_SOURCE_TYPES.OTHER_DATA_STORES]: [
    THIRD_PARTY_SOURCES.BIGQUERY,
    THIRD_PARTY_SOURCES.AMAZON_S3,
    THIRD_PARTY_SOURCES.AMAZON_REDSHIFT,
    THIRD_PARTY_SOURCES.SNOWFLAKE,
  ],
  [DATA_SOURCE_TYPES.COMMUNITY]: [
    THIRD_PARTY_SOURCES.GAINSIGHT_INSIDED,
    THIRD_PARTY_SOURCES.KHOROS,
    THIRD_PARTY_SOURCES.SFDC_COMMUNITIES,
    THIRD_PARTY_SOURCES.VANILLA_HIGHER_LOGIC,
  ],
  [DATA_SOURCE_TYPES.PRODUCTIVITY]: [
    THIRD_PARTY_SOURCES.SURVEY_MONKEY,
    THIRD_PARTY_SOURCES.ZUORA,
    THIRD_PARTY_SOURCES.GONGIO,
  ],
};
