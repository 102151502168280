
import {AssessmentAnswers, IDemographics} from '@data/model/interfaces';

// enums
export enum SEQUENCE {
  FOUNDATION = 'Foundation',
  INTERMEDIATE = 'Intermediate',
  ADVANCED = 'Advanced'
}

export const SEQUENCE_MAP = {
  [SEQUENCE.FOUNDATION]: 'Now',
  [SEQUENCE.INTERMEDIATE]: 'Soon',
  [SEQUENCE.ADVANCED]: 'Later',
};

export enum ASSET_TYPE {
  PDF = 'pdf',
  BLOG = 'Blog',
  DEMO = 'Demo',
  VIDEO = 'Video',
}

export enum MATURITY {
  Foundation = 'Foundation',
  Intermediate = 'Intermediate',
  Advanced = 'Advanced',
}

// export enum ASSESSMENT {
//   CUSTOMER_DATA = 'Customer Data',
//   PROCESS_WORFLOWS = 'Process and Workflows',
//   ORG_ALIGNMENT = 'Org Alignment',
//   MEASURES_METRICS = 'Measures and Metrics',
//   SKILLS_RESOURCING = 'CS Ops Skills and Resourcing',
// }

export const ASSESSMENT_MAP: {[key: string]: string} = {
  'Customer Data': 'CUSTOMER_DATA',
  'Process and Workflows': 'PROCESS_WORFLOWS',
  'Org Alignment': 'ORG_ALIGNMENT',
  'Measures and Metrics': 'MEASURES_METRICS',
  'CS Ops Skills and Resourcing': 'SKILLS_RESOURCING',
};


// export enum PRIORITY_OLD {
//   VISIBILITY = 'Visibility',
//   SCALE = 'Scale',
//   ADOPTION = 'Adoption',
//   RETENTION = 'Retention',
//   EXPERIENCE = 'Experience',
//   EXPANSION = 'Expansion',
// }

// export enum PRIORITY{
//   SCALE_EFFICIENCY = 'Increase Scale & Efficiency',
//   RETENTION = 'Improve Retention',
//   EXPERIENCE_ADOPTION = 'Improve User Experience & Product Adoption',
//   EXPANSION = 'Increase Expansion',
// }


// default objects
export const DefaultAssessmentAnwsers: AssessmentAnswers = {
  CUSTOMER_DATA: 0,
  PROCESS_WORFLOWS: 0,
  ORG_ALIGNMENT: 0,
  MEASURES_METRICS: 0,
  SKILLS_RESOURCING: 0,
};

export const DefaultDemographics: IDemographics = {
  firstName: null,
  lastName: null,
  email: null,
  title: null,
  companyName: null,
  number_of_employees: null,
  number_of_csms: null,
  industry: null,
  teams_using_gainsight: null,
  users_of_gainsight: null,
  consent: 'no',
};


export type ErrorItem = {
  status: boolean;
  msg: string;
}

type IDemoErrors = {
  firstName: ErrorItem;
  lastName: ErrorItem;
  email: ErrorItem;
  title: ErrorItem;
  companyName: ErrorItem;
  industry: ErrorItem;
  number_of_employees: ErrorItem;
  number_of_csms: ErrorItem;
  users_of_gainsight: ErrorItem;
  teams_using_gainsight: ErrorItem;
}


export const defaultDemographicsError: IDemoErrors = {
  firstName: {
    status: false,
    msg: '',
  },
  lastName: {
    status: false,
    msg: '',
  },
  email: {
    status: false,
    msg: '',
  },
  title: {
    status: false,
    msg: '',
  },
  companyName: {
    status: false,
    msg: '',
  },
  industry: {
    status: false,
    msg: '',
  },
  number_of_employees: {
    status: false,
    msg: '',
  },
  number_of_csms: {
    status: false,
    msg: '',
  },
  users_of_gainsight: {
    status: false,
    msg: '',
  },
  teams_using_gainsight: {
    status: false,
    msg: '',
  },
};

