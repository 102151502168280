import {ROUTE_PATH} from '@constants/routes';
import {ICompletedAssessment} from '@data/model/interfaces';
import useModel from '@data/model/useModel';
import {isWholeAssessmentComplete, isDataSourcesComplete, isDemoComplete} from '@data/validation';
import {useAppSelector} from '@services/store/hooks';
import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';


/**
 * Makes sure if a user has landed on a survey screen, that the previous screen data is complete, if not it sends them back
 *
 * eg. If a user goes to /survey/yourdata but there is not demographics data in the store, they will be sent back to /survey/aboutyou
 */


const RouteGuard = () =>{
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const model = useModel();
  const {uuid, main, assess, demo, dataSources, priorities} = useAppSelector((store) => store);

  const answers: ICompletedAssessment = {
    demo: demo.data,
    sources: dataSources.data,
    assessment: assess.answers,
    priorities: priorities.data,
  };

  useEffect(() => {
    if (!main.isInitialized) return;

    const demoComplete = isDemoComplete(demo.data);
    const sourcesCompelte = isDataSourcesComplete(dataSources.data);
    const assessComplete = isWholeAssessmentComplete({model, answers});

    // NO validation needed
    // if (pathname===ROUTE_PATH.DEMO )

    if (pathname===ROUTE_PATH.SOURCES && [demoComplete].includes(false)) {
      navigate(ROUTE_PATH.DEMO);
      return;
    }

    if (pathname===ROUTE_PATH.ASSESS && [demoComplete, sourcesCompelte].includes(false)) {
      navigate(ROUTE_PATH.DEMO);
      return;
    }

    if (pathname===ROUTE_PATH.PRIORITIES && [demoComplete, assessComplete, sourcesCompelte].includes(false)) {
      navigate(ROUTE_PATH.DEMO);
      return;
    }
  }, [main.isInitialized]);

  return null;
};

export default RouteGuard;
