import {DefaultAssessmentAnwsers, MATURITY} from '@data/model/defaults';
import {createSlice} from '@reduxjs/toolkit';
import {IAssessState} from '@data/model/interfaces';

const initialState: IAssessState = {
  answers: {...DefaultAssessmentAnwsers},
  maturity: MATURITY.Foundation,
};

export const assessSlice = createSlice({
  name: 'assess',
  initialState: {...initialState},
  reducers: {
    init: (state, {payload}) => {
      state.answers = payload;
    },
    clear: (state) => {
      state.answers = {...DefaultAssessmentAnwsers};
    },
    update: (state, {payload}) => {
      state.answers = payload;

      // dont think this is used anymore
      // state.maturity = calculateMaturity(payload);
    },
  },
});

export const {init, clear, update} = assessSlice.actions;

export default assessSlice.reducer;
