import React, {useState} from 'react';


import styled from 'styled-components';
import Button from '@components/Button';
import {QUERIES, COLORS} from '@constants/style';

export const SamplePlan = ({onClose}: {onClose: () => void}) => {
  return (
    <Wrapper>
      <h2>Sample Gainsight Plan</h2>

      <Body>
        <ImageWrapper>
          <div>
            <Image src='/images/dashboard2.png' alt="Sample Plan"/>
          </div>
        </ImageWrapper>
        {/* <ul>
          <h4>Lorem ipsum </h4>
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dignissim lorem dui</li>
          <li>Pellentesque ultrices accumsan risus vitae varius. Suspendisse congue orci libero</li>
          <li>Donec varius, odio eget porta euismod, lectus urna faucibus diam</li>
          <li>Pellentesque maximus erat ac eros commodo </li>
          <li>Praesent felis dui, pulvinar eget tortor eget, finibus porta ex. Quisque eget pretium sapien. Sed ultrices lacus non justo convallis vestibulum.</li>
        </ul> */}
      </Body>


      <Button onClick={onClose}>Close</Button>

    </Wrapper>
  );
};

const Wrapper= styled.div`
    display: flex;
    flex-direction: column;   
    align-items: center;
    gap: 30px;
    padding: 0 30px 30px 30px;
`;

const Body = styled.div`

  display: flex;
  @media ${QUERIES.MOBILE} {   
    flex-direction: column;
  }

  gap: 20px;
  color: ${COLORS.DARKS.CHARCOAL};

  ul{
    list-style: none;
    flex: 1;
    padding: 0;    
  }
  li{
    padding: 10px 0;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  box-shadow: 4px 6px 12px hsl(0deg 0% 0% / 0.2);
`;
