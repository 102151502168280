// dgarage.io account

export default {
  apiKey: 'AIzaSyDKno3ALsXvS5jQweG_Roy8qf7DtaQv0DM',
  authDomain: 'value-plan.firebaseapp.com',
  projectId: 'value-plan',
  storageBucket: 'value-plan.appspot.com',
  messagingSenderId: '422055362352',
  appId: '1:422055362352:web:dd191a19ae761db706d2f5',
  measurementId: 'G-XZSZ33RK4C',
};
