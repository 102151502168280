import {WIDTHS} from '@constants/style';
import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: ${WIDTHS.TABLET};
    min-height: 50vh;
    margin: 20px auto 60px;  
    padding: 0 20px; 
`;

export const Title = styled.h1`
    margin: 60px 0 20px;
`;

export const Subtitle = styled.h3`
    margin: 20px 0;
`;

export const P = styled.p`
    margin: 10px 0;
    font-size: 1rem;
`;

export const OL = styled.ol`
    margin: 0;
    padding: 0;
    margin-top: 20px;
    counter-reset: innerCounter;
`;

export const UL = styled.ul`
    margin: 0;
    padding: 0;
`;

export const LI = styled.li`
  padding: 0;
  margin: 0;
  margin-top: 10px;
  list-style: none;    
  counter-increment: innerCounter;
  p{
    &:first-of-type{
      display: inline;      
    }      
  }

  &::before{
    display: inline;
    content: counter(counter) "." counter(innerCounter);
    padding-right: 12px;    
  }
`;

export const LI1 = styled.li`
    counter-increment: counter;  
    margin-top: 20px;
    padding-left: 0;
    margin-left: 0;
`;

export const BOLD = styled.span`
    font-weight: 600;
    font-size: 1.2rem;
`;

export const LIA = styled.li`
    list-style: lower-alpha;
    margin: 8px 0;
`;
