import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';

import {COLORS, QUERIES} from '@constants/style';
import {NoBreak} from 'ui/misc/SharedWrappers';

interface AppProps {
  color?: string;
  onClick: () => void;
  disabled?: boolean;
  secondary?: boolean;
  small?: boolean;
}

export const Button = ({children, small=false, secondary = false, color = COLORS.PRIMARY.BLUE, onClick, ...props}: PropsWithChildren<AppProps>) => {
  if (secondary) {
    return (
      <SecondaryButton color={color} secondary={secondary} {...props} onClick={onClick}>
        <NoBreak>{children}</NoBreak>
      </SecondaryButton>
    );
  }

  return (
    <MainButton color={color} small={small} secondary={secondary} {...props} onClick={onClick}>
      <NoBreak>{children}</NoBreak>
    </MainButton>
  );
};

const BaseButton = styled.button<AppProps>`
  margin: 2px;
  font-size: 15px;
  border-radius: 6px;
  border: 2px solid transparent;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  height: 45px;
  min-width: ${({small}) => small ? 'none' : '186px'};
  padding: 0 20px;
  cursor: pointer;

  @media ${QUERIES.MOBILE}{
    padding: 0 10px;      
  }
  
  &:disabled {
    background-color: ${COLORS.LIGHTS.GREY2};
    border-color: ${COLORS.LIGHTS.GREY2};
    cursor: not-allowed;
    pointer-events: none;
  }
  &:disabled:hover {
    filter: brightness(1);
  }
  &:disabled:active {
    transform: none;
  }
  &:hover {
    filter: brightness(1.15);
  }
  &:active {
    transform: translateY(2px);
  }
`;

const MainButton = styled(BaseButton)`  
  color: ${COLORS.WHITE};
  background-color: ${({color}) => color};
  border-color: ${({color}) => color };
`;

const SecondaryButton = styled(BaseButton)`  
  color: ${COLORS.PRIMARY.BLUE};
  background-color: ${COLORS.WHITE};
  border-color: ${COLORS.WHITE};;
`;
