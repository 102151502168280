import Button from '@components/Button';
import {Hero} from '@components/Hero/Hero';
import {COLORS} from '@constants/style';
import React from 'react';
import styled from 'styled-components';


interface AppProps {
    backClick?: () => void;
    ctaClick?: () => void;
    color?: string;
    image?: string;
    isDisabled: boolean;
    pageTitle: string,
    pageSubtitle: string,
    heroTitle: string,
    heroSubtitle: string,
    heroCopy?: string,
    ctaCopy?: string
    isSecure: boolean,
}

export const LargeHeroHeader = ({color = COLORS.PRIMARY.PURPLE, isDisabled=false, ...props}: AppProps) => (
  <Header>
    <Top>
      <Left>
        <h1>{props.pageTitle}</h1>
        <p>{props.pageSubtitle}</p>
      </Left>
      <Right>
        {!props.isSecure && props.ctaClick && <Button small disabled={isDisabled} onClick={props.ctaClick}>{props.ctaCopy}</Button>}
      </Right>
    </Top>
    <Hero color={color} copy={props.heroCopy} src={props.image} subtitle={props.heroSubtitle} title={props.heroTitle} />
  </Header>
);


export const ThinHeroHeader = ({color = COLORS.PRIMARY.PURPLE, ...props}: AppProps) => (
  <Header>
    <Top>
      <Left>
        <h1>{props.pageTitle}</h1>
        <p>{props.pageSubtitle}</p>
      </Left>
      <Right>
        {!props.isSecure && props.ctaClick && <Button disabled={props.isDisabled} onClick={props.ctaClick}>{props.ctaCopy}</Button>}
      </Right>
    </Top>
    <ThinHero color={color}>
      <h2>{props.heroTitle}</h2>
      <h3>{props.heroSubtitle}</h3>
    </ThinHero>
  </Header>
);


const Header = styled.header`  
  grid-area: PAGE_HEAD; 
  p{
    color: ${COLORS.PRIMARY.BLUE};
    position: relative;
  }
`;

const Top = styled.div`
  display: flex;
`;

const Left = styled.div`  
  flex: 1;
  margin-left: 4px;
  h1{
    font-size: clamp(1.2rem, 4vw, 3rem);
  }
`;

const Right = styled.div`  
  display: flex;
  align-items: center;
`;

const ThinHero = styled.div`  
  height: max-content;
  color: white;  
  margin: 20px 0;
  padding: 40px;
  border-radius: 20px;
  overflow: hidden;  
  background-color: ${({color}) => color || COLORS.PRIMARY.PURPLE}; 
`;


