import {COPY} from '@constants/copy';
import {COLORS, SHADOWS} from '@constants/style';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSetPageTitle} from '@utils/hooks';
import React from 'react';
import {ClipLoader} from 'react-spinners';
import styled from 'styled-components';
import {SUBMIT_STATE, useHook} from './useHook.Login';

export function Login() {
  useSetPageTitle('Login');
  const {email, setEmail, submittedState, onSubmit, error} = useHook();

  return (
    <Wrapper>
      <InnerWrapper>
        {submittedState === SUBMIT_STATE.SUBMITTED && <LoginWarning /> }
        {submittedState === SUBMIT_STATE.INIT && (
          <>
            <Title>{COPY.LOGIN_TITLE}</Title>
            <Row>
              <EmailField
                type='text'
                value={email}
                placeholder='name@domain.com'
                onChange={(v) => setEmail(v.target.value.trim())}
                onKeyDownCapture={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit();
                  }
                }}
              />
              <Button onClick={onSubmit}>
                <FontAwesomeIcon icon={faArrowRight} size="2x"/>
              </Button>
            </Row>
          </>
        )}

        {submittedState === SUBMIT_STATE.SUBMITTING && (
          <>
            <Title>{COPY.LOGIN_TITLE}</Title>
            <Row>
              <EmailField
                type='text'
                value={email}
                readOnly={true}
              />
              <Button disabled={true}>
                <ClipLoader color='white' size={20}/>
              </Button>
            </Row>
          </>
        )}

        {submittedState === SUBMIT_STATE.ERROR && (
          <>
            <Title>{COPY.LOGIN_ERROR_TITLE}</Title>
            <p>{error}</p>
            <p>{COPY.LOGIN_ERROR_MSG}</p>
          </>
        )}

      </InnerWrapper>
    </Wrapper>
  );
}


function LoginWarning() {
  return (
    <Warning>
      <h4>{COPY.LOGIN_SUBMITTED}</h4>
      <ul>
        <h5>{COPY.LOGIN_WARNING_HEADER}</h5>
        <li>{COPY.LOGIN_WARNING_1} <em>{COPY.LOGIN_WARNING_EMAIL}</em></li>
        <li>{COPY.LOGIN_WARNING_2}</li>
        <li>{COPY.LOGIN_WARNING_3}</li>
      </ul>
    </Warning>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 66%;
  display: grid;
  place-content: center;
`;

const Title = styled.h4`
`;

const Warning = styled.div`
  
ul {  
  h5{ 
    padding: 20px 0 16px;
  }  
  padding: 0;
  li{
    font-size: 1rem;
    list-style: none;
    padding: 6px 0;    
    em{ 
      font-style: italic;
      font-weight: 600;
    }
  }
}
  

`;

const InnerWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid ${COLORS.LIGHTS.GREY2};
  box-shadow: ${SHADOWS.SECONDARY};
  color: ${COLORS.GREY600};  
`;

const EmailField = styled.input`
  height: 50px;
  border-radius: 10px;
  border: 1px solid ${COLORS.LIGHTS.GREY3};
  padding: 2px 10px;
  width: 100%;  
`;

const Button = styled.button`
  border: none;
  background: ${COLORS.PRIMARY.BLUE};
  width: 60px;
  height: 50px;
  border-radius: 10px;
  color: ${COLORS.WHITE};
  display: grid;
  place-content: center;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 1px 1px #ccc;
  &:active{
    top: 1px;
    box-shadow: none;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
`;
