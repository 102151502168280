import React, {useEffect} from 'react';
import styled from 'styled-components';

import {COPY} from '@constants/copy';
import {logAnayltics} from '@services/FirebaseHelper';
import {EVENTS} from 'ui/misc/Analytics';
import {useSetPageTitle} from '@utils/hooks';

export const NotFound = () => {
  useSetPageTitle('Not Found');
  useEffect(() => {
    logAnayltics(EVENTS.ERROR_404);
  }, []);


  return (
    <Wrapper>
      <h2>{COPY.NOTFOUND_TITLE}</h2>
      <h4>{COPY.NOTFOUND_SUBTITLE}</h4>
    </Wrapper>
  );
};

const Wrapper = styled.div`    
    width: 100%;
    height: 66%;
    display: grid;
    place-content: center;
`;
