import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';

import mainSlice from './slices/mainSlice';
import assessSlice from './slices/assessSlice';
import demoSlice from './slices/demoSlice';
import prioritiesSlice from './slices/prioritiesSlice';
import dataSourcesSlice from './slices/dataSourcesSlice';
import uuidSlice from './slices/uuidSlice';

import {StoreMiddleware} from './middleware';

export const store = configureStore({
  reducer: {
    assess: assessSlice,
    demo: demoSlice,
    main: mainSlice,
    uuid: uuidSlice,
    dataSources: dataSourcesSlice,
    priorities: prioritiesSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(StoreMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
