import React, {useContext} from 'react';
import {COPY} from '@constants/copy';

import {ResultsContext} from '../useHook.Results';
import {PlanView} from './PlanView';
import {COLORS} from '@constants/style';
import {useSetPageTitle} from '@utils/hooks';


export const PlanViewNow = () => {
  useSetPageTitle('Plan Now');
  const {summaryModel} = useContext(ResultsContext);


  if (!summaryModel) return <></>;

  return (
    <PlanView
      model={summaryModel.model.Foundation}
      title={COPY.PLAN_NOW_HERO_TITLE}
      subtitle={COPY.PLAN_NOW_HERO_SUBTITLE}
      color={COLORS.PRIMARY.BLUE}
    />
  );
};
