
import {INVALID_EMAIL_DOMAINS} from '@constants/const';
import {REGEX_EMAIL} from '@constants/regex';
import {AssessmentAnswers, AssessmentType, IAssessment, ICompletedAssessment, IDemographics, IPriority} from './model/interfaces';
import {Model} from './model/model';

export function isWholeAssessmentComplete({model, answers}: {model: Model, answers: ICompletedAssessment}): boolean {
  // check to make sure priorities has 6 items
  const prioritiesComplete = isPrioritesComplete({answers: answers.priorities, questions: model.prioritesAsList()});

  // check to make sure sum of assessment answers > 0<
  const assessComplete = isAssessmentComplete({
    answers: answers.assessment,
    questions: model.data.assessment,
  });

  // check to make sure no answers in demo are null
  const demoComplete = isDemoComplete(answers.demo);

  // check to make sure at least 1 data sources provided
  const dataSourcesComplete = isDataSourcesComplete(answers.sources);

  return demoComplete && prioritiesComplete && assessComplete && dataSourcesComplete;
}

export function isPrioritesComplete({answers, questions}: {answers: string[], questions: IPriority[]}): boolean {
  return answers.length === questions.length;
}

export function isAssessmentComplete({questions, answers}: {questions: AssessmentType, answers: AssessmentAnswers}): boolean {
  return Object.values(questions).reduce((accum: number, question: IAssessment) => {
    accum += answers[question.key] || 0;
    return accum;
  }, 0) >= 0;
}


export function isDemoComplete(demo: IDemographics): boolean {
  return ![demo.firstName,
    demo.lastName,
    demo.email,
    demo.companyName,
    demo.number_of_employees,
    demo.number_of_csms,
    demo.industry].includes(null);
}

export function isDataSourcesComplete(sources: any[]): boolean {
  return sources.length > 0;
}

export const isValidEmail = (v: string) => !v.match(REGEX_EMAIL);

export const isPersonalEmail = (v: string) => {
  const domain = v.substring( v.lastIndexOf('@')+1).toLocaleLowerCase();
  return INVALID_EMAIL_DOMAINS.includes(domain);
};
