import {COLORS, QUERIES} from '@constants/style';
import React from 'react';
import styled from 'styled-components';

interface AppProps {
    color: string;
    copy?: string;
    title: string;
    subtitle: string;
    src?: string;
}

export const Hero = ({color, src, title, subtitle, copy}: AppProps) => {
  return (

    <Wrapper color={color}>
      {src && <Left><img src={src}/></Left>}
      <Right>
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        { copy && <Copy>{copy}</Copy> }
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled.div<{color: string}>`  
  background-color: ${({color}) => color || COLORS.PRIMARY.PURPLE};  
  border-radius: 10px;
  margin: 10px 0;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: white;  
`;

const Left = styled.div`
  display: grid;
  place-items: center;  

  @media ${QUERIES.MOBILE} {
    display: none;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${QUERIES.MOBILE} {
    padding: 0 20px;
  }
`;

const Title = styled.h2`
    font-size: clamp(1rem, 3vw, 2rem);       
    line-height: 1.4; 
    white-space: nowrap;       
`;
const SubTitle = styled.h3`
    font-weight: 400; 
    @media ${QUERIES.TABLET} {    
      font-size: clamp(.8rem, 3vw, 1.6rem); 
    }
    font-size: clamp(.9rem, 3vw, 1.6rem); 
    line-height: 1.4; 
    padding: 1.2vw 0 1vw;  
`;

const Copy = styled.p`
    font-size: clamp(.7rem, 1.6vw, 1rem); 
    color: ${COLORS.WHITE} !important;      
    padding-right: 20px;    
`;
