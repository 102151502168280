import Button from '@components/Button';
import FormCell from '@components/FormCell';
import {COPY} from '@constants/copy';
import {DEMO_FIELDS} from '@constants/fields';
import {COLORS} from '@constants/style';
import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';
import {useHook} from './useHook.RequestDemoForm';


export const RequestDemoForm = () => {
  const {phone, isSubmitted, onChange, submit, setIsError, onModalClose, isDisabled} = useHook();

  const Thanks = () => (
    <>
      <h3 style={{textAlign: 'center'}}>{COPY.DEMO_FORM_THANKYOU_TITLE}</h3>
      <p style={{textAlign: 'center'}}>{COPY.DEMO_FORM_THANKYOU_COPY}</p>
      <Bottom>
        <Button disabled={isDisabled} onClick={onModalClose}>{COPY.DEMO_FORM_THANKYOU_CTA}</Button>
      </Bottom>
    </>
  );


  return (
    <Wrapper>
      <Title>{COPY.DEMO_FORM_TITLE}</Title>
      {!isSubmitted && (
        <>
          <Msg>{COPY.DEMO_FORM_COPY}</Msg>
          <InputRow>
            <FormCell field={DEMO_FIELDS.PHONE}>
              <MyPhoneInput
                placeholder="Enter phone number"
                country={'US'}
                defaultCountry={'US'}
                value={phone}
                onBlur={() => setIsError(isDisabled)}
                onChange={(number: any) => onChange(String(number))}/>
            </FormCell>
          </InputRow>

          <Bottom>
            <Button disabled={isDisabled} onClick={submit}>Request Demo</Button>
          </Bottom>
        </>
      )}

      {isSubmitted && <Thanks />}
    </Wrapper>
  );
};

const MyPhoneInput = styled(PhoneInput)<{isError: boolean, country: string, defaultCountry: string}>`
  border: 1px solid ${({isError}) => isError ? 'red' : COLORS.LIGHTS.GREY3};
  padding: 4px 8px;
  border-radius: 4px;
  height: 50px;
  input:focus-visible{
    
  }
  input{ 
      border: 0px;
      height: 100%;
      padding-left: 8px;
    }
`;

const Wrapper = styled.div`    
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 0;
    /* height: 50px; */
    
`;

const Title = styled.h2`
  text-align: center;  
  transform: translateY(-50px);
`;

const Msg = styled.p`
  max-width: 600px;
  margin: 0 auto;
`;

const InputRow = styled.div`
  width: clamp(240px, 80%, 400px);
  margin: 0 auto;
`;

const Bottom = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 20px;
`;
