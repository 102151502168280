import Button from '@components/Button';
import {ThinHeroHeader} from '@components/HeroHeader/HeroHeader';
import {COPY} from '@constants/copy';
import {COLORS, QUERIES} from '@constants/style';
import {CALCULATOR_URL} from '@constants/urls';
import {ISequenceContentModel} from '@data/model/interfaces';
import React, {useContext} from 'react';
import styled from 'styled-components';
import {DetailedPlanTile} from '../DetailedPlanTile/DetailedPlanTile';
import {MetricsTile} from '../MetricsTile/MetricsTile';
import {ResultsContext} from '../useHook.Results';


interface PlanViewProps {
  model: ISequenceContentModel;
  title: string;
  subtitle: string;
  color: string;
}

export const PlanView = ({model, color, title, subtitle}: PlanViewProps) => {
  const {userDataModel, onModalOpen, isSecure} = useContext(ResultsContext);

  return (
    <Grid>
      <ThinHeroHeader
        ctaClick={onModalOpen}
        ctaCopy={COPY.CTA_CTA}
        pageTitle={COPY.PLAN_TITLE}
        pageSubtitle={userDataModel.demo.companyName || ''}
        heroTitle={title}
        heroSubtitle={subtitle}
        isDisabled={false}
        color={color}
        isSecure={isSecure}
      />

      <DetailedPlanTile
        style={{gridArea: 'JOURNEY_PLAN'}}
        outcomes={model.outcomes}
      />

      <Right>

        {/* <pre>{JSON.stringify(model.metrics, null, 2)}</pre> */}

        <MetricsTile
          style={{gridArea: 'JOURNEY_METRICS'}}
          metrics={model.metrics}
        />
        <PromoBlock
          href={CALCULATOR_URL}
          target="_blank"
          style={{gridArea: 'PROMO'}}>
          <div>
            {COPY.PLAN_CTA_CALCULATOR}
          </div>
          <img src="/images/calculator.svg" alt="Calculator"/>
        </PromoBlock>

        {!isSecure &&(
          <PromoBlock2 style={{gridArea: 'PROMO2'}}>
            <div>{COPY.PLAN_CTA_DEMO}</div>
            <Button secondary onClick={onModalOpen}>{COPY.CTA_CTA}</Button>
            <img src="/images/demo_screenshot.png" alt="demo"/>
          </PromoBlock2>
        )}
      </Right>
    </Grid>
  );
};


const Grid = styled.div`  
  
  display: grid;
  /* width: 100%; */
  gap: 10px;
  margin-right: 10px;
  grid-template-columns: 60% 40%;
  
  grid-template-areas: 
  'PAGE_HEAD PAGE_HEAD'
  'JOURNEY_PLAN RIGHT';

  @media ${QUERIES.TABLET} {  
    grid-template-columns: 100%;
    grid-template-areas: 
    'PAGE_HEAD'
    'JOURNEY_PLAN'
    'RIGHT'  
    ;      
    margin-right: 0px;
  }
`;

const Right = styled.div`
  grid-area: "RIGHT";
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PromoBlock = styled.a`
  color: ${COLORS.WHITE};
  background-color: ${COLORS.PRIMARY.PURPLE};  
  font-weight: 800;
  font-size: 1.4rem;
  border-radius: 10px;
  text-decoration: none;
  display: flex;
  justify-content: space-between; 
  align-items: center; 
  padding: 10px;
  height: max-content;
  div{
    width: 60%;
    padding-left: 30px;
    
  }
`;


const PromoBlock2 = styled.div`
  color: ${COLORS.WHITE};
  background-color: ${COLORS.PRIMARY.BLUE};  
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  
  align-items: center;
  justify-content: flex-end;
  height: fit-content;
  div{
    font-size: 1.8rem;
    font-weight: 800;
    width: 60%;
    text-align: center;
    padding: 10px 0;
    
  }
`;
