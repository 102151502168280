
import React from 'react';
import styled from 'styled-components';
import Button from '@components/Button';

import Card from '@views/Home/HomeCard';
import useHook from './useHook.Home';
import {COPY} from '@constants/copy';
import ReloadSession from './ReloadSession';
import Modal from '@components/Modal';
import SamplePlan from './SamplePlan';
import {PageWrapper} from '@components/PageWrapper';
import {QUERIES, COLORS, WIDTHS} from '@constants/style';
import {useSetPageTitle} from '@utils/hooks';

export const Home = () => {
  useSetPageTitle('Home');
  const {onClick, close, onCreateNew, onLoadExisting, onShowSample, showDialog, dialogBody} = useHook();

  const modals = {
    reload: {
      label: 'Reload Report?',
      cmp: <ReloadSession onCreateNew={onCreateNew} onLoadExisting={onLoadExisting}/>,
    },
    sample: {
      label: 'Sample Gainsight Plan',
      cmp: <SamplePlan onClose={close}/>,
    },
  };

  return (
    <PageWrapper color={COLORS.PRIMARY.BLUE}>

      <Wrapper>
        <TopWrapper>
          <Title1>{COPY.HOME_TITLE1}</Title1>
          <Title2>{COPY.HOME_TITLE2}</Title2>
          <Copy>{COPY.HOME_BODY}</Copy>
          <Button secondary onClick={onClick}>{COPY.HOME_CTA}</Button>
          <PlanLink onClick={onShowSample}>{COPY.HOME_CTA_LINK}</PlanLink>
        </TopWrapper>
        <BottomWrapper style={{position: 'relative'}}>

          <Star style={{position: 'absolute', zIndex: 0}} src="/images/star.svg" alt="Background doodle" />
          <Doodle style={{position: 'absolute', zIndex: 0}} src="/images/doodle.svg" alt="Background doodle" />

          <CardWrapper style={{position: 'relative', zIndex: 1}}>
            <Card title={COPY.HOME_CARD1_TITLE} body={COPY.HOME_CARD1_BODY} />
            <Card title={COPY.HOME_CARD2_TITLE} body={COPY.HOME_CARD2_BODY} />
            <Card title={COPY.HOME_CARD3_TITLE} body={COPY.HOME_CARD3_BODY} />
          </CardWrapper>

        </BottomWrapper>

        <Modal
          showDialog={showDialog}
          close={close}
          label={modals[dialogBody as keyof typeof modals].label}
          body={modals[dialogBody as keyof typeof modals].cmp}
        />
      </Wrapper>

    </PageWrapper>
  );
};

const Wrapper = styled.div` 
  max-width: ${WIDTHS.BASE};
  margin: 0 auto;
  display: flex;   
  height: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 80px 20px 100px 20px;    
  @media ${QUERIES.TABLET} {
    padding: 10px 20px 20px;
  }
`;

const IMGBase = styled.img`
  display: none;
  display: block;
`;

const Star = styled(IMGBase)`
  left: -100px;
  top: -80px;
`;

const Doodle = styled(IMGBase)`  
  right: -110px;
  top: -120px;
  @media ${QUERIES.TABLET} {
    display: none;
  }
`;

const TopWrapper = styled.div`      
  padding: 50px 0;  
  text-align: center;
  color: ${COLORS.WHITE};  
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title1 = styled.h1`
  font-size: 3rem;
  padding-bottom: 10px;
`;

const Title2 = styled(Title1)`
  font-weight: 800;
`;

const Copy = styled.p`
  width: 80%;
  @media ${QUERIES.TABLET} {
    width: 100%;
  }
  padding: 30px 0;
`;

const BottomWrapper = styled.div` 
  height: max-content;
  width: 80%;
  background-color: ${COLORS.PRIMARY.BLUE};  
  border-radius: 10px;  
`;

const CardWrapper = styled.ol`  
  display: flex;  
  flex-direction: row;
  margin: 0;  
  padding: 0;
  gap: 20px;
  justify-content: space-between;
  counter-reset: listCounter;
  /* border: 1px solid red; */
  padding: 20px;
  background-color: ${COLORS.LIGHTS.BLUE};
  border-radius: 10px;

  @media ${QUERIES.TABLET} {  
    flex-direction: column;
                 
    margin: 0 auto;
  }
`;

const PlanLink = styled.button`
  color: ${COLORS.WHITE};
  border: none;
  background: none;
  font-size: 1rem;
  font-weight: 800;
  padding-top: 10px;
  cursor: pointer;
  text-decoration: none;

  &::after{
    font: var(--fa-font-solid);
    content: "\f061";    
    padding-left: 6px;
    text-decoration: none;
  }
`;
