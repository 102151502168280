import React, {PropsWithChildren, useContext} from 'react';
import styled from 'styled-components';

import {INTEGRATIONS, DATA_SOURCE_TYPES, INTEGRATION_TYPES_LABELS} from '@data/dataSources';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {DataSourcesContext} from '@views/DataSources/useHook.DataSources';
import {QUERIES, SHADOWS, COLORS} from '@constants/style';

export const DataSourcesDragDrop = () => {
  const {selected, remove, onDragEnd} = useContext(DataSourcesContext);

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Left>
          <h4>Sources of Customer Data:</h4>
          <Grid>
            {Object.values(DATA_SOURCE_TYPES)
                .map((type, i) => (
                  <IntegrationTypeWrapper key={i}>
                    <h5 key={i}>{INTEGRATION_TYPES_LABELS[type]}</h5>
                    <Droppable droppableId={type}isDropDisabled={true} >
                      {(provided) => (
                        <SourceList {...provided.droppableProps} ref={provided.innerRef} items={INTEGRATIONS[type].length}>
                          {INTEGRATIONS[type]
                              // filter out selected ones
                              .filter((v) => !selected.map((s) => s.id).includes(v.id) )
                              .map((source, i2) => (
                                <Draggable key={source.id} draggableId={source.id} index={i2} isDragDisabled={selected.length == 10} >
                                  {(provided2) => (
                                    <div
                                      {...provided2.draggableProps}
                                      {...provided2.dragHandleProps}
                                      ref={provided2.innerRef}>
                                      <GrippableWrapper>{source.label}</GrippableWrapper>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                        </SourceList>
                      )}
                    </Droppable>
                  </IntegrationTypeWrapper>
                ))}
          </Grid>
        </Left>

        <Right>
          <RankHeader>Your Data Sources</RankHeader>
          <Droppable droppableId='priorities' >
            {(provided) => (
              <SelectedDropzone {...provided.droppableProps} ref={provided.innerRef}>
                {selected.length === 0 && (
                  <EmptyList>
                    <h4>
                        Drag &amp; Drop Up-to 10 <br/>
                        in Order of Importance
                    </h4>
                  </EmptyList>
                )}

                {selected.map((selectedItem, i) => (
                  <Draggable key={selectedItem.id} draggableId={selectedItem.id} index={i} >
                    {(provided2) => (
                      <div
                        {...provided2.draggableProps}
                        {...provided2.dragHandleProps}
                        ref={provided2.innerRef}
                      >
                        <GrippableWrapper onClose={() => remove(selectedItem.id)} closeable>{selectedItem.label}</GrippableWrapper>
                      </div>
                    )}
                  </Draggable>
                ))}
              </SelectedDropzone>
            )}
          </Droppable>
        </Right>
      </DragDropContext>
    </Wrapper>
  );
};

interface GrippableWrapperProps {
  children: any,
  onClose?: () => void,
  closeable?: boolean
}

const GrippableWrapper = ({children, onClose, closeable=false}: PropsWithChildren<GrippableWrapperProps>) => (
  <SourceItemWrapper>
    <span>{children}</span>
    {closeable && <div onClick={onClose}/>}
  </SourceItemWrapper>
);

const Wrapper = styled.div`
    display: flex;
    @media ${QUERIES.TABLET} {   
      position: relative; 
      flex-direction: column-reverse;
      gap: 20px;
    }
    width: 100%;
    padding: 20px 0px;           
    margin-bottom: 40px;
`;

const EmptyList = styled.div`
  height: 100%;  
  display: grid;
  place-content: center;
  position: relative;
  h4{
    color: ${COLORS.DARKS.DARK_GREY};
    @media ${QUERIES.TABLET} {    
      position: relative;
      top: 14px;
    }    
  }
`;

const Grid = styled.div`  
  display: grid;
  gap: 16px;  
  grid-template-columns: 50% 50%;    
  width: 100%;
  @media ${QUERIES.TABLET} {  
    grid-template-columns: 100%;     
    padding: 0px 40px;
  }
  
`;

const Left = styled.div`
  flex: 3;
  h4 {
      padding-bottom: 1rem;
  }  
`;

const IntegrationTypeWrapper = styled.div`
  h5 {
    color: ${COLORS.DARKS.DARK_GREY};
    margin-left: 6px;
  }
`;

const Right = styled.div` 
  background-color:${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTS.GREY};
  border-radius: 20px;
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-left: 64px;    
  @media ${QUERIES.TABLET} {    
    margin-left: 0px;
    position: sticky;
    top: 0;
  }
  box-shadow: ${SHADOWS.SECONDARY};    
  h4{
      text-align: center;
  }
`;

const SourceList = styled.ul<{items: number}>`
  list-style: none;
  margin: 10px 0 20px 0;
  padding: 0;    
  height: calc(2.6rem * ${(props) => props.items});  
  margin-right: 16px;
  @media ${QUERIES.TABLET} {  
    margin-right: 0;    
  }
  
`;

const SourceItemWrapper = styled.div`
    
    padding: 6px 20px;    
    background: ${COLORS.LIGHTS.LIGHT_GREY};
    border: 1px solid ${COLORS.LIGHTS.GREY2};
    border-radius: 8px;
    margin: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    span {
      &::before{
        font: var(--fa-font-solid);            
        content: "\f58e";
        color: ${COLORS.DARKS.DARK_GREY};
        padding-right: 20px;
      }
      white-space: nowrap;
      overflow: hidden;     
    }
    div {
      cursor: pointer;
      width: 20px;
      height: 20px;
      &::before{
        font: var(--fa-font-solid); 
        font-size: 1.4rem;
        content: "\f146";
        color: ${COLORS.DARKS.DARK_GREY};
        left: 50%;
        position: relative
      }
    }    
`;

const SelectedDropzone = styled.ul`
    border: 1px dashed ${COLORS.DARKS.DARK_GREY};
    background: ${COLORS.LIGHTS.OFF_WHITE};
    height: 100%;
    padding: 20px;  
    margin: 20px;
    border-radius: 16px;
    @media ${QUERIES.TABLET} {    
      padding-bottom: 44px;
    }
`;

const RankHeader = styled.h5`
  color: ${COLORS.PRIMARY.NAVY};
  text-align: center;
  padding: 20px 0 0 0;  
`;
