import {ROUTE_PATH} from '@constants/routes';
import {useAppDispatch, useAppSelector} from '@services/store/hooks';
import {LOADING_STATES} from '@services/store/slices/mainSlice';
import {clearDataAsync, loadAssessmentByUUIDAndEmail} from '@services/store/thunks';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

export function useHook() {
  const {id} = useParams();
  const {isLoading, isInitialized, email, uuid} = useAppSelector((state) => {
    return {
      isLoading: state.main.isLoading,
      isInitialized: state.main.isInitialized,
      uuid: state.uuid.uuid,
      email: state.demo.data.email || '',
    };
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isShowEmailChallenge, setIsShowEmailChallenge] = useState(false);

  useEffect(() => {
    if (!isInitialized) return;
    if (!(id && id.length > 0)) return;

    // UUIDs don't match, clear data, and show login screen
    if (id !== uuid) {
      dispatch(clearDataAsync());
      setIsShowEmailChallenge(true);
      return;
    }

    dispatch(loadAssessmentByUUIDAndEmail({uuid: id, email}));
  }, [id, uuid, isInitialized]);

  useEffect(() => {
    if (isLoading === LOADING_STATES.COMPLETED) {
      setIsShowEmailChallenge(false);
      navigate(ROUTE_PATH.DASHBOARD);
    }
  }, [isLoading]);

  const submitEmail = (localEmail: string) => {
    dispatch(loadAssessmentByUUIDAndEmail({uuid: id || '', email: localEmail}));
  };

  return {submitEmail, isShowEmailChallenge, isLoading};
}
