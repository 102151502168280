import Button from '@components/Button';
import React from 'react';
import styled from 'styled-components';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faRetweet} from '@fortawesome/free-solid-svg-icons';

import {COLORS, QUERIES} from '@constants/style';

interface AppProps {
    onLoadExisting: () => void;
    onCreateNew: () => void;
}

export const ReloadSession = ({onCreateNew, onLoadExisting}: AppProps) => {
  return (
    <Wrapper>
      <FontAwesomeIcon icon={faRetweet} size="5x"/>
      <h2>Reload Report?</h2>
      <p>
        You&apos;ve already created a report. Would you like to reload
        the existing one or create a new one?
      </p>
      <Bottom>
        <Button onClick={onCreateNew}>Create New</Button>
        <Button color={COLORS.PRIMARY.NAVY} onClick={onLoadExisting}>Load Existing</Button>
      </Bottom>
    </Wrapper>
  );
};

const Wrapper = styled.div`    
    text-align: center;
    p{
        padding: 50px;
    }    
`;

const Bottom = styled.div`
    padding: 50px 0 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    @media ${QUERIES.MOBILE} {  
      flex-direction: column;      
    }
    
`;
