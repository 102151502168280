import Dialog from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';
import React from 'react';
import styled from 'styled-components';
import '@reach/dialog/styles.css';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons';

import {COLORS} from '@constants/style';

type ModalProps = {
    close: () => void;
    label: string;
    body: React.ReactElement;
    showDialog: Boolean;
}

export const Modal = ({body, label='modal label', showDialog, close}: ModalProps) => {
  return (

    <MyDialog
      aria-label={`Modal: ${label}`}
      isOpen={Boolean(showDialog)} onDismiss={close}>
      <CloseButton onClick={close}>
        <VisuallyHidden>Close</VisuallyHidden>
        <span aria-hidden>
          <FontAwesomeIcon icon={faCircleXmark} size="2x"/>
        </span>
      </CloseButton>
      <Body>
        {body}
      </Body>
    </MyDialog>

  );
};

const MyDialog = styled(Dialog)`
    border: none;
    border-radius: 10px;
    position: relative;        
    width: clamp(360px, 80vw, 1000px);
`;

const CloseButton = styled.button`
    border: 0;
    padding: 0;
    margin: 0;
    background: none;    
    position: absolute;
    top: 10px;
    right: 10px;
    color: ${COLORS.DARKS.DARK_GREY};
    cursor: pointer;
`;

const Body = styled.div`    
  margin-top: 20px;
`;

const Wrapper = styled.div``;
