import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

interface IMTooltipProps {
  id: string;
}

export const PrioritiesTooltip = ({id, ...props}: IMTooltipProps) => {
  const Wrapper = styled(ReactTooltip)`
    &.place-left,
    &.place-right {
      width: 300px;
      color: revert;
    }
  `;

  return (
    <Wrapper
      {...props}
      id={id}
      html={true}
      delayHide={250}
      place='left'
      effect='solid'
      type='dark'
      backgroundColor='#ccc'
    />
  );
};

export const AssessTooltip = ({id, ...props}: IMTooltipProps) => {
  const Wrapper = styled(ReactTooltip)`
    &.place-top,
    &.place-left,
    &.place-right,
    &.place-bottom {
      width: 300px;
      filter: drop-shadow(2px 2px 2px #ccc);
      color: revert;
    }
  `;

  return (
    <Wrapper
      {...props}
      id={id}
      html={true}
      delayHide={1000}
      place='bottom'
      effect='solid'
      type='dark'
      backgroundColor='#ccc'
    />
  );
};
