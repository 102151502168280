import {EMAIL_FOR_SIGNIN} from '@constants/config';
import {auth} from '@services/FirebaseHelper';
import {actionCodeSettings, sendSignInLinkToEmail} from '@services/AuthHelper';
import {useState} from 'react';

export enum SUBMIT_STATE {
  INIT,
  SUBMITTING,
  SUBMITTED,
  ERROR
}

export function useHook() {
  const [email, setEmail] = useState<string>('');
  const [submittedState, setSubmittedState] = useState<SUBMIT_STATE>(SUBMIT_STATE.INIT);
  const [error, setError] = useState<string>('');

  async function onSubmit() {
    setSubmittedState(SUBMIT_STATE.SUBMITTING);
    try {
      if (email) {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
        window.localStorage.setItem(EMAIL_FOR_SIGNIN, email);
        setSubmittedState(SUBMIT_STATE.SUBMITTED);
      }
    } catch (error) {
      setSubmittedState(SUBMIT_STATE.ERROR);
      setError(String(error));
      console.error({error});
    }
  }
  return {submittedState, setSubmittedState, email, setEmail, error, onSubmit};
}
