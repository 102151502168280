
import {DefaultDemographics} from '@data/model/defaults';
import {createSlice} from '@reduxjs/toolkit';

const initialState = {...DefaultDemographics};

export const demoSlice = createSlice({
  name: 'demo',
  initialState: {
    data: initialState,
  },
  reducers: {
    init: (state, {payload}) => {
      state.data = {...payload};
    },
    clear: (state) => {
      state.data = {...initialState};
    },
    update: (state, {payload}) => {
      state.data = {...payload};
    },
  },
});

export const {init, clear, update} = demoSlice.actions;

export default demoSlice.reducer;
