import {ROUTE_PATH} from '@constants/routes';
import {BASE_URL} from '@constants/urls';

export {sendSignInLinkToEmail, onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink} from 'firebase/auth';


export const actionCodeSettings = {
  /**
     * URL you want to redirect back to. The domain (www.example.com) for this
     * URL must be in the authorized domains list in the Firebase Console.
     **/
  url: `${BASE_URL}${ROUTE_PATH.AUTH}`,

  /** This must be true **/
  handleCodeInApp: true,
};
