import {convertSources} from '@data/model/utils';
import {formatDateFromSeconds} from '@utils/misc';
import {db} from './FirebaseHelper';
import {getDocs, collection, query, where} from './FirestoreHelper';

export const fetchAllReports = async (): Promise<any[]> => {
  const results: any[] = [];

  const querySnapshot = await getDocs(collection(db, 'records'));
  querySnapshot.forEach((doc: any) => results.push(doc.data()));
  return results;
};

export const fetchReportByUUID = async (uuid: string): Promise<any[]> => {
  const results: any[] = [];

  try {
    const refs = collection(db, 'records');
    const q = query(refs, where('uuid', '==', uuid));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc: any) => {
      const obj = {...doc.data()};
      obj.createdAt = formatDateFromSeconds(obj.createdAt.seconds);
      obj.sources = convertSources(obj.sources);
      results.push({...obj});
    });
  } catch (e) {
    console.error(e);
  }
  return results;
};

