import React, {useContext} from 'react';
import {COPY} from '@constants/copy';

import {ResultsContext} from '../useHook.Results';
import {PlanView} from './PlanView';
import {COLORS} from '@constants/style';
import {useSetPageTitle} from '@utils/hooks';

export const PlanViewLater = () => {
  useSetPageTitle('Plan Later');
  const {summaryModel} = useContext(ResultsContext);

  if (!summaryModel) return <></>;

  return (
    <PlanView
      model={summaryModel.model.Advanced}
      title={COPY.PLAN_LATER_HERO_TITLE}
      subtitle={COPY.PLAN_LATER_HERO_SUBTITLE}
      color={COLORS.PRIMARY.PINK}
    />
  );
};
