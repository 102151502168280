import React from 'react';

import {COLORS} from '@constants/style';
import {SHADOWS} from '@constants/style';
import styled from 'styled-components';
import {COPY} from '@constants/copy';

interface PeerResultBarProps {
    min: number;
    max: number;
}

export const PeerResultBar = ({min, max}: PeerResultBarProps) => {
  return (
    <Wrapper>
      {max > min && (
        <Inner min={min} max={max}>
          <Tooltip>
            <p>{COPY.parse(COPY.DASHBOARD_ASSESSMENT_TILE_PEER_TOOLTIP, {min: min/10, max: max/10})}</p>
            <Triangle />
          </Tooltip>
        </Inner>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`        
    height: 10px;
    position: relative;
    width: 100%;
    margin: 5px;
    position: relative; 
    transform: translateX(-5px) ;
`;

const Inner = styled.div<PeerResultBarProps>`    
    height: 100%;
    border-radius: 5px;
    position: absolute;    
    background-color: ${COLORS.PRIMARY.YELLOW}; 
    left: ${({min}) => (min)+`%`};
    width: ${({min, max}) => (max-min)+`%`};   
    div{        
        display: none;          
    }
    &:hover{
      div:first-of-type{        
        display: block;        
      }
    } 
`;

const Tooltip = styled.div`
    border: 1px solid ${COLORS.LIGHTS.GREY};      
    position: relative;      
    background: ${COLORS.WHITE};
    padding: 10px;
    min-width: max-content;
    transform: translateX(calc(-100% - 14px)) translateY(calc(-50% + 2px));  
    will-change: filter;      
    filter: drop-shadow(${SHADOWS.PRIMARY});
    div{
      position: absolute;
      transform: translateX(100%) translateY(-100%);
      right: 0;      
    }
`;

const Triangle = styled.div`
  background: ${COLORS.WHITE};
  height: 20px;
  width: 20px;
  clip-path: polygon(0% 0%, 0% 100%, 50% 50%);
  
`;
