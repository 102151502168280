import React, {useContext} from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import DraggableItem from '@views/Priorities/DraggableItem';
import {PrioritiesContext} from '@views/Priorities/useHook.Priorities';
import {COPY} from '@constants/copy';
import {SHADOWS, QUERIES, COLORS} from '@constants/style';

import useModel from '@data/model/useModel';
import {makePriorityDisplay} from '@utils/misc';

export const DragDropPriorities = () => {
  const {startList, endList, onDragEnd} = useContext(PrioritiesContext);
  const model = useModel();

  return (
    <Wrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Left>
          <Droppable droppableId='start'>
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                <DropAreaStart>
                  {startList.map((d, i) => {
                    return (
                      <Draggable key={d.key} draggableId={d.key} index={i}>
                        {(provided) => (
                          <Item
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}>
                            <DraggableItem priority={makePriorityDisplay(model.data.version, d)}/>
                          </Item>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </DropAreaStart>
              </List>
            )}
          </Droppable>
        </Left>
        <Right>
          <RankHeader>{COPY.PRIORITIES_RANKHEADING}</RankHeader>
          <Droppable droppableId='end'>
            {(provided) => (
              <DropAreaEnd {...provided.droppableProps} ref={provided.innerRef}>

                {endList.length === 0 && (
                  <EmptyList>
                    <h4>{parse(COPY.PRIORITIES_EMPTYLIST)}</h4>
                  </EmptyList>

                )}

                {endList.map((d, i) => {
                  return (
                    <DroppedItemWrapper key={i}>
                      <Draggable draggableId={d.key} index={i}>
                        {(provided) => (
                          <>
                            <DroppedItem
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}>
                              <DraggableItem priority={makePriorityDisplay(model.data.version, d)}/>
                            </DroppedItem>
                          </>
                        )}
                      </Draggable>
                    </DroppedItemWrapper>
                  );
                })}
                {provided.placeholder}

              </DropAreaEnd>
            )}
          </Droppable>
        </Right>
      </DragDropContext>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media ${QUERIES.TABLET} {
    flex-direction: column-reverse;
    gap: 20px;
  }  
  gap: 0px;    
  align-self: center;
`;

const Left = styled.div`
  flex: 1;
  width: 100%;
  @media ${QUERIES.TABLET} {    
    width: calc(100% - 44px); 
    margin: 0 auto;    
  }
  /* border: 1px solid red; */
`;

const DropAreaStart = styled.div`  
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: 0;
  padding-left: 0;    
  border-radius: 10px;  

  margin-right: 20px;
  margin: 0 40px;
  @media ${QUERIES.TABLET} { 
    margin: 0;
    padding: 0;
  }
  
`;


const Item = styled.div`
  width: 100%;  
  list-style: none;
`;

const DroppedItem = styled.div`
  width: 100%;  
`;
const DroppedItemWrapper = styled.div`
   width: 100%;  
   display: flex;
   flex-direction: row;
   align-content: center;
   align-items: center;      
`;

const List = styled.div`
  padding: 0;
  margin: 0;
`;


const Right = styled.div`
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTS.GREY};
  border-radius: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: calc(100%-10px);
  box-shadow: ${SHADOWS.SECONDARY};    
  h4{
      text-align: center;
  }
  @media ${QUERIES.TABLET} {
    position: sticky;
    top: 0;    
  }
`;

const RankHeader = styled.h5`
  color: ${COLORS.PRIMARY.NAVY};
  text-align: center;
  padding: 20px 0 0 0;
`;


const DropAreaEnd = styled.div`
  border: 1px dashed ${COLORS.DARKS.DARK_GREY};
  background: ${COLORS.LIGHTS.OFF_WHITE};  
  padding: 20px;  
  margin: 20px;
  border-radius: 16px;
  list-style: none;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  @media ${QUERIES.TABLET} {
    min-height: 200px;  
    padding: 10px;  
    margin: 10px;
  } 

  @media ${QUERIES.MOBILE} {    
    padding: 10px;  
    margin: 5px;
  } 
  height: 100%;

`;

const EmptyList = styled.div`   
  display: grid;
  place-content: center;
  h4{
    color: ${COLORS.DARKS.DARK_GREY};
    transform: translateY(100%);
  }
  
  /* border: 1px solid red; */
`;
