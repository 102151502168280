import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {IAssessment} from '@data/model/interfaces';

// Centralize Contexts
import {AssessContext} from '@views/Assess/useHook.Assess';

import {VisuallyHidden} from 'ui/misc/SharedWrappers';
import {QUERIES, SHADOWS, COLORS} from '@constants/style';

interface AppProps {
  question: IAssessment;
}

export const Dimension = ({question}: AppProps) => {
  const {results, updateResult} = useContext(AssessContext);
  const [val, setVal] = useState(results[question.key]);

  useEffect(() => {
    if (results) {
      setVal(results[question.key]);
    }
  }, [results, question.key]);

  const update = (value: number) => {
    setVal(Number(value));
    updateResult(question.key, Number(value));
  };

  return (
    <Wrapper>
      <QuestionWrapper>
        <img src={'/images/dimensions/'+question.icon} alt={question.key}/>
        <h3>{question.displayName}</h3>
        <p>{question.description}</p>
        <SliderWrapper>
          <Left>
            <HoverLink data-tip={question.lowDescription} data-for='assessTT'>
              <h5>{question.lowLabel}</h5>
              <ShortDesc>Need Help!</ShortDesc>
            </HoverLink>
          </Left>

          <label>
            <VisuallyHidden>{question.displayName}</VisuallyHidden>
            <Range
              style={{backgroundSize: `${val}%  100%`}}
              type='range'
              value={Number(val)}
              onChange={(v: any) => update(v.target.value)}
            />
          </label>

          <Right>
            <HoverLink data-tip={question.highDescription} data-for='assessTT'>
              <h5>{question.highLabel}</h5>
              <ShortDesc>Got This!</ShortDesc>
            </HoverLink>
          </Right>
        </SliderWrapper>
      </QuestionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  max-width: 800px;
  
  display: flex;  
  flex-direction: column;
  padding: 0;
  margin: 0 auto;
  margin-bottom: 20px;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.LIGHTS.GREY2};
  border-radius: 10px;
  box-shadow: ${SHADOWS.SECONDARY};  
`;

const HoverLink = styled.a`
  &:hover {
    text-decoration-line: underline;    
  }
  h5 {
    @media ${QUERIES.MOBILE} {  
      display: none;
    }
  }
`;


const QuestionWrapper = styled.div`
  flex: 4;
  padding: 20px;
  text-align: center;  
  h3{
    padding: 10px 0;
  }
  p{
    /* width: 60%; */
    margin: auto;
    padding-top: 10px; 
    font-size: 0.8rem;
  }
  img{
    margin: auto;
    padding-top: 10px;
  }
`;

const SliderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 50% 1fr;
  gap: 20px;
  align-content: start;
  padding-top: 40px;
  padding-bottom:20px;
  align-items: start;
`;

const Label = styled.div`
  h5 {
    padding-bottom: 10px;
    font-size: .8rem;
  }
`;

const Left = styled(Label)`
  text-align: left;
`;
const Right = styled(Label)`
  text-align: right;
`;

const ShortDesc = styled.div`  
  position: relative;
  top: 12px;  
  font-size: .8rem;
  display: none;
  @media ${QUERIES.MOBILE} {
    display: block;
  }
`;

const Range = styled.input`
  --range-size: 29px;
  --gradient-color-1: ${COLORS.PRIMARY.BLUE};
  --gradient-color-2: ${COLORS.DARKS.DARK_GREY};
  -webkit-appearance: none;
  transform: translateY(10px);
  height: calc(var(--range-size) * 1);
  border-radius: calc(var(--range-size) / 2);
  background: rgba(189, 188, 188, 0.6);
  background-image: linear-gradient(
    var(--gradient-color-1),
    var(--gradient-color-1)
  );
  background-size: 50% 100%;
  background-repeat: no-repeat;
  width: 100%;
  &::-webkit-slider-thumb {
    --size: calc(var(--range-size) * 1.4);
    -webkit-appearance: none;
    height: var(--size);
    width: var(--size);
    border-radius: 50%;
    cursor: ew-resize;
    background: white;
    border: 4px solid ${COLORS.PRIMARY.BLUE};
  };  
  
`;
