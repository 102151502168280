import {logAnayltics} from '@services/FirebaseHelper';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const EVENTS = {
  SURVEY_STARTED: 'survey_started',
  SURVEY_COMPLETED: 'survey_completed',
  DOWNLOAD_PDF: 'download_pdf',
  MODAL_OPEN: 'modal_open',
  MODAL_CLOSE: 'modal_close',
  CREATE_NEW: 'create_new',
  LOAD_EXISTING: 'load_existing',
  REQUEST_DEMO: 'request_demo',
  LOGIN_STARTED: 'login_started',
  LOGIN_COMPLETED: 'login_completed',
  COOKIE_CONSENT: 'cookie_consent',
  ERROR_404: 'not_found',
  ERROR_403: 'access_forbidden',
  ERROR_500: 'app_error',
};


const Analytics = () => {
  const location = useLocation();
  useEffect(() => {
    setTimeout(() => {
      const payload = {
        page_title: document.title,
        page_location: document.location.href,
        page_path: location.pathname,
      };
      logAnayltics('page_view', payload);
    }, 500);
  }, [location]);
  return null;
};

export default Analytics;

