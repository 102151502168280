import {LOADING_STATES} from '@services/store/slices/mainSlice';
import Loading from '@components/Loading';
import React from 'react';
import styled from 'styled-components';
import {EmailChallenge} from './EmailChallenge';
import {useHook} from './useHook.LoadPlanById';


export const LoadPlanById = () => {
  const {submitEmail, isShowEmailChallenge, isLoading} = useHook();

  if (isShowEmailChallenge) {
    // handle loading after submitting
    if (isLoading === LOADING_STATES.STARTED) return <Loading />;
    return <EmailChallenge onSubmit={submitEmail} isError={isLoading === LOADING_STATES.FAILED}/>;
  }

  return <Loading/>;
};


const Wrapper = styled.div`
    height: 400px;
`;
