import {ASSET_RULES} from '@data/model/RulesProcessor';
import {ASSET_TYPE} from '../defaults';
import {AssessmentType, AssessmentResults, IAssessmentResult, AssessmentAnswers} from '../interfaces';

const assessment: AssessmentType = {

  CUSTOMER_DATA: {
    key: 'CUSTOMER_DATA',
    displayName: 'Customer Data',
    description: 'From basic customer entitlements to granular usage data. Your company\'s ability to easily access and centralize customer data is a critical building block for many growth strategies to follow.',
    lowLabel: 'The customer data we do have is all over the place',
    lowDescription: 'Collecting customer data is extremely manual and time consuming. We still don\'t have visibility into how customers are adopting/using our products or if they are healthy or not.',
    highLabel: 'We know everything about our customers',
    highDescription: 'Anyone in our company can easily access all relevant customer data from one, trusted interface.',
    order: 1,
    icon: 'data.svg',
    result: {
      assets: [
        {
          title: 'Why Visibility Is the Foundation for Customer Success',
          uri: 'https://www.gainsight.com/blog/why-visibility-is-the-foundation-for-customer-success/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._LOW,
        },
        {
          title: 'Building the Foundation for Customer Visibility with Usage Data and Health Scoring',
          uri: 'https://www.gainsight.com/blog/building-the-foundation-for-customer-visibility-with-usage-data-and-health-scoring/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._LOW,
        },
        {
          title: 'Make Accurate & Actionable Data a Priority',
          uri: 'https://gainsight.hubs.vidyard.com/watch/dPNMUVBhndx9eZQReNsHp6?',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._MIDDLE,
        },
        {
          title: 'CS Essentials by Gainsight - Data Readiness',
          uri: 'https://open.spotify.com/episode/68gTBJZWTmRuqtNK14FvnU?si=8923c06d5006450b',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._MIDDLE,
        },
        {
          title: 'How To Score Customer Health',
          uri: 'https://www.gainsight.com/blog/customer-health-scores/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._HIGH,
        },
        {
          title: '3 Tips for Getting Started with Product Usage Data',
          uri: 'https://www.gainsight.com/blog/3-tips-for-getting-started-with-product-usage-data/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._HIGH,
        },
      ],
      peerHigh: 40,
      peerLow: 10,
      title: 'Customer Data Readiness',
    },
  },
  PROCESS_WORFLOWS: {
    key: 'PROCESS_WORFLOWS',
    displayName: 'Process and Workflows',
    description: 'From relying on experienced employees doing the right thing to having fully documented, automated, and tracked workflows. Maturity in this dimension drives consistency in customer experience as well as the ability to scale your teams beyond current sales, support and success ratios.',
    lowLabel: 'Nothing is documented, we rely on experienced individuals',
    lowDescription: 'Because every CSM manages their accounts the way they want, I don\'t have a great pulse on how consistent the experience is. I just hear how busy CSMs are and I can\'t increase ratios further.',
    highLabel: 'Workflows are documented, automated, and tracked',
    highDescription: 'We\'ve made our CSMs\' lives easier by triggering and automating several of their workflows. We are now blending tech touch with human touches to help scale even more.',
    order: 2,
    icon: 'workflow.svg',
    result: {
      assets: [
        {
          title: 'Why Is NRR Your Most Important Growth Metric?',
          uri: 'https://www.gainsight.com/blog/nrr-north-star-growth-metric/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._LOW,
        },
        {
          title: 'Elevating Customer Success: Becoming a Die Hard Metrics Driven Organization',
          uri: 'https://gainsight.hubs.vidyard.com/watch/1rB89Y55KZV4MS7nBL2MEi?',
          type: ASSET_TYPE.VIDEO,
          rule: ASSET_RULES._LOW,
        },
        {
          title: 'NRR: Your Most Important Growth Metric',
          uri: 'https://info.gainsight.com/rs/231-EAT-840/images/2021-GS-NRReBook.pdf',
          type: ASSET_TYPE.PDF,
          rule: ASSET_RULES._MIDDLE,
        },
        {
          title: 'The Customer Success Playbook to Key Performance Indicators',
          uri: 'https://info.gainsight.com/rs/231-EAT-840/images/executiveplaybook8.pdf',
          type: ASSET_TYPE.PDF,
          rule: ASSET_RULES._MIDDLE,
        },
        {
          title: 'Optimizing the Customer Journey with Digital CS Metrics',
          uri: 'https://www.gainsight.com/blog/tracking-and-optimizing-digital-led-customer-success/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._HIGH,
        },
        {
          title: 'Customer Success & Marketing Success',
          uri: 'https://www.gainsight.com/blog/customer-success-marketing-success/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._HIGH,
        },
      ],
      peerHigh: 50,
      peerLow: 20,
      title: 'Process and Workflows',
    },
  },
  ORG_ALIGNMENT: {
    key: 'ORG_ALIGNMENT',
    displayName: 'Org Alignment',
    description: 'From rallying the company around customer growth to establishing clear cross-functional goals, rules of engagement and incentives, a lack of organizational alignment often stands in the way of placing the bigger bets required to mature quickly.',
    lowLabel: 'Still trying to change culture',
    lowDescription: 'I\'m still doing the CS Roadshow and educating teams on Customer Success the "department" vs. Customer Success the actual outcome.',
    highLabel: 'Everyone is bought in',
    highDescription: 'My company is fully bought in and committed on CS. They get it. I\'ve purchased and used CS Platforms in prior CS leadership roles.',
    order: 3,
    icon: 'org.svg',
    result: {
      assets: [
        {
          title: 'Ultimate Guide to Customer Success Org Structures',
          uri: 'https://www.gainsight.com/blog/ultimate-guide-customer-success-organization/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._LOW,
        },

        {
          title: 'Smarter Not Harder: Launching Customer Success from Scratch',
          uri: 'https://info.gainsight.com/rs/231-EAT-840/images/smarter-not-harder-launching-customer-success-from-scratch.pdf',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._LOW,
        },

        {// TODO this still points to gated content
          title: 'The Role Of Change Management In Your Customer Success Platform Selection',
          uri: 'https://info.gainsight.com/role-of-change-management-in-your-customer-success-datasheet.html',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._MIDDLE,
        },
        {
          title: 'Functional Roles of Customer Success',
          uri: 'https://www.gainsight.com/blog/customer-success-roles/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._MIDDLE,
        },
        {
          title: 'Investing In Customer Success: Why Your Board Cares About Your CS Strategy',
          uri: 'https://gainsight.hubs.vidyard.com/watch/C8LX9yFNgn95JDtot7oqvS?',
          type: ASSET_TYPE.VIDEO,
          rule: ASSET_RULES._HIGH,
        },
        {
          title: 'Customer Success as a Driving Function of Enterprise-Wide Success and Transformation',
          uri: 'https://info.gainsight.com/rs/231-EAT-840/images/2021-08-EB-Gainsight-Customer%20Success%20as%20a%20Driving%20Function-v2.pdf',
          type: ASSET_TYPE.PDF,
          rule: ASSET_RULES._HIGH,
        },
      ],
      peerHigh: 60,
      peerLow: 30,
      title: 'Organizational Alignment',
    },
  },
  MEASURES_METRICS: {
    key: 'MEASURES_METRICS',
    displayName: 'Measures and Metrics',
    description: 'Often aligned with the customer data dimension, this is your company\'s ability to measure and align on customer growth metrics. Best in class companies track metrics such as  Gross Renewal Rate (GRR), Net Retention Rate (NRR), NPS, Product Adoption and Cost to Serve in addition to several critical leading indicator metrics.',
    lowLabel: 'Without trust in the data, we can\'t align on metrics',
    lowDescription: 'Each team reports differently so there is very little trust in the data. Even retention rates are debated. We have some customer satisfaction measures in place.',
    highLabel: 'Our company is focused on metrics that matter',
    highDescription: 'Gross Renewal Rate (GRR), Net Retention Rate (NRR), NPS, CS Cost % of revenue makeup our key outcome metrics. We also track critical indicator metrics.',
    order: 4,
    icon: 'metrics.svg',
    result: {
      assets: [
        {
          title: 'Establishing Scaleable, Effective Customer Success Playbooks',
          uri: 'https://info.gainsight.com/rs/231-EAT-840/images/Esablishing-Scalable-Effective-Customer-Playbooks.pdf',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._LOW,
        },
        {
          title: 'The Customer Visibility Playbook',
          uri: 'https://info.gainsight.com/rs/231-EAT-840/images/ExecutivePlaybookBookCustomerVisibility.pdf',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._LOW,
        },
        {
          title: 'Driving Alignment and Collaboration with an End-to-End Value Framework',
          uri: 'https://gainsight.hubs.vidyard.com/watch/gPKt8BCyj9varhy43u3YA6',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._MIDDLE,
        },
        {
          title: 'The Customer Success Playbook to Customer Experience',
          uri: 'https://info.gainsight.com/rs/231-EAT-840/images/executiveplaybook6.pdf',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._MIDDLE,
        },
        {
          title: 'Evolve Your CS Strategy and Power Growth Across the Customer Lifecycle',
          uri: 'https://gainsight.hubs.vidyard.com/watch/vPoScd7P574KF8SCF2HUuy',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._HIGH,
        },
        {
          title: 'TSW 2021: Leveraging Digital Success to Power Scale and Efficiency',
          uri: 'https://gainsight.hubs.vidyard.com/watch/5w7hTLrfmdftLB2LMXCW7R',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._HIGH,
        },
      ],
      peerHigh: 60,
      peerLow: 20,
      title: 'Measures and Metrics',
    },
  },
  SKILLS_RESOURCING: {
    key: 'SKILLS_RESOURCING',
    displayName: 'Customer Operations and Resourcing',
    description: 'From considering a dedicated CS Ops role to having aligned or integrated teams across sales, success and product operations. Maturity in this dimension often allows teams to overcome less than ideal customer data situations and generally progress along their strategic roadmap more quickly.',
    lowLabel: 'I\'m beginning to consider a Customer Ops person',
    lowDescription: 'Either I get my hands dirty or ask one of my CSMs to play this role from time to time.',
    highLabel: 'We have dedicated operations resources',
    highDescription: 'I have a rockstar team either reporting directly to me or dedicated to me. They handle all metrics & reporting as well as my ad hoc requests.',
    order: 5,
    icon: 'skills.svg',
    result: {
      assets: [
        {
          title: 'The Evolution of CS Operations',
          uri: 'https://www.gainsight.com/blog/cs-ops-evolution/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._LOW,
        },

        {
          title: 'The Who, What and When For CS Operational Success',
          uri: 'https://www.gainsight.com/blog/the-who-what-and-when-for-cs-operational-success/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._LOW,
        },

        {
          title: 'Success in CS Operations: An Inside Look at How Okta Drives Results',
          uri: 'https://www.gainsight.com/blog/success-in-cs-operations-an-inside-look-at-how-okta-drives-results/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._MIDDLE,
        },
        {
          title: '5 Qualities You Need to Be a Great CS Ops Leader',
          uri: 'https://www.gainsight.com/blog/5-qualities-of-great-cs-ops-leader/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._MIDDLE,
        },

        {
          title: 'How CS Ops Drives Market Valuation',
          uri: 'https://www.gainsight.com/blog/cs-operations-drive-market-valuation/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._HIGH,
        },
        {
          title: '7 Takeaways from CCO Organizational Structure Survey',
          uri: 'https://www.gainsight.com/blog/7-takeaways-from-cco-organizational-structure-survey/',
          type: ASSET_TYPE.BLOG,
          rule: ASSET_RULES._HIGH,
        },
      ],
      peerHigh: 30,
      peerLow: 0,
      title: 'CS Operations Skills & Resourcing',
    },
  },
};


export default assessment;
