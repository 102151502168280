import {COPY} from '@constants/copy';
import {COLORS, SHADOWS} from '@constants/style';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';


export const EmailChallenge = ({onSubmit, isError=true}: {isError: boolean, onSubmit: (email: string) => void}) => {
  const [email, setEmail] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    setIsDisabled(email.length > 0 ? false : true);
  }, [email]);

  return (
    <InnerWrapper>
      <h2>{COPY.EMAIL_CHALLENGE_TITLE}</h2>
      <p>{COPY.EMAIL_CHALLENGE_BODY}</p>
      {isError && <Error>{COPY.EMAIL_CHALLENGE_ERROR}</Error>}
      <Row>
        <EmailField
          type='text'
          value={email || ''}
          placeholder='name@domain.com'
          onKeyDownCapture={(e) => {
            if (e.key === 'Enter') {
              onSubmit(email.trim());
            }
          }}
          onChange={(v) => setEmail(v.target.value)}
        />
        <Button onClick={() => onSubmit(email.trim())} disabled={isDisabled}>
          <FontAwesomeIcon icon={faArrowRight} size="2x"/>
        </Button>
      </Row>
    </InnerWrapper>
  );
};


const InnerWrapper = styled.div`
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  padding: 40px;
  border-radius: 20px;
  border: 1px solid ${COLORS.LIGHTS.GREY2}; 
  box-shadow: ${SHADOWS.SECONDARY};
  color: ${COLORS.GREY600};  
  width: clamp(400px, 100vw, 600px);
`;

const Error = styled.p`
  color: red;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  /* flex-direction: column; */
`;

const EmailField = styled.input`
  height: 50px;
  border-radius: 10px;
  border: 1px solid ${COLORS.LIGHTS.GREY3};
  padding: 2px 10px;
  width: 100%;  
`;


const Button = styled.button`
  border: none;
  background: ${COLORS.PRIMARY.BLUE};
  width: 60px;
  height: 50px;
  border-radius: 10px;
  color: ${COLORS.WHITE};
  display: grid;
  place-content: center;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 1px 1px #ccc;
  &:active{
    top: 1px;
    box-shadow: none;
  }
`;
