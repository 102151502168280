import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useHook} from './useHook.Auth';

import {ROUTE_PATH} from '@constants/routes';

export const Auth = () => {
  const {working, destination} = useHook();
  const navigate = useNavigate();

  if (working) {
    return <h2>Logging in...</h2>;
  }

  if (destination) {
    navigate(`${ROUTE_PATH.SECURE_PLAN}/${destination}`);
    return null;
  }

  return <h2>Something went wrong</h2>;
};
