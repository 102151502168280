import React from 'react';

import Icons from '@components/Icons';
import {COPY} from '@constants/copy';
import {COLORS, QUERIES} from '@constants/style';
import {IPriorityDisplay, IPriorityResult, WithColorProps} from '@data/model/interfaces';
import {PriorityLabel} from '@views/Priorities/DraggableItem/DraggableItem';
import ResultsTile from '@views/Results/ResultsTile';
import {PropsWithChildren} from 'react';
import styled from 'styled-components';
import useModel from '@data/model/useModel';
import {makePriorityDisplay} from '@utils/misc';

interface PrioritiesResultTileProps {
  priorities: IPriorityResult[],
  liteHeader?: boolean
}

export const PrioritiesResultTile = ({priorities, liteHeader=false}: PrioritiesResultTileProps) => {
  const model = useModel();

  return (
    <ResultsTile
      style={{gridArea: 'BUSINESS_PRIORITIES'}}
      title={COPY.DASHBOARD_PRIORITIES_TILE_TITLE}
      subtitle={liteHeader ? undefined : COPY.DASHBOARD_PRIORITIES_TILE_SUBTITLE}
      icon={liteHeader ? undefined : <Icons.CheckedCircle/>}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
        {priorities.map((priority, i) => (
          <PriorityRow key={i} priority={makePriorityDisplay(model.data.version, priority)}/>
        ) )}
      </div>
    </ResultsTile>
  );
};


export const ColoredRow = ({color='black', children}: PropsWithChildren<WithColorProps>) => (
  <RowWrapper>
    <Left color={color}/>
    <Right>
      {children}
    </Right>
  </RowWrapper>
);


const PriorityRow = ({priority}: {priority: IPriorityResult|IPriorityDisplay}) => {
  const p = priority as IPriorityResult;

  return (
    <ColoredRow color={priority.color}>
      {/* <pre>{JSON.stringify(priority, null, 2)}</pre> */}
      <InnerWrapper>
        <PriorityLabel
          color={priority.color}
          label={priority.label}
          prefix={priority.prefix}
          lineBreak={!!priority.prefix}
        />
        <div style={{display: 'flex', width: '100%'}}>
          <Cell>
            <span>Your<br/>Score:</span>
            <YourScore>{p.yourScore}</YourScore>
          </Cell>
          <Cell>
            <span>Your<br/>Peers:</span>
            <PeerScore>{p.peerScore}</PeerScore>
          </Cell>
        </div>
      </InnerWrapper>
    </ColoredRow>
  );
};


const InnerWrapper = styled.div`
  flex: 1;
  display: grid;
  flex-direction: row;
  grid-template-columns: 1fr 1fr;
`;

const RowWrapper = styled.div` 
  background-color: ${COLORS.LIGHTS.GREY};
  border-radius: 8px;
  display: flex; 
  position: relative;
  align-items: center;
  min-height: 120px;  
`;

const Left = styled.div<HasColor>`
  position: absolute;
  height: 100%;
  width: 25px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: ${({color}) => color};  
  flex: 1;  
`;

const Right = styled.div`  
  padding: 10px 10px 10px 40px;
  flex: 1;
`;

const Cell = styled.div`  
  display: flex;  
  flex-direction: row;
  align-items: center;
  justify-content: center;    
  flex: 1;
  font-size: 0.8rem;
  @media ${QUERIES.LAPTOP} {
    flex-direction: column-reverse;
    justify-content: center;    
    text-align: center;
    gap: 6px; 
  }
  /* border: 1px solid red; */
`;

const YourScore = styled.span`
  margin-left: 6px;
  background-color: ${COLORS.PRIMARY.BLUE};
  width: 30px;
  height: 40px;
  display: grid;
  place-content: center;
  color: ${COLORS.WHITE};
  border-radius: 2px;
  font-weight: 800;    
`;

const PeerScore = styled(YourScore)`
  background-color: ${COLORS.PRIMARY.NAVY};
`;

interface HasColor{
  color: string;
}

