import styled from 'styled-components';

import {COLORS} from '@constants/style';

export const BodyWrapper = styled.div`
  height: 100%;
  width: 100%;
  margin: 0px auto;
  padding: 0 40px;
  max-width: 1400px;
`;

export const P1 = styled.p`
  font-size: 18px;
  font-weight: 400;
`;

export const P2 = styled.p`
  font-size: 16px;
  font-weight: 400;
`;

export const Caption = styled.caption`
  font-size: 14px;
  font-weight: 700;
`;

export const PageHeader = styled.div`
  padding: 10px 0 20px 0;
`;

export const Wrapper = styled.div`
  _border: 1px solid red;
`;


export const VisuallyHidden = styled.span`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

export const NoBreak = styled.span`
  white-space: nowrap;
`;


export const BackLink = styled.span`
 color: ${COLORS.PRIMARY.BLUE};
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: 700;
    &:hover{
      cursor: pointer;
    }
    &::before{
      font: var(--fa-font-solid);
      content: "\f060";
      padding-right: 6px;      
    }
`;
