import {SEQUENCE} from '../defaults';
import {ICompletedAssessment} from '../interfaces';
import priorities from './priorities';

const version = 2;

export const getOutcomes = ($: any) => {
  const outcomes = {
    INCREASE_CUSTOMER_FACING_TEAMS_IMPACT: {
      outcome: 'Increase Customer Facing Teams Impact',
      capabilities: [
        'Single system of record to understand the customer; Predictability in your business',
        'Increase productivity by enabling teams to spend more time on high-value activities',
        'Visibility on where customer facing teams are spending their time',
        'Customized views and dashboards to serve the needs of specific roles and functions.',
        'Automated and predictive alerts and health scoring identify areas of risk and opportunity within an account.',
      ],
      sequence: SEQUENCE.FOUNDATION,
    },
    INCREASE_LIFECYCLE_AUTOMATION: {
      outcome: 'Increase Lifecycle Automation',
      capabilities: [
        'Engage at the right time with the right message',
        'Consistency and standardization of serving customers; Deliver in-app communications & support to users',
        'Guide customer and user journeys with email and in-product engagements, and trigger interventions when customer or user behavior changes.',
      ],
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.SCALE_EFFICIENCY).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    PRIORITIZE_RND_EFFECTIVELY: {
      outcome: 'Prioritize R&D effectively',
      capabilities: [
        'Prioritized roadmap based on customer data',
      ],
      sequence: SEQUENCE.ADVANCED,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.SCALE_EFFICIENCY).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    IMPROVE_USER_ENGAGEMENT_EXPERIENCE: {
      outcome: 'Improve User Engagement and Experience',
      capabilities: [
        'Able to provide rich, contextualized experiences based on historical or point-in-time customer/user data',
        'Drive awareness and use of new and existing features with personalized self-service enablement.',
        'Better user behavior insights to drive positive customer outcomes',
        'Product and design improvements based on user trends and needs that make products stickier',
      ],
      sequence: SEQUENCE.INTERMEDIATE,

      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPERIENCE_ADOPTION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(33) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },

    },
    IMPROVE_ADOPTION_BREADTH_DEPTH: {
      outcome: 'Improve Adoption Breadth and Depth',
      capabilities: [
        'Product and design improvements based on user trends and needs that make products stickier',
        'More impactful product experience that accelerates customer time-to-value',
        'Gauge the degree of active/healthy usage at the feature level with a set of Adoption Depth and Breadth Health Scores.',
        'Analyze and normalize patterns in usage data to define and track optimal in-product behavior.',
      ],
      sequence: SEQUENCE.INTERMEDIATE,

      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPERIENCE_ADOPTION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    OPTIMIZE_CUSTOMER_JOURNEY: {
      outcome: 'Optimize Customer Journey',
      capabilities: [
        'More impactful product experience that accelerates customer time-to-value',
        'Key “Moments of Truth” with desired customer experience, specific CS activities and anticipated results, are defined and tracked.',
        'Community at the center of a digital customer experience improving time-to-value',
        'Easier to acquire and expand more customers',
      ],
      sequence: SEQUENCE.FOUNDATION,
    },
    INCREASE_ADVOCATES: {
      outcome: 'Increase Advocates',
      capabilities: [
        'Advocacy programs and industry reviews as key growth drivers',
        'Gather feedback - NPS, CSAT, CES - from users and customers across channels and within your product, and systematically respond to users with predefined actions based on sentiment.',
      ],
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPERIENCE_ADOPTION).inTop(2) &&
          $(d).isAssessmentAll().greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    IMPROVE_HEALTH_SCORE_PREDICTIVENESS: {
      outcome: 'Improve Health Score Predictiveness',
      capabilities: [
        'Use manual and automatically calculated inputs to create an actionable health score.',
        'Human and digital playbooks driven from automated signals',
        'Source of truth for voice of customer, customer health, at-risk accounts, and adoption for all teams',
      ],
      sequence: SEQUENCE.FOUNDATION,
    },
    REDUCE_RISK_CHURN: {
      outcome: 'Reduce Risk & Churn',
      capabilities: [
        'Types of risk are defined w/ clear criteria and specific ways to address.',
        'Incorporate additional automated risk signals and resolution programs.',
        'Product roadmap/initiatives driven by data-informed evidence and community feedback',
      ],
      sequence: SEQUENCE.FOUNDATION,
    },
    IMPROVE_CUSTOMER_ROI: {
      outcome: 'Improve Customer ROI',
      capabilities: [
        'Customers are achieving goals/outcomes',
        'Processes and tools to track and share progress towards goals, and document ROI.',
      ],
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.RETENTION).inTop(2) &&
          $(d).isAssessmentAll().greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    INCREASE_RENEWAL_FORECAST_ACCURACY: {
      outcome: 'Increase Renewal Forecast Accuracy',
      capabilities: [
        'Internal teams are more aligned, fight fewer fires, and deliver higher value',
        'Improved forecast predictability and trust from other stakeholders',
        'Human and digital playbooks driven from automated signals ',
      ],
      sequence: SEQUENCE.INTERMEDIATE,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.RETENTION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
    IMPROVE_EXPANSION_VELOCITY: {
      outcome: 'Improve Expansion Velocity',
      capabilities: [
        'Implement a consistent, cross-functional expansion approach',
        'Leverage expansion signals to improve customer buying experience',
      ],
      sequence: SEQUENCE.ADVANCED,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(66) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    INCREASE_EXPANSION_PIPELINE: {
      outcome: 'Increase Expansion Pipeline',
      capabilities: [
        'Efficiently market and drive awareness of sticky/new products/functionality to existing customers',
      ],
      sequence: SEQUENCE.ADVANCED,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(66) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    INCREASE_GROWTH_THROUGH_ADVOCACY: {
      outcome: 'Increase Growth Through Advocacy',
      capabilities: [
        'Leverage successfully delivered outcomes/product success stories/advocates to drive expansion',
        'Attribute monetary value to advocacy and reference programs.',
      ],
      sequence: SEQUENCE.ADVANCED,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(66) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    REDUCE_REVENUE_LEAKAGE: {
      outcome: 'Reduce Revenue Leakage',
      capabilities: [
        'Clear view of penetration and whitespace in your accounts',
        'Effectively monetize features and entitlements for upsell/cross sell',
      ],
      sequence: SEQUENCE.ADVANCED,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    INCREASE_SELFSERVICE_ENGAGEMENT: {
      outcome: 'Increase Self-Service Engagement',
      capabilities: [
        'Able to provide rich, contextualized experiences based on historical or point-in-time customer/user data',
        'Drive awareness and use of new and existing features with personalized self-service enablement.',
        'Better user behavior insights to drive positive customer outcomes',
        'Product and design improvements based on user trends and needs that make products stickier',
      ],
      sequence: SEQUENCE.ADVANCED,
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.SCALE_EFFICIENCY).inTop(2) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(66)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
  };
  return outcomes;
};
