export const ROUTE_BASE = '';
export const SURVEY_BASE = `${ROUTE_BASE}/survey`;
export const PLAN_BASE = `${ROUTE_BASE}/plan`;
export const SECURE_BASE = `${ROUTE_BASE}/secure`;

/**
 * Routes & URLS
 */

export const ROUTE_TOKEN = {
  HOME: '',
  DEMO: 'aboutyou',
  SOURCES: 'yourdata',
  ASSESS: 'assess',
  PRIORITIES: 'priorities',
  PRIVACY: 'privacy',
  TERMS: 'terms',

  DASHBOARD: 'dashboard',
  NOW: 'now',
  SOON: 'soon',
  LATER: 'later',
  PROFILE: 'profile',

  LOGIN: 'login',
  AUTH: 'auth',
  PLAN: 'plan',
};

export const ROUTE_PATH = {
  HOME: `${ROUTE_BASE}/`,
  PRIVACY: `${ROUTE_BASE}/${ROUTE_TOKEN.PRIVACY}`,
  TERMS: `${ROUTE_BASE}/${ROUTE_TOKEN.TERMS}`,

  DEMO: `${SURVEY_BASE}/${ROUTE_TOKEN.DEMO}`,
  SOURCES: `${SURVEY_BASE}/${ROUTE_TOKEN.SOURCES}`,
  ASSESS: `${SURVEY_BASE}/${ROUTE_TOKEN.ASSESS}`,
  PRIORITIES: `${SURVEY_BASE}/${ROUTE_TOKEN.PRIORITIES}`,

  DASHBOARD: `${PLAN_BASE}/${ROUTE_TOKEN.DASHBOARD}`,
  NOW: `${PLAN_BASE}/${ROUTE_TOKEN.NOW}`,
  SOON: `${PLAN_BASE}/${ROUTE_TOKEN.SOON}`,
  LATER: `${PLAN_BASE}/${ROUTE_TOKEN.LATER}`,
  PROFILE: `${PLAN_BASE}/${ROUTE_TOKEN.PROFILE}`,

  LOGIN: `${SECURE_BASE}/${ROUTE_TOKEN.LOGIN}`,
  AUTH: `${SECURE_BASE}/${ROUTE_TOKEN.AUTH}`,
  SECURE_PLAN: `${SECURE_BASE}/${ROUTE_TOKEN.PLAN}`,
};
