import * as React from 'react';
import styled from 'styled-components';

const Info = (props: any) => (
  <SVG
    width={20}
    height={20}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}>
    <path
      d='M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0Zm1 15H9V9h2v6Zm0-8H9V5h2v2Z'
      fill='#fff'
    />
  </SVG>
);

const SVG = styled.svg``;

export default Info;
