import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import {store} from './services/store/store';
import {Provider} from 'react-redux';
import GlobalStyle from './ui/misc/GlobalStyles';

ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
      <GlobalStyle />
    </React.StrictMode>,
    document.getElementById('root'));
