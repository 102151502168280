
import Icons from '@components/Icons';
import {COPY} from '@constants/copy';
import {ROUTE_PATH} from '@constants/routes';
import {COLORS} from '@constants/style';
import {SEQUENCE} from '@data/model/defaults';
import ResultsTile from '@views/Results/ResultsTile';
import React, {useContext} from 'react';
import styled from 'styled-components';
import PDFButton from '../PDFButton';
import {ResultsContext} from '../useHook.Results';


export const JourneySummaryTile = () => {
  const {updateNavigation, summaryModel} = useContext(ResultsContext);

  // return <pre>{JSON.stringify(summaryModel, null, 2)}</pre>;

  if (!summaryModel) return <></>;

  return (
    <ResultsTile
      style={{gridArea: 'JOURNEY_SUMMARY'}}
      title={COPY.DASHBOARD_JOURNEYSUMMARY_TILE_TITLE}
      subtitle={COPY.DASHBOARD_JOURNEYSUMMARY_TILE_SUBTITLE}
      icon={<Icons.BullsEye size="lg" color={COLORS.PRIMARY.PURPLE}/>}
      cta={<PDFButton />}
    >
      <BullsEyeHorizontalRule/>

      <BoxRow>
        <BorderBox
          label='Now'
          data={summaryModel.summary[SEQUENCE.FOUNDATION]}
          color={COLORS.PRIMARY.BLUE}
          onClick={() => updateNavigation(ROUTE_PATH.NOW)}
        />
        <BorderBox
          label='Soon'
          data={summaryModel.summary[SEQUENCE.INTERMEDIATE]}
          color={COLORS.PRIMARY.NAVY}
          onClick={() => updateNavigation(ROUTE_PATH.SOON)}
        />
        <BorderBox
          label='Later'
          data={summaryModel.summary[SEQUENCE.ADVANCED]}
          color={COLORS.PRIMARY.PINK}
          onClick={() => updateNavigation(ROUTE_PATH.LATER)}
        />
      </BoxRow>
    </ResultsTile>
  );
};


const BullsEyeHorizontalRule = () => {
  return (
    <BullsEyeRowWrapper>
      <Gradient />
      <BullsEyeRow>
        <BullsEyeRowCell>
          <Icons.BullsEye fill color={COLORS.PRIMARY.BLUE}/>
        </BullsEyeRowCell>
        <BullsEyeRowCell>
          <Icons.BullsEye fill color={COLORS.PRIMARY.NAVY}/>
        </BullsEyeRowCell>
        <BullsEyeRowCell>
          <Icons.BullsEye fill color={COLORS.PRIMARY.PINK}/>
        </BullsEyeRowCell>
      </BullsEyeRow>
    </BullsEyeRowWrapper>
  );
};


interface BorderBoxProps {
  label: string;
  color: string;
  data: string[];
  onClick: () => void;
}


const BorderBox = ({label, data, color, onClick}: BorderBoxProps) => {
  return (
    <BWrapper>
      <BTop color={color}>{label}</BTop>
      <BLower>
        <ul>
          {data.length === 0 && (<p>{COPY.RESULTS_EMPTY_PHASE_OUTCOMES}</p>)}
          {data.map((item, i) => (<li key={i}>{item}</li>))}
        </ul>
        <BLink onClick={onClick}>{COPY.DASHBOARD_JOURNEYSUMMARY_DETAILED_CTA}</BLink>
      </BLower>
    </BWrapper>
  );
};


const BullsEyeRowWrapper = styled.div`
  position: relative;  
  @media(max-width: 640px){
    display: none;
  }  
`;

const BullsEyeRow = styled.div`
  display: flex;      
  position: relative;
`;
const BullsEyeRowCell = styled.div`
  flex: 1;
`;

const BWrapper = styled.div`  
  border-radius: 8px;  
  flex: 1;  
  min-height: 100%;
`;

const BTop = styled.div<{color: string}>` 
  border-top-left-radius: 8px;  
  border-top-right-radius: 8px;  
  background-color: ${({color}) => color};
  padding: 10px 20px;
  color: ${COLORS.WHITE};
  font-size: 1.3rem;
  font-weight: 800;
`;

const BLower = styled.div`
  border-bottom-left-radius: 8px;  
  border-bottom-right-radius: 8px;  
  border: 1px solid ${COLORS.LIGHTS.GREY2};
  background-color: ${COLORS.WHITE};
  padding: 10px 16px;  
  min-height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ul{
    padding: 0 10px;
    list-style: square;
    font-size: 1rem;
  }
  li{
    padding: 6px 0;
    font-size: clamp(.8rem, 2vw, 1rem); 
  }
`;

const BLink = styled.div<{onClick: any}>`
  color: ${COLORS.PRIMARY.BLUE};
  font-size: 0.8rem;
  font-weight: 600;
  padding-top: 10px;
  &::after {
    font: var(--fa-font-solid);
    padding-left: 6px;
    font-size: 0.8rem;
    content: "\f061";
  }
  &:hover{
    text-decoration: underline;
    cursor: pointer;    
  }
`;

const BoxRow = styled.div`
  display: flex;  
  flex-direction: row;
  margin-top: 14px;
  gap: 10px;
  @media(max-width: 640px){
    flex-direction: column;    
    gap: 20px;
  }
`;

const CTADiv = styled.div`
  @media(max-width: 640px){
    display: none;
  }
`;

const Gradient = styled.div`  
  height: 7px;
  background: ${COLORS.WHITE};
  background-image: linear-gradient( 90deg, ${COLORS.LIGHTS.GREY2}, ${COLORS.WHITE});
  position: relative;
  transform: translateY(16px);
  margin: 0 10px
`;
