import {createSlice} from '@reduxjs/toolkit';

export const CURRENT_CLIENT_VERSION = '2.0.0';
export const CURRENT_SERVER_VERSION = '2.0.0';
export const PREVIOUS_MODEL_VERSION = '1.0.0';

// change default model used
export const CURRENT_MODEL_VERSION = '2.0.1';
// export const CURRENT_MODEL_VERSION = '1.0.0';

interface IUUIDState {
  uuid: string | null;
  clientVersion: string,
  serverVersion: string,
  modelVersion: string | null,
}

export const uuidSlice = createSlice({
  name: 'uuid',
  initialState: {
    uuid: null,
    clientVersion: CURRENT_CLIENT_VERSION,
    serverVersion: CURRENT_SERVER_VERSION,
    modelVersion: CURRENT_MODEL_VERSION,
  } as IUUIDState,
  reducers: {
    init: (state, {payload}) => {
      state.uuid = payload;
    },
    clear: (state) => {
      state.uuid = null;
    },
    update: (state, {payload}) => {
      state.uuid = payload.uuid;
      state.clientVersion = payload.clientVersion;
      state.serverVersion = payload.serverVersion;
      state.modelVersion = payload.modelVersion;
    },
    updateModelVersion: (state, {payload}) => {
      state.modelVersion = payload;
    },
  },
});

export const {init, clear, update, updateModelVersion} = uuidSlice.actions;

export default uuidSlice.reducer;
