import {useAppDispatch} from '@services/store/hooks';
import {EVENTS} from '../Analytics';
import {logAnayltics} from '@services/FirebaseHelper';
import {clearDataAsync} from '@services/store/thunks';


function useHook() {
  const dispatch = useAppDispatch();

  function onClick() {
    logAnayltics(EVENTS.ERROR_500);
    dispatch(clearDataAsync());
    window.location.href = '/';
  }

  return {onClick};
}

export default useHook;

