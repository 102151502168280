import React, {useEffect, useState} from 'react';
import styled, {keyframes} from 'styled-components';

import {COLORS} from '@constants/style';
import {COPY} from '@constants/copy';
import {randomInteger} from '@utils/math';

export const Processing = () => {
  const [company, setCompany] = useState(false);
  const [assessment, setAssessment] = useState(false);
  const [priorities, setPriorities] = useState(false);

  useEffect(() => {
    const t1 = randomInteger(500, 1500);
    const t2 = randomInteger(1500, 3000);
    const t3 = randomInteger(3000, 5000);

    setTimeout(() => {
      setCompany(true);
    }, t1);
    setTimeout(() => {
      setAssessment(true);
    }, t2);
    setTimeout(() => {
      setPriorities(true);
    }, t3);
  }, []);

  return (
    <Wrapper>
      <Center>
        <Spinner>
          <Bounce1 />
          <Bounce2 />
        </Spinner>
        <ul>
          {company && <li>{COPY.PROCESSING_STEP1}</li>}
          {assessment && <li>{COPY.PROCESSING_STEP2}</li>}
          {priorities && <li>{COPY.PROCESSING_STEP3}</li>}
        </ul>
      </Center>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: calc(100vh - 220px);
  min-height: 600px;
  padding-top: 20vh;
  place-content: center;
`;

const BounceAnimation = keyframes`
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
`;

const Center = styled.div`
  font-size: 0.8rem;
  transform: translateY(-20vh);
  ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
    height: 100px;
  }

  li {
    &::before {
      content: "✓";
      color: ${COLORS.PRIMARY.GREEN};
      padding-right: 10px;
    }
  }
`;

const Spinner = styled.div`
  --size: 80px;
  --color: ${COLORS.PRIMARY.BLUE};
  width: var(--size);
  height: var(--size);
  position: relative;
  margin: 0px auto 40px;
`;

const Bounce = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--color);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: ${BounceAnimation} 2s infinite ease-in-out;
  animation: ${BounceAnimation} 2s infinite ease-in-out;
`;

const Bounce1 = styled(Bounce)``;

const Bounce2 = styled(Bounce)`
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
`;
