import Button from '@components/Button';
import {COLORS, QUERIES} from '@constants/style';
import React from 'react';
import styled from 'styled-components';
import useHook from './useHook.CookieConsent';

export const CookieConsent = () => {
  const {setAccept, accepted} = useHook();
  return (
    <Inner accepted={accepted}>
      <Left>
        <a href="/privacy">This site uses cookies to deliver its services and to analyze traffic.</a>.
      </Left>
      <Right>
        <Button small color={COLORS.PRIMARY.PURPLE} onClick={setAccept}>Ok, got it</Button>
      </Right>
    </Inner>
  );
};

const Inner = styled.div<{accepted: boolean}>`
  display: ${({accepted}) => accepted ? 'none' : 'flex'};
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1000000000;
  height: 80px;
  width: 100%;
  padding: 20px;
  background: #fff;
  box-shadow: 0 1px 5px 0 #80868b;
  @media ${QUERIES.MOBILE}{
    height: 200px;
    flex-direction: column;
  }
`;

const Left = styled.div`
  display: flex;    
  flex: 1;    
  align-items: center;
  color: ${COLORS.DARKS.CHARCOAL};
  font-size: 10px;
  a, a:visited {
      font-size: .9rem;
      color: ${COLORS.DARKS.CHARCOAL};
      font-weight: 400;        
      text-decoration: none;
    }
`;
const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;  
`;
