import {replaceTokens} from '@utils/string';

export const COPY = {

  HEADER_TITLE: 'Value Plan',

  HOME_TITLE1: 'Ready to plan your',
  HOME_TITLE2: 'Durable Growth Journey?',
  HOME_BODY: `Assess your company across a few key dimensions then prioritize a set of growth priorities. We'll generate your custom Gainsight Plan as well as provide recommendations with tips, best practices, and success measures to accelerate your journey.`,
  HOME_CTA: 'Get Started',
  HOME_CTA_LINK: 'See Sample Plan',
  HOME_CARD1_TITLE: 'Take Your Assessment.',

  HOME_CARD1_BODY: 'A friendly and quick assessment to rate your customer success operation across 5 key dimensions.',
  HOME_CARD2_TITLE: 'Define Priorities.',
  HOME_CARD2_BODY: 'Based on your assessment, we will identify a set of challenges. Tell us which ones are most important for your company today.',
  HOME_CARD3_TITLE: 'Get your Gainsight Value Plan.',
  HOME_CARD3_BODY: 'All results are in! We will deliver recommendations and a customized Gainsight Value Plan. Take it to go and schedule a demo to see your plan in action.',

  DEMO_HEADER_TITLE: 'Welcome!',
  DEMO_HEADER_SUBTITLE: 'First, tell us a bit about yourself.',
  DEMO_HEADER_COPY: 'We will use this information to provide peer benchmarks and customize your final Gainsight Value Plan.',
  DEMO_CONSENT: 'Gainsight may email you about products and services we think might interest you. Check this box if you consent.',
  DEMO_SUBMIT: 'Continue',
  DEMO_DEFAULT_SELECT_VALUE: 'Select One',

  YOURDATA_HEADER_TITLE: 'We Love Data!',
  YOURDATA_HEADER_SUBTITLE: 'And we\'d love to learn about yours.',
  YOURDATA_HEADER_COPY: 'Help us understand which systems you use, and which are most important for delivering customer outcomes.',
  YOURDATA_SUBMIT: 'Continue to Assessment',

  ASSESS_TITLE: 'Assess',
  ASSESS_SUBTITLE: 'Rate your customer success operations across 5 key dimensions.',
  ASSESS_EMPTYLIST: 'Drag &amp; Drop Challenges <br/> in Order of Importance',
  ASSESS_BACK: 'Previous',
  ASSESS_SUBMIT: 'Continue',

  ASSESS_PAGE_TITLE: 'Let\'s Assess Your Ops!',
  ASSESS_PAGE_SUBTITLE: 'What\'s going well? What can you improve?',
  ASSESS_PAGE_COPY: 'Help us understand your company\'s level of operational maturity across five key dimensions.',

  PRIORITIES_PAGE_TITLE: 'Rank Your Priorities.',
  // PRIORITIES_PAGE_SUBTITLE: 'What\'s top of mind as you scale your customer success org?',
  PRIORITIES_PAGE_SUBTITLE: 'Where do you need to focus to build an unstoppable business?',
  // PRIORITIES_PAGE_COPY: 'Help us understand which business challenges are the highest priority for you today. Rank the following business challenges in priority order.',
  PRIORITIES_PAGE_COPY: 'Help us understand the relative importance of the following customer growth priorities.',

  PRIORITIES_EMPTYLIST: 'Drag &amp; Drop Challenges <br/> in Order of Importance',

  PRIORITIES_RANKHEADING: 'Your Priorities',
  PRIORITIES_DROPLABEL: 'Drop Here',
  PRIORITIES_BACK: 'Go Back',
  PRIORITIES_SUBMIT: 'Complete Your Assessment!',

  PROCESSING_STEP1: 'Analyzing Company Info...',
  PROCESSING_STEP2: 'Analyzing Assessment...',
  PROCESSING_STEP3: 'Analyzing Your Priorities...',

  DASHBOARD_TITLE: 'Your Dashboard',


  DASHBOARD_NAV_DASHBOARD: 'Dashboard',
  DASHBOARD_NAV_NOW: 'Now',
  DASHBOARD_NAV_SOON: 'Soon',
  DASHBOARD_NAV_LATER: 'Later',
  DASHBOARD_NAV_DEMO_CTA: 'Schedule Your LIVE Gainsight Demo!',

  DASHBOARD_HERO_TITLE: 'Congratulations, {firstName}!',
  DASHBOARD_HERO_SUBTITLE: 'You\'ve completed your self assessment!',
  DASHBOARD_HERO_COPY: 'Based on your maturity assessment, business priorities and Gainsight best practices, we\'ve customized your Gainsight plan with comparisons to industry peers.',
  DASHBOARD_HERO_IMG: '/images/hero_3.png',
  DASHBOARD_PLAN_TITLE: 'Your CS Journey',
  DASHBOARD_PLAN_SUBTITLE: 'Based on Maturity & Priorities',

  DASHBOARD_ASSESSMENT_TILE_TITLE: 'Self Assessment',
  DASHBOARD_ASSESSMENT_TILE_SUBTITLE: 'Your Score vs. Your Peers',
  DASHBOARD_ASSESSMENT_TILE_PEER_TOOLTIP: 'Peer range from {min} to {max}',

  DASHBOARD_PRIORITIES_TILE_TITLE: 'Business Priorities',
  DASHBOARD_PRIORITIES_TILE_SUBTITLE: 'Your Priorities vs. Your Peers',

  DASHBOARD_JOURNEYSUMMARY_TILE_TITLE: 'Your Gainsight Plan Summary',
  DASHBOARD_JOURNEYSUMMARY_TILE_SUBTITLE: 'Based on Maturity, Priorities & Best Practices',
  DASHBOARD_JOURNEYSUMMARY_DOWNLOAD_CTA: 'Results',
  DASHBOARD_JOURNEYSUMMARY_DETAILED_CTA: 'Detailed Plan',

  DASHBOARD_METRICS_TILE_TITLE: 'Outcome Metrics',
  DASHBOARD_METRICS_TILE_SUBTITLE: 'Based on Maturity & Priorities',

  PLAN_TITLE: 'Detailed Plan',
  PLAN_YOUR_PLAN_TILE_TITLE: 'Your Detailed Plan',
  PLAN_YOUR_PLAN_TILE_SUBTITLE: 'Based on Maturity & Priorities',
  PLAN_NOW_HERO_TITLE: 'Where to Focus NOW',
  PLAN_NOW_HERO_SUBTITLE: 'A personalized Gainsight Plan for your organization.',
  PLAN_SOON_HERO_TITLE: 'Where to Focus SOON',
  PLAN_SOON_HERO_SUBTITLE: 'A personalized Gainsight Plan for your organization.',
  PLAN_LATER_HERO_TITLE: 'Where to Focus LATER',
  PLAN_LATER_HERO_SUBTITLE: 'A personalized Gainsight Plan for your organization.',
  PLAN_CTA_CALCULATOR: 'Check Out Our Customer Success Metrics Calculator!',
  PLAN_CTA_DEMO: 'See your journey come to life!',

  PROFILE_TITLE: 'Profile',
  PROFILE_DASHBOARD_CTA: 'View Dashboard',
  PROFILE_DATASOURCE_TILE_TITLE: 'Your Systems',
  PROFILE_GOBACK_TITLE: 'Need to go back?',
  PROFILE_GOBACK_CTA: 'Return to this section',
  PROFILE_GOBACK_SUBTITLE: 'Click below to edit or update your assessment answers.',
  PROFILE_GOBACK_DATA_TITLE: 'Data',
  PROFILE_GOBACK_DATA_IMG: '/images/goback_data.svg',
  PROFILE_GOBACK_ASSESS_TITLE: 'Assess',
  PROFILE_GOBACK_ASSESS_IMG: '/images/goback_assess.svg',
  PROFILE_GOBACK_PRIORITIZE_TITLE: 'Prioritize',
  PROFILE_GOBACK_PRIORITIZE_IMG: '/images/goback_prioritize.svg',

  NOTFOUND_TITLE: 'Oops!',
  NOTFOUND_SUBTITLE: 'We can\'t find that page.',

  ERROR_TITLE: 'Oops!',
  ERROR_SUBTITLE: 'Something went wrong.',
  ERROR_CTA: 'Try again',

  ERROR_MSG_PERSONAL_EMAIL: 'Please provide a business email address.',

  INCOMPLETE_TITLE: 'Oops!',
  INCOMPLETE_SUBTITLE: 'Incomplete or Unknown Assessment',
  INCOMPLETE_COPY: 'Before we can generate a plan you need to complete the assessment.',
  INCOMPLETE_CTA: 'Take Assessment',

  CTA_CTA: 'Request a Demo',

  EMAIL_CHALLENGE_TITLE: 'Loading Plan',
  EMAIL_CHALLENGE_BODY: 'To ensure your privacy please enter the E-mail used to create this report.',
  EMAIL_CHALLENGE_ERROR: 'No plan found matching the information provided.',

  LOGIN_TITLE: 'Login',
  LOGIN_SUBMITTED: 'A one-time login link has been emailed to you.',
  LOGIN_WARNING_HEADER: 'Login Tips:',
  LOGIN_WARNING_1: 'Login emails come from',
  LOGIN_WARNING_2: 'If it\'s not in your inbox within a minute, please check your SPAM folder.',
  LOGIN_WARNING_3: 'Open the login link using this browser only.',
  LOGIN_ERROR_TITLE: 'Login Error',
  LOGIN_ERROR_MSG: 'Please try again later.',

  LOGIN_WARNING_EMAIL: 'noreply@value-plan.firebaseapp.com',

  DEMO_FORM_TITLE: 'Schedule a Demo',
  DEMO_FORM_COPY: 'Tell us a bit more about yourself, and we\'ll tell you a lot more about us.',
  DEMO_FORM_CTA: 'Request Demo',
  DEMO_FORM_THANKYOU_TITLE: 'Thank you!',
  DEMO_FORM_THANKYOU_COPY: 'Schedule a Demo',
  DEMO_FORM_THANKYOU_CTA: 'Close',

  RESULTS_EMPTY_PHASE_OUTCOMES: 'Congratulations!  Due to your maturity assessment, all outcomes were moved into an earlier stage.',
  RESULTS_EMPTY_PHASE_METRICS: 'Congratulations!  Due to your maturity assessment, all metrics were moved into an earlier stage.',

  parse: replaceTokens,
};


