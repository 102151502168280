import {ISummaryContentModel} from '@data/model/interfaces';
import {SEQUENCE} from './defaults';

export default class SummaryModel {
  model : ISummaryContentModel = {
    summary: {
      [SEQUENCE.FOUNDATION]: [],
      [SEQUENCE.INTERMEDIATE]: [],
      [SEQUENCE.ADVANCED]: [],
    },
    model: {
      [SEQUENCE.FOUNDATION]: {
        metrics: [],
        outcomes: [],
      },
      [SEQUENCE.INTERMEDIATE]: {
        metrics: [],
        outcomes: [],
      },
      [SEQUENCE.ADVANCED]: {
        metrics: [],
        outcomes: [],
      },
    },
  };
};
