import {CURRENT_MODEL_VERSION} from './slices/uuidSlice';

const store = window.localStorage;
const MODEL_VERSION_KEY = 'model_version';
const DIMENSIONS_KEY = 'dimensions';
const DEMO_KEY = 'demo';
const PRIORITIES_KEY = 'priorities';
const DATASOURCES_KEY = 'dataSources';
const UUID_KEY = 'uuid';

// Todo mush this into one larger object

export const StoreMiddleware = () => (next: any) => (action: any) => {
  if (action.type === 'assess/update') {
    store.setItem(DIMENSIONS_KEY, JSON.stringify(action.payload));
  }

  if (action.type === 'demo/update') {
    store.setItem(DEMO_KEY, JSON.stringify(action.payload));
  }

  if (action.type === 'priorities/update') {
    store.setItem(PRIORITIES_KEY, JSON.stringify(action.payload));
  }

  if (action.type === 'dataSources/update') {
    store.setItem(DATASOURCES_KEY, JSON.stringify(action.payload));
  }

  if (action.type === 'uuid/update') {
    store.setItem(UUID_KEY, JSON.stringify(action.payload.uuid));
    store.setItem(MODEL_VERSION_KEY, JSON.stringify(action.payload.modelVersion));
  }

  if (action.type === 'main/init/pending') {
    upsertModelVersion();
  }

  return next(action);
};


// check to make sure no version_model
// if there is a version_model and it's not the current version model
//    then white out all data on local
//    then store current version model
function upsertModelVersion() {
  const jsonAsString = store.getItem(MODEL_VERSION_KEY) || '""';

  try {
    const versionFromStore = JSON.parse(jsonAsString);
    console.log(versionFromStore, CURRENT_MODEL_VERSION);

    if (versionFromStore !== CURRENT_MODEL_VERSION) {
      store.clear();
    }

    store.setItem(MODEL_VERSION_KEY, JSON.stringify(CURRENT_MODEL_VERSION));
  } catch (e) {
    console.error(e);
  }
}
