import Info from './Info';
import BullsEye from './BullsEye';
import User from './User';
import Home from './Home';
import Resource from './Resource';
import CheckedCircle from './CheckedCircle';
import Download from './Download';

const Icons = {Info, Download, BullsEye, Home, Resource, User, CheckedCircle};

export default Icons;
