import React from 'react';
import {COLORS} from '@constants/style';

const CheckedCircle = (props: any) => (
  <svg
    width={46}
    height={46}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx={23}
      cy={23}
      r={23}
      transform="rotate(-90 23 23)"
      fill={COLORS.PRIMARY.NAVY}
    />
    <circle cx={23} cy={23} transform="rotate(-90 23 23)" fill="#fff" r={15} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.36 16.812a.65.65 0 0 0-.928 0l-10.644 10.8-4.186-4.248a.65.65 0 0 0-.929 0 .673.673 0 0 0 0 .942l4.651 4.719a.65.65 0 0 0 .929 0l11.107-11.27a.673.673 0 0 0 0-.943Z"
      fill={COLORS.PRIMARY.GREEN}
      stroke={COLORS.PRIMARY.GREEN}
    />
  </svg>
);

export default CheckedCircle;
