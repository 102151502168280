import React from 'react';

import styled from 'styled-components';

import ResultsTile from '@views/Results/ResultsTile';
import {COLORS, COLORS_ARRAY} from '@constants/style';
import {ColoredRow} from '../PrioritiesResultTile/PrioritiesResultTile';
import Icons from '@components/Icons';
import {IMetric, WithStyleProps} from '@data/model/interfaces';
import {COPY} from '@constants/copy';

interface MetricsTileProps extends WithStyleProps{
  metrics: IMetric[],
  style?: any,
}

export const MetricsTile = ({metrics, ...props}: MetricsTileProps) => {
  return (
    <ResultsTile
      {...props}
      title={COPY.DASHBOARD_METRICS_TILE_TITLE}
      subtitle={COPY.DASHBOARD_METRICS_TILE_SUBTITLE}
      icon={<Icons.CheckedCircle />}
    >
      <BodyWrapper>
        {metrics.length === 0 && (
          <p>{COPY.RESULTS_EMPTY_PHASE_METRICS}</p>
        )}

        {metrics.map((metric, i) => (
          // TODO add right color based on which priority the metrics is assinged to
          <ColoredRow key={i} color={metric.color}>
            <RowBody>
              <NameWrapper>{metric.metric}</NameWrapper>
              <DescWrapper>{metric.description}</DescWrapper>
            </RowBody>
          </ColoredRow>
        ))}
      </BodyWrapper>
    </ResultsTile>
  );
};


const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 20px;    
`;

const RowBody = styled.div`  
  margin-left: 10px;
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const NameWrapper = styled.div`
  flex: 2;
  font-weight: 600;
  font-size: 0.8rem;
`;

const DescWrapper = styled.div`
  flex: 3;
  font-size: 0.8rem;
`;
