import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';

import FormCell from '@components/FormCell';
import {COLORS} from '@constants/style';
import {DEMO_FIELDS} from '@constants/fields';
import {IDemographics} from '@data/model/interfaces';
import {DemoFormGrid} from '@views/Demographics/DemographicsForm/DemographicsForm';
import ResultsTile from '@views/Results/ResultsTile';

export const DemographicsTile = ({demo, title='Your Information'}: {demo: IDemographics, title?: string}) => (

  <ResultsTile title={title}>
    <DemoFormGrid wide>
      <ProfileCell field={DEMO_FIELDS.FIRST_NAME}>
        {demo.firstName}
      </ProfileCell>
      <ProfileCell field={DEMO_FIELDS.LAST_NAME}>
        {demo.lastName}
      </ProfileCell>
      <ProfileCell field={DEMO_FIELDS.TITLE}>
        {demo.title}
      </ProfileCell>
      <ProfileCell field={DEMO_FIELDS.COMPANY_NAME}>
        {demo.companyName}
      </ProfileCell>
      <ProfileCell field={DEMO_FIELDS.EMAIL}>
        {demo.email}
      </ProfileCell>
      <ProfileCell field={DEMO_FIELDS.TOTAL_EMPLOYEES}>
        {demo.number_of_employees}
      </ProfileCell>
      <ProfileCell field={DEMO_FIELDS.CUSTOMER_SUCCESS_EMPLOYEES}>
        {demo.number_of_csms}
      </ProfileCell>
      <ProfileCell field={DEMO_FIELDS.INDUSTRY}>
        {demo.industry}
      </ProfileCell>
      <ProfileCell field={DEMO_FIELDS.EMPLOYEES_USE_GAINSIGHT}>
        {demo.users_of_gainsight}
      </ProfileCell>
      <ProfileCell field={DEMO_FIELDS.TEAMS_USE_GAINSIGHT}>
        {demo.teams_using_gainsight?.join(', ')}
      </ProfileCell>
    </DemoFormGrid>
  </ResultsTile>
);

const ProfileCell = ({children, field}: PropsWithChildren<{field: DEMO_FIELDS}>) => (
  <FormCell field={field}>
    <ValueWrapper>
      {children}
    </ValueWrapper>
  </FormCell>
);

const ValueWrapper = styled.div`
  border: 1px solid ${COLORS.LIGHTS.GREY2};
  padding: 10px;
  background-color: ${COLORS.LIGHTS.GREY};
  border-radius: 8px;
`;
