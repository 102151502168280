import React from 'react';

import {COLORS} from '@constants/style';

const Home = ({color=COLORS.WHITE, ...props}: {color: string}) => (
  <svg
    width={21}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m20.354 9.99-2.156-2.157L11.01.646a.718.718 0 0 0-1.02 0L2.802 7.833.646 9.99a.718.718 0 0 0 0 1.02.719.719 0 0 0 1.02 0l.928-.934v9.768a.719.719 0 0 0 .719.718h14.375a.719.719 0 0 0 .718-.718v-9.768l.928.934a.718.718 0 0 0 1.02 0 .72.72 0 0 0 0-1.02Zm-3.385 9.135H4.03V8.638L10.5 2.17l6.469 6.468v10.487Z"
      fill={color}
    />
  </svg>
);

export default Home;
