import {VERSION, WRITE_TO_STAGING_TABLES} from '@constants/config';
import {API_ROUTE, API_URLS} from '@constants/urls';
import {EMPLOYEE_SIZE_VALUES} from '@data/demographicConstants';
import axios from 'axios';
import {AssessmentResults, IDataSourceItem, IDemographics, IDemoRecord, IResultRecord} from '@data/model/interfaces';
import {convertSources, createDemoFromAssessment} from '@data/model/utils';

export const postAssessmentRecord = async (record: IResultRecord): Promise<{uuid: string}> => {
  const newRecord = addCompanySize(record);
  return _writeWithRetry(API_ROUTE.ASSESSMENT, newRecord);
};

export const postDemoRecord = async (record: IDemoRecord): Promise<{uuid: string}> => {
  const newRecord = addCompanySize(record);
  return _writeWithRetry(API_ROUTE.DEMO, newRecord);
};

// convert number_of_employees to a number
function addCompanySize(record: IDemoRecord | IResultRecord): any {
  return {
    ...record,
    company_size: EMPLOYEE_SIZE_VALUES[record.number_of_employees as keyof typeof EMPLOYEE_SIZE_VALUES],
  };
}

// write function with retry on exception
export const _writeWithRetry = async (route: API_ROUTE, record: IResultRecord | IDemoRecord): Promise<{uuid: string}> => {
  return new Promise((resolve, _reject) => {
    async function tryWrite(localRecord: IResultRecord | IDemoRecord, retyInterval: number = 0) {
      console.log('trying to write record', retyInterval);
      setTimeout(async () => {
        try {
          const {uuid} = await _writeRecord(route, localRecord);
          resolve({uuid});
        } catch (e) {
          console.error(e);
          await tryWrite(localRecord, retyInterval + 1000);
        }
      }, retyInterval);
    }
    tryWrite(record);
  });
};

// REMEMBER MY_TYPE[key as keyof typeof MY_TYPE]
const _writeRecord = async (route: API_ROUTE, record: IResultRecord | IDemoRecord): Promise<{uuid: string}> => {
  try {
    const payload = {
      ...record,
      // set client version
      version_client: VERSION,
    };

    const config = {
      headers: {
        'value_plan_staging': WRITE_TO_STAGING_TABLES,
      },
    };

    const resp = await axios.post(API_URLS[route], payload, config);

    return {uuid: resp.data.uuid};
  } catch (e: any) {
    throw e;
  }
};

interface IFetchResponse {
  uuid: string;
  createdAt?: Date;
  demo: IDemographics;
  assessment: AssessmentResults;
  priorities: string[];
  sources: IDataSourceItem[];
  versions: {
    clientVersion: string,
    serverVersion: string,
    modelVersion: string,
  }
}

export const fetchAssessmentByUUIDAndEmail = async ({uuid, email}: {uuid: string, email: string} ): Promise<IFetchResponse> => {
  // lite housekeeping
  email = email.trim().toLowerCase();
  uuid = uuid.trim();

  const config = {headers: {'value_plan_email': email}};
  const resp = await axios.get(API_URLS[API_ROUTE.ASSESSMENT] + '/' + uuid, config);

  const data: any = resp.data;

  console.log({data});

  const demo = createDemoFromAssessment(data);
  const sources = convertSources(data.sources);
  const createdAt = data.createdAt;
  const assessment = data.assessment;
  const priorities = data.priorities;
  const versions = {
    clientVersion: data.version_client,
    serverVersion: data.version_server,
    modelVersion: data.version_model,
  };

  return {versions, demo, sources, createdAt, uuid, assessment, priorities};
};
