

import React from 'react';
import {ResultsRouter} from './ResultsRouter';
import useHook from './useHook.Results';

export const Results = () => {
  const params = useHook();

  return (
    <ResultsRouter {...params}/>
  );
};


