import React from 'react';
import styled from 'styled-components';

import {COLORS} from '@constants/style';

const Resource = (props: any) => (
  <SVG
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.25 5.25a.375.375 0 0 0-.375.375v5.25a.375.375 0 0 1-.375.375H3.75a.375.375 0 0 1-.375-.375v-8.25a.375.375 0 0 1 .375-.375h4.875v1.875A.375.375 0 0 0 9 4.5h2.25a.375.375 0 0 0 .266-.641L9.375 1.72v-.596A1.125 1.125 0 0 0 8.25 0H1.5A1.125 1.125 0 0 0 .375 1.125v8.25A1.125 1.125 0 0 0 1.5 10.5h1.125v.375A1.125 1.125 0 0 0 3.75 12h6.75a1.125 1.125 0 0 0 1.125-1.125v-5.25a.375.375 0 0 0-.375-.375Zm-1.875-1.5v-.971l.971.971h-.971Zm-7.875 6a.375.375 0 0 1-.375-.375v-8.25A.375.375 0 0 1 1.5.75h6.75a.375.375 0 0 1 .375.375V1.5H3.75a1.125 1.125 0 0 0-1.125 1.125V9.75H1.5Z"
      fill={COLORS.PRIMARY.BLUE}
    />
    <path
      d="M9.375 5.25H8.25a.375.375 0 0 0 0 .75h.221l-.971.971-.859-.862a.375.375 0 0 0-.532 0l-1.5 1.5a.375.375 0 0 0 .41.613.375.375 0 0 0 .122-.08l1.234-1.238.859.862a.375.375 0 0 0 .532 0L9 6.53v.221a.375.375 0 0 0 .75 0V5.625a.375.375 0 0 0-.375-.375ZM9.375 9.75h-4.5a.375.375 0 1 1 0-.75h4.5a.375.375 0 0 1 0 .75ZM5.625 4.875h-.75a.375.375 0 1 1 0-.75h.75a.375.375 0 1 1 0 .75Z"
      fill={COLORS.PRIMARY.BLUE}
    />
  </SVG>
);

const SVG = styled.svg`
  position: relative;
  top: 4px;
`;

export default Resource;
