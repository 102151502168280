import Button from '@components/Button';
import {SurveyPageWrapper} from '@components/SurveyPageWrapper';
import {COPY} from '@constants/copy';
import {COLORS} from '@constants/style';
import {useSetPageTitle} from '@utils/hooks';
import DemographicsForm from '@views/Demographics/DemographicsForm';
import React from 'react';
import useHook from './useHook.Demographics';

export const Demographics = () => {
  useSetPageTitle('About You');

  const {onCompleted, nextClick, data, isDisabled} = useHook();

  return (
    <SurveyPageWrapper
      title={COPY.DEMO_HEADER_TITLE}
      subtitle={COPY.DEMO_HEADER_SUBTITLE}
      copy={COPY.DEMO_HEADER_COPY}
      color={COLORS.PRIMARY.BLUE}
      nextButton={<Button disabled={isDisabled} onClick={nextClick}>{COPY.DEMO_SUBMIT}</Button>}
    >

      <DemographicsForm onCompleted={onCompleted} data={data} />

    </SurveyPageWrapper>
  );
};
