import Icons from '@components/Icons';
import {COPY} from '@constants/copy';
import {ROUTE_PATH, ROUTE_TOKEN} from '@constants/routes';
import {COLORS, QUERIES} from '@constants/style';
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {ResultsContext} from '../useHook.Results';


export const ResultNavbar = () => {
  const [selected, setSelected] = useState<string|null>();
  const {onModalOpen, isSecure} = useContext(ResultsContext);
  const navigate = useNavigate();

  useEffect(() => {
    setSelected(window.location.pathname);
  }, [selected]);

  function update(route: string) {
    setSelected(route);
    navigate(route);
  }

  return (
    <Wrapper>
      <ul>
        <Item active={selected?.includes(ROUTE_TOKEN.DASHBOARD)} onClick={() => update(ROUTE_TOKEN.DASHBOARD)}>
          <Left><Icons.Home color={selected?.includes(ROUTE_TOKEN.DASHBOARD) ? COLORS.WHITE: COLORS.DARKS.CHARCOAL}/></Left>
          <Right>{COPY.DASHBOARD_NAV_DASHBOARD}</Right>
        </Item>
        <Item active={selected?.includes(ROUTE_TOKEN.NOW) } onClick={() => update(ROUTE_TOKEN.NOW)}>
          <Left><Icons.BullsEye color={selected?.includes(ROUTE_TOKEN.NOW) ? COLORS.WHITE: COLORS.PRIMARY.BLUE}/></Left>
          <Right>{COPY.DASHBOARD_NAV_NOW}</Right>
        </Item>
        <Item active={selected?.includes(ROUTE_TOKEN.SOON)} onClick={() => update(ROUTE_TOKEN.SOON)}>
          <Left><Icons.BullsEye color={selected?.includes(ROUTE_TOKEN.SOON) ? COLORS.WHITE: COLORS.PRIMARY.NAVY}/></Left>
          <Right>{COPY.DASHBOARD_NAV_SOON}</Right>
        </Item>
        <Item active={selected?.includes(ROUTE_TOKEN.LATER)} onClick={() => update(ROUTE_TOKEN.LATER)}>
          <Left><Icons.BullsEye color={selected?.includes(ROUTE_TOKEN.LATER) ? COLORS.WHITE: COLORS.PRIMARY.PINK}/></Left>
          <Right>{COPY.DASHBOARD_NAV_LATER}</Right>
        </Item>
      </ul>

      {!isSecure && (
        <DemoWrapper>
          <div>
            {COPY.DASHBOARD_NAV_DEMO_CTA}
          </div>
          <div onClick={onModalOpen}>
            <Circle />
          </div>
        </DemoWrapper>
      )}
    </Wrapper>
  );
};


interface IsActive {
  active?: boolean;
}

const Wrapper = styled.nav`
  width: 235px;
  padding: 20px 14px;  

  @media ${QUERIES.TABLET} {
    width: 50px;
    padding: 10px 4px;  
  }

  min-height: 100%;  
  background-color: ${COLORS.WHITE};    
  ul{
    padding: 0;
    margin: 0;      
    margin: 0;    
  }
`;

const Item = styled.li<IsActive>`  
  margin: 4px 0;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  padding: 6px 20px;

  @media ${QUERIES.TABLET} {
    padding: 6px 2px;
    justify-content: center;
  }

  background-color: ${({active}) => active ? COLORS.PRIMARY.PURPLE: 'revert'}; 
  color: ${({active}) => active ? COLORS.WHITE: 'revert'};
  cursor: pointer;
  &:hover{
    background-color: ${({active}) => active ? 'none': COLORS.LIGHTS.GREY}; 
  }
`;

const Left = styled.div`  
  height: 40px;
  width: 30px;
  color: ${COLORS.BLACK};
  display: grid;
  place-content: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  @media ${QUERIES.TABLET} {  
    display: none;
  }
`;

const DemoWrapper = styled.div`
  background-color: ${COLORS.PRIMARY.PURPLE_LITE};
  margin: 20px 0px;
  border-radius: 8px;
  padding: 20px;
  color: ${COLORS.WHITE};
  display: flex;
  div{       
    display: flex;
    align-items: flex-end;
    line-height: 1.4rem;    
    letter-spacing: .1rem;
  }
  @media ${QUERIES.TABLET} {
    display: none;
  }
`;

const Circle = styled.div`  
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: ${COLORS.PRIMARY.PURPLE};
  position: relative;
  &::after{
    font: var(--fa-font-solid);
    content: "\f105";
    position: relative;
    font-size: 1.6rem;
    left: 18px;
    top: -10px;    
  }
  &:hover{
    cursor: pointer;    
  }
  &:active{
    transform: translateY(2px);
  }
`;
