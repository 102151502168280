import React from 'react';
import styled from 'styled-components';

import {QUERIES, WIDTHS} from '@constants/style';
import {Outlet} from 'react-router-dom';
import ResultNavbar from '../ResultNavbar';

export const NavPage = () =>{
  return (
    <Wrapper>
      <Left>
        <ResultNavbar />
      </Left>
      <Body>
        <Outlet />
      </Body>
    </Wrapper>
  );
};

const Wrapper = styled.div`  
  display: flex;  
  gap: 20px;  
  max-width: ${WIDTHS.BASE};
  margin: auto;
`;

const Left = styled.div`  
  width: 235px;
  @media ${QUERIES.TABLET} {    
    width: 50px;
  }
  @media ${QUERIES.MOBILE} {        
    display: none;
  }
`;

const Body = styled.div`  
  
  padding: 20px 20px 40px 0;

  @media ${QUERIES.MOBILE} {        
    padding: 20px 10px 40px 10px; 
  }  
`;
