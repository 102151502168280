import {MONTH_NAMES} from '@constants/date';
import {IPriority, IPriorityDisplay, IPriorityResult} from '@data/model/interfaces';

export function isDefined(obj: any): boolean {
  return !(typeof obj === 'undefined' || obj === null);
}

// create display friendly date
export const formatDateFromSeconds = (seconds: number) => {
  const date = new Date(seconds * 1000);
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const time = formatTime(date);
  return `${MONTH_NAMES[monthIndex]} ${day}, ${year} @  ${time}`;
};

// create display time from date object
export const formatTime = (date: Date) : string => {
  const hours = date.getHours() % 12;
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hoursStr = hours ? hours : 12;
  const minutesStr = String(minutes < 10 ? '0' + minutes : minutes);

  return `${hoursStr}:${minutesStr} ${ampm} EST`;
};


export function makePriorityDisplay(modelVersion: string, priority: IPriorityResult|IPriority): IPriorityResult|IPriorityDisplay {
  // console.log(priority);

  const name = priority.name.trim();
  const index = modelVersion === '1.0.0' ? name.lastIndexOf(' ') : name.indexOf(' ');
  const [prefix, label] = [name.slice(0, index).trim(), name.slice(index).trim()];

  // console.log(prefix, '| ', label);
  return {
    ...priority,
    prefix,
    label,
  };
}

export const sortByKeyAsc = (a: any, b: any) => {
  if (a.key < b.key) return -1;
  if (a.key > b.key) return 1;
  return 0;
};
