import {COLORS} from '@constants/style';
import {PrioritesMapType} from '../interfaces';

const priorities: PrioritesMapType = {
  VISIBILITY: {
    key: 'VISIBILITY',
    name: 'Lack of Visibility',
    desc: 'Our team is missing a holistic, single view of our customers.',
    color: COLORS.PRIMARY.NAVY,
    icon: 'visibility.png',
  },
  SCALE: {
    key: 'SCALE',
    name: 'Inability to Scale',
    desc: 'Our team relies on manual processes and/or has high account ratios.',
    color: COLORS.PRIMARY.BLUE,
    icon: 'scale.png',
  },
  ADOPTION: {
    key: 'ADOPTION',
    name: 'Poor product Adoption',
    desc: 'Our customers aren\'t realizing the full value of our products or features.',
    color: COLORS.PRIMARY.YELLOW,
    icon: 'adoption.png',
  },
  EXPERIENCE: {
    key: 'EXPERIENCE',
    name: 'Disconnected Experience',
    desc: 'Our customers feel that our cross channel comms are disjointed.',
    color: COLORS.PRIMARY.PINK,
    icon: 'experience.png',
  },
  RETENTION: {
    key: 'RETENTION',
    name: 'Poor Retention',
    desc: 'We\'re unable to proactively address challenges that impede renewals.',
    color: COLORS.PRIMARY.PURPLE,
    icon: 'retention.png',
  },
  EXPANSION: {
    key: 'EXPANSION',
    name: 'Missed Expansion',
    desc: 'We don\'t know where to expand or tap into opportunities programmatically.',
    color: COLORS.PRIMARY.GREEN,
    icon: 'expansion.png',
  },
};


export const peerScores = {
  VISIBILITY: 1,
  SCALE: 2,
  RETENTION: 3,
  ADOPTION: 4,
  EXPERIENCE: 5,
  EXPANSION: 6,
};

// export const computePriorityResults = (results: string[]): IPriorityResult[] => {
//   const mergedResults : IPriorityResult[] = results.map((priority, i) => {
//     const p = priorities[priority];
//     return {
//       ...priorities[priority],
//       yourScore: i+1,
//       peerScore: PeerScores[priority as keyof typeof PeerScores],
//     };
//   });
//   return mergedResults;
// };


export default priorities;
