import {ROUTE_PATH} from '@constants/routes';
import {IDemographics} from '@data/model/interfaces';
import {useAppDispatch, useAppSelector} from '@services/store/hooks';
import {update} from '@services/store/slices/demoSlice';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

function useHook() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [demo, setDemo] = useState<IDemographics | null>(null);

  const navigate = useNavigate();
  const data = useAppSelector((store) => store.demo.data);
  const dispatch = useAppDispatch();

  function onCompleted(isCompleted: boolean, demo: IDemographics) {
    setIsDisabled(!isCompleted);
    setDemo(demo);
  }

  const nextClick = () => {
    navigate(ROUTE_PATH.SOURCES); // navigate('../yourdata');
    dispatch(update(demo));
  };

  return {onCompleted, nextClick, data, isDisabled};
}

export default useHook;
