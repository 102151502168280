import Button from '@components/Button';
import {SurveyPageWrapper} from '@components/SurveyPageWrapper';
import {COPY} from '@constants/copy';
import {COLORS} from '@constants/style';
import {useSetPageTitle} from '@utils/hooks';
import DataSourcesDragDrop from '@views/DataSources/DataSourcesDragDrop';
import React from 'react';
import {DataSourcesContext, useHook} from './useHook.DataSources';


export const DataSources = () => {
  useSetPageTitle('Your Data');
  const {selected, isNextEnabled, remove, onDragEnd, nextClick} = useHook();


  return (
    <DataSourcesContext.Provider value={{selected, isNextEnabled, remove, onDragEnd}}>
      <SurveyPageWrapper
        title={COPY.YOURDATA_HEADER_TITLE}
        subtitle={COPY.YOURDATA_HEADER_SUBTITLE}
        copy={COPY.YOURDATA_HEADER_COPY}
        color={COLORS.PRIMARY.BLUE}
        nextButton={<Button disabled={isNextEnabled} onClick={nextClick}>{COPY.DEMO_SUBMIT}</Button>}
      >

        <DataSourcesDragDrop />

      </SurveyPageWrapper>
    </DataSourcesContext.Provider>

  );
};
