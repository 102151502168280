import Icons from '@components/Icons';
import CONSTANTS from '@constants';
import {ROUTE_PATH, ROUTE_TOKEN} from '@constants/routes';
import {SHADOWS, WIDTHS} from '@constants/style';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {BodyWrapper} from '../../misc/SharedWrappers';

interface HeaderProps{
  shadow?: boolean;
  showProfileButton?: boolean;
}

export const Header = ({shadow = false, showProfileButton = false}: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <Wrapper shadow={shadow}>
      <InnerWrapper>

        <LogoWrapper>
          <a
            href='/'
            title='Gainsight | Value Creation Plan'>
            <img
              src='/images/top_logo.png'
              alt='Customer Success and Product Experience Software | Gainsight'
              style={{width: '600px', height: '100%'}}
            />
          </a>
        </LogoWrapper>

        {/* <Title to={ROUTE_PATH.HOME}>{COPY.HEADER_TITLE}</Title> */}

        {showProfileButton && (
          <IconWrapper>
            <Icons.User onClick={() => navigate(ROUTE_TOKEN.PROFILE) }/>
          </IconWrapper>
        )}

      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.header<HeaderProps>`
  background: ${CONSTANTS.COLORS.WHITE};
  height: 80px;
  position: relative;
  box-shadow: ${({shadow}) => shadow ? SHADOWS.HEADER : 'none'};
  width: 100%;
`;

export const InnerWrapper = styled(BodyWrapper)`
  padding: 0;    
  display: flex;
  align-items: center; 
  justify-content: space-between;
  max-width: ${WIDTHS.BASE};
  gap: 10px;  
`;

const IconWrapper = styled.div`
  display: grid;
  place-items: center;
  min-width: 54px;
`;

const LogoWrapper = styled.div`
  display: flex; 
  justify-content: center;
  place-content: center;
  width: clamp(300px, 80%, 600px);
  margin-left: 20px;
`;
