import {REGEX_TOKENS} from '@constants/regex';

export function replaceTokens(str: string, args: any): string {
  const matches = str.match(REGEX_TOKENS);

  const keys = matches?.map((match) => match.substring(0, match.length-1).substring(1));

  keys?.forEach((key) => {
    const reStr = `{${key}}`;
    const arg = args[key] === undefined || null ? '' : args[key];
    str = str.replace(reStr, String(arg));
  });

  return str;
}
