
import {AssessmentType, ICompletedAssessment, IPriority} from '@data/model/interfaces';


export default function rulesProcessor(dimensions: string[] ) {
  return (answers: ICompletedAssessment) => {
    return {

      // priority rules
      isPriority: (priority: IPriority) => {
        return {
          inTop: (n: number): boolean => {
            const index = answers.priorities.indexOf(priority.key);
            return index < n && index >= 0;
          },
        };
      },

      // assessment all
      isAssessmentAll: () => {
        return {
          greaterThan: (n: number): boolean => {
            return Boolean(dimensions.reduce((acc, value) => {
              const score = Number(answers.assessment[value] || 0);
              return acc && score > n;
            }, true));
          },

          lessThan: (n: number): boolean => {
            return Boolean(dimensions.reduce((acc, value) => {
              const score = Number(answers.assessment[value] || 0);
              return acc && score < n;
            }, true));
          },
        };
      },


      // assessment rules
      isAssessment: (category: string) => {
        return {

          greaterThan: (n: number): boolean => {
            const score = answers.assessment[category];
            if (score != undefined) {
              return score > n;
            }
            return false;
          },

          lessThan: (n: number): boolean => {
            const score = answers.assessment[category];
            if (score != undefined) {
              return score < n;
            }
            return false;
          },
        };
      },
    };
  };
}

export const ASSET_RULES = {
  _LOW: (v: any) => Number(v) <= 30,
  _MIDDLE: (v: any) => Number(v) > 30 && Number(v) <= 70,
  _HIGH: (v: any) => Number(v) > 70,
};
