
import React from 'react';
import {COLORS} from '@constants/style';
import {logAnayltics} from '@services/FirebaseHelper';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from 'styled-components';
import {EVENTS} from 'ui/misc/Analytics';
import useHook from './useHook.SalesView';
import {ResultsRouter} from '@views/Results/ResultsRouter';
import {InnerWrapper} from '@components/Header/Header';

export const SalesView = () => {
  const {userDataModel, summaryModel, isNotFound} = useHook();

  if (isNotFound) {
    logAnayltics(EVENTS.ERROR_404);
    return (
      <Centered style={{textAlign: 'center'}}>
        <h2>Sorry.</h2>
        <h4>No Record found.</h4>
      </Centered>
    );
  }
  if (!userDataModel || !summaryModel) {
    return (
      <Centered>
        <ClipLoader color={COLORS.PRIMARY.BLUE} size='60px' />
      </Centered>
    );
  }

  return (
    <Wrapper>
      <SecureHeader>
        <InnerHeader>
          <div><label>Plan for:</label> {userDataModel.demo.firstName} {userDataModel.demo.lastName} ({userDataModel.demo.email})</div>
          <div>&nbsp;</div>
        </InnerHeader>
      </SecureHeader>
      <ResultsRouter
        userDataModel={userDataModel}
        summaryModel={summaryModel}
        isCompleted={true}
        isInitialized={true}
        isShowModal={false}
        isSecure={true}
        onModalClose={() => {}}
        onModalOpen={() => {}}
        updateNavigation={() => {}}
      />
    </Wrapper>
  );
};


const Wrapper = styled.div`  
    /* margin: 40px;
    padding: 20px 0 60px 0;
    width: 1280px;
    margin: auto;
    h2{
      padding-bottom: 40px;
      color: ${COLORS.PRIMARY.NAVY};
    } */
`;

const SecureHeader = styled.div`
  background-color: ${COLORS.PRIMARY.NAVY};
  color: ${COLORS.WHITE};
`;

const InnerHeader = styled(InnerWrapper)`
  /* border: 1px solid red; */
  padding: 2px 30px;
  font-size: clamp(.6rem, 1.6vw, 1.2rem);
  height: 60px; 
  font-weight: 600;
  label{
    color: ${COLORS.PRIMARY.BLUE};
    padding-right: 4px;
  }
`;

const Centered = styled.div`
  display: grid;
  place-content: center;
  height: 66%;
  width: 100%;
`;


const Top = styled.div`
    display: grid;
    grid-template-columns: 80% 20%;
    gap: 10px;
    margin-right: 10px;
`;

const Bottom = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 10px;
`;
