import React from 'react';

import styled from 'styled-components';
import ResultsTile from '@views/Results/ResultsTile';
import {COLORS} from '@constants/style';
import Icons from '@components/Icons';
import {IOutcome, WithStyleProps} from '@data/model/interfaces';
import {COPY} from '@constants/copy';

interface DetailedPlanTileProps extends WithStyleProps{
  outcomes: IOutcome[]
}

export const DetailedPlanTile = ({outcomes, ...props}: DetailedPlanTileProps) => {
  return (
    <ResultsTile
      {...props}
      title={COPY.PLAN_YOUR_PLAN_TILE_TITLE}
      subtitle={COPY.PLAN_YOUR_PLAN_TILE_SUBTITLE}
      icon={<Icons.CheckedCircle />}
    >
      {outcomes.length === 0 && (
        <p>{COPY.RESULTS_EMPTY_PHASE_OUTCOMES}</p>
      )}

      {outcomes.length > 0 && (
        <TileBody>
          <Line/>
          { outcomes.map((outcome, i) => <JourneyBlock key={i} data={outcome}/>)}
        </TileBody>
      )}
    </ResultsTile>
  );
};

const JourneyBlock = ({data}: {data: IOutcome}) => {
  return (
    <BlockWrapper>
      <Left>
        <div>
          <img src="/images/sm_bullseye.svg" alt="bullseye"/>
        </div>
      </Left>
      <Right>
        <BlockHeader>
          <h5>{data.challenge}</h5>
          <h4>{data.outcome}</h4>
        </BlockHeader>
        <List>
          {data.capabilities.map((c, i) => <li key={i}>{c}</li>)}
        </List>
        {data.demo && (
          <BlockFooter>
            <a href={data.demo.url} target="_blank" rel="noreferrer" >{data.demo.label}</a>
          </BlockFooter>
        )}
      </Right>
    </BlockWrapper>
  );
};

const BlockWrapper = styled.div`
  --border-radius: 14px;  
  display: flex;
  position: relative;  
`;

const TileBody = styled.div`
  position: relative;
  margin-left: -5px;
`;

const Line = styled.div`
  
  width: 6px;
  background-color: ${COLORS.PRIMARY.BLUE};
  position: absolute;
  height: calc(100% - 30px);
  left: 27px;
  top: 30px;
`;

const Left = styled.div`  
  width: 60px;  
  display: flex;
  justify-content: center;
  div{
    
    margin-top: 20px;
    border: 8px solid ${COLORS.PRIMARY.BLUE};;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    background-color: ${COLORS.WHITE};
    display: grid;
    place-content: center;    
  }
  /* border: 1px solid red; */
`;
const Right = styled.div`
  
  background-color: ${COLORS.LIGHTS.GREY};
  border-radius: var(--border-radius);
  margin: 20px 0 0 10px;
  flex: 1;
`;

const BlockHeader = styled.div`
  padding: 10px 20px;
  h4{
    color: ${COLORS.PRIMARY.BLUE};
  }
`;

const List = styled.ul`
  padding: 0 10px 20px;
  margin: 0 20px;
  font-size: 0.8rem;
  li{
    padding: 6px 0;
  }
`;

const BlockFooter = styled.div`
  color: ${COLORS.WHITE};
  background-color: ${COLORS.PRIMARY.BLUE};
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  padding: 10px 20px;

  a{
    color: ${COLORS.WHITE};
    text-decoration: none;
    font-weight: 800;
    &:after{      
      font: var(--fa-font-solid);
      padding-left: 10px;
      font-size: 1rem;
      content: "\f061";
    }
    &:before{      
      font: var(--fa-font-solid);      
      font-size: 0.8rem;
      content: "\f87c";
      padding-right: 10px;
    }    
  }
`;
