import React from 'react';

import styled from 'styled-components';

import {QUERIES, COLORS, WIDTHS} from '@constants/style';
import {PageWrapperProps, PageWrapper} from '@components/PageWrapper/PageWrapper';

interface SurveyPageWrapperProps extends PageWrapperProps {
  title: string;
  subtitle: string;
  copy: string;
  children: any;
  nextButton: React.ReactNode;
}

export const SurveyPageWrapper = ({children, title, subtitle, copy, nextButton}: SurveyPageWrapperProps) => (
  <PageWrapper fullwidth color={COLORS.PRIMARY.BLUE}>
    <Wrapper>
      <TopWrapper>
        <TopLeft>
          <ImageWrapper>
            <LeftImg src="/images/discussion_1.svg" alt="Woman Image" />
          </ImageWrapper>
        </TopLeft>
        <TopMiddle>
          <ContentWrapper>
            <Title>{title}</Title>
            <SubTitle>{subtitle}</SubTitle>
            <HR />
            <P>{copy}</P>
          </ContentWrapper>
        </TopMiddle>
        <TopRight>
          <ImageWrapper>
            <RightImg src="/images/discussion_2.svg" alt="Man Image" />
          </ImageWrapper>
        </TopRight>
      </TopWrapper>
      <BottomWrapper>
        <BodyWrapper>
          {children}
          {nextButton}
        </BodyWrapper>
      </BottomWrapper>
    </Wrapper>
  </PageWrapper>
);


const Wrapper = styled.div`  
  position: relative; 
`;

const TopWrapper = styled.div`
  max-width: 100%;  
  margin: auto; 
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
`;

const TopLeft = styled.div`
  display: flex; 
  flex: 1;
  justify-content: flex-end;
  @media ${QUERIES.MOBILE} {  
    display: none;
  }  
 
`;

const TopRight = styled.div`
  flex: 1;
  display: flex;  
  align-content: flex-end;
  @media ${QUERIES.MOBILE} {  
    display: none;
  }
`;

const ImageWrapper = styled.div`  
  width: clamp(160px, 20vw, 220px);
`;

const LeftImg = styled.img`
  width: 100%;
`;

const RightImg = styled.img`
  width: 100%;
`;


const TopMiddle = styled.div`  
  display: flex;
  align-self: center;
  padding: 10px 0;
  color: ${COLORS.WHITE};  
`;

const ContentWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  @media ${QUERIES.TABLET} {  
    padding: 10px 0;
  }  
`;


const Title = styled.h1`
  font-size: clamp(1.2rem, 5vw, 2rem); 
  line-height: clamp(1.4rem, 5vw, 3rem);
  font-weight: 800;
  letter-spacing: 2px;  
`;

const SubTitle = styled.h2`    
  font-size: clamp(.8rem, 2vw, 1.2rem); 
  font-weight: 600;
  line-height: clamp(1.2rem, 3vw, 2rem);  
`;

const P = styled.p` 
  font-size: clamp(.8rem, 1.2vw, 1rem); 
  width: clamp(200px, 60%, 800px);
  @media ${QUERIES.MOBILE} {  
    width: 90%;
  }
`;

const HR = styled.div`
  margin: 10px 0;
  border-top: 1px solid ${COLORS.WHITE};
  width: 60px;
`;

const BottomWrapper = styled.div`    
  background-color: ${COLORS.WHITE};
  width: 100%;
  position: relative;
`;

const BodyWrapper = styled.main`  
  max-width: ${WIDTHS.BASE};
  display: flex;
  margin: 0px auto;
  padding: 60px 10px 80px;
  @media ${QUERIES.TABLET} {  
    padding: 20px 10px 40px;
  }
  flex-direction: column;
  align-items: center;
  gap: 40px;
`;
