import {AssessmentAnswers, AssessmentResults, CompleteModel, IAssessment, ICompletedAssessment, IContentModel, IPriority, IPriorityResult, ISummaryContentModel} from '@data/model/interfaces';
import {CURRENT_MODEL_VERSION} from '@services/store/slices/uuidSlice';
import {makePriorityDisplay} from '@utils/misc';
import {ASSESSMENT_MAP} from './defaults';
import SummaryModel from './summaryModel';
import v1 from './v1';
import v2 from './v2';

export class Model {
  version: string | null = null;
  data: CompleteModel;
  summary: ISummaryContentModel|null = null;

  constructor(version: string = CURRENT_MODEL_VERSION) {
    this.version = version;

    console.log('Loading model version: ', version);

    switch (version) {
      case '2.0.1':
        console.log('loading as 2.0.1');
        this.data = v2;
        break;

      case '2.0.0':
        console.log('loading as 2.0.0');
        this.data = v2;
        break;

      default:
        console.log('loading as 1.0.0');
        this.data = v1;
        break;
    }

    console.log('Model version: ', this.data.version);
  }

  prioritesAsList(): IPriority[] {
    return Object.values(this.data.priorities);
  }

  assessmentsAsList(): IAssessment[] {
    return Object.values(this.data.assessment);
  }

  challengeKeys() {
    return this.data.model.map((m) => m.challenge);
  }

  dimensionKeys() {
    return Object.values(this.data.assessment).map((d) => d.key);
  }

  createSummaryModel(data: ICompletedAssessment): ISummaryContentModel {
    const summaryModel = new SummaryModel().model;
    const localData = {...data};

    localData.assessment = this.mapAssessmentResults(data.assessment);

    const orderedModel = this.reorderModelBySequence(localData);

    const sortedModel = this.sortModelByPriority(orderedModel, localData.priorities);

    sortedModel.forEach(({challenge, outcomes, metrics}) => {
      outcomes.forEach((outcome) => {
        const sequence = outcome.sequence;
        if (sequence) {
          summaryModel.model[sequence].outcomes.push({challenge, ...outcome});
          summaryModel.summary[sequence].push(outcome.outcome);
        }
      });
      metrics.forEach((metric) => {
        const sequence = metric.sequence;
        if (sequence) {
          summaryModel.model[sequence].metrics.push(metric);
        }
      });
    });
    this.summary = summaryModel;

    return summaryModel;
  }

  computeAssessmentResults(answers: AssessmentAnswers): AssessmentResults {
    const questions = this.data.assessment;
    const results: AssessmentResults = {};
    try {
      const localAnswers = this.mapAssessmentResults(answers);

      Object.keys(localAnswers).forEach((key: string) => {
        const assets = questions[key].result.assets
            .filter(({rule}) => {
              return rule && rule(localAnswers[key]);
            });

        results[key] = {
          ...questions[key].result,
          assets: assets,
          score: localAnswers[key],
        };
      });
    } catch (e) {
      console.log({error: e});
      throw Error('Error in loading assessment results');
    }

    return results;
  };

  generatePrioritiesFromKeys(keys: string[]): IPriority[] {
    const priorities = this.data.priorities;
    const returnPriorities: IPriority[] = [];
    keys.forEach((key) => {
      if (key in priorities) {
        returnPriorities.push(priorities[key]);
      }
    });
    return returnPriorities;
  }

  computePriorityResults(results: string[]): IPriorityResult[] {
    const priorities = this.data.priorities;
    const peerScores = this.data.peerScores;

    const mergedResults : IPriorityResult[] = results.map((result, i) => {
      const resultCap = result.toUpperCase();

      const p = makePriorityDisplay(this.data.version, priorities[resultCap]);
      return {
        ...p,
        peerScore: peerScores[resultCap],
        yourScore: i+1,
      };
    });
    return mergedResults;
  };

  private reorderModelBySequence(data: ICompletedAssessment): IContentModel[] {
    // have a local copy so we aren't updating the main one
    const localModel = [...this.data.model];

    // console.log({localModel});

    localModel.forEach((m) => {
      m.outcomes.forEach((o) => {
        if (typeof o.rule === 'function') {
          o.sequence = o.rule(data) || o.sequence;
        }
      });
      m.metrics.forEach((m) => {
        // console.log({m});
        if (typeof m.rule === 'function') {
          m.sequence = m.rule(data) || m.sequence;
        }
      });
    });

    return localModel;
  };

  // Return a model where challenges are sorted by the user's priorities
  private sortModelByPriority(localModel: IContentModel[], priorities: string[]): IContentModel[] {
    const sortedModel : IContentModel[] = [];
    priorities.forEach((priority) => {
      const items = localModel
          .filter((item: IContentModel) => item.key === priority.toLocaleUpperCase());

      items.forEach((item) => sortedModel.push(item));
    });
    return sortedModel;
  }

  private mapAssessmentResults(answers: AssessmentAnswers): AssessmentAnswers {
    const major = this.version?.split('.')[0];
    if (Number(major) === 2) return answers;

    const tmp : {[key: string]: any} = {};
    Object.keys(answers).forEach((key) => {
      tmp[ASSESSMENT_MAP[key]] = answers[key];
    });

    console.log({tmp});

    return tmp;
  }
};


let _version: string;
let _model: Model;

export function getModel(version: string | null): Model {
  version = version || CURRENT_MODEL_VERSION;

  if (_model === undefined || _version !== version) {
    _version = version;
    _model = new Model(version);
  }

  return _model;
}


