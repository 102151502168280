import priorities, {peerScores} from './priorities';
import {CompleteModel, IContentModel} from '@data/model/interfaces';
import {getMetrics} from './metrics';
import {getOutcomes} from './outcomes';
import rulesProcessor from '../RulesProcessor';
import assessment from './assessment';

const version = '2.0.0';

export function getContentModel(): CompleteModel {
  const dimensions = Object.keys(assessment);

  const $ = rulesProcessor(dimensions);
  const outcomes = getOutcomes($);
  const metrics = getMetrics($);

  const model: IContentModel[] = [
    {
      key: priorities.SCALE_EFFICIENCY.key,
      challenge: priorities.SCALE_EFFICIENCY.name,
      color: priorities.SCALE_EFFICIENCY.color,
      outcomes: [
        outcomes.INCREASE_CUSTOMER_FACING_TEAMS_IMPACT,
        outcomes.INCREASE_LIFECYCLE_AUTOMATION,
        outcomes.INCREASE_SELFSERVICE_ENGAGEMENT,
        outcomes.PRIORITIZE_RND_EFFECTIVELY,
      ],
      metrics: [
        metrics.END_USER_NPS,
        metrics.PERCENT_OF_CUSTOMERS_MANAGED,
        metrics.COST_TO_SERVE,
        metrics.FEATURE_ADOPTION_RATE,
      ],
    },
    {
      key: priorities.EXPERIENCE_ADOPTION.key,
      challenge: priorities.EXPERIENCE_ADOPTION.name,
      color: priorities.EXPERIENCE_ADOPTION.color,
      outcomes: [
        outcomes.INCREASE_ADVOCATES,
        outcomes.OPTIMIZE_CUSTOMER_JOURNEY,
        outcomes.IMPROVE_ADOPTION_BREADTH_DEPTH,
        outcomes.IMPROVE_USER_ENGAGEMENT_EXPERIENCE,
      ],
      metrics: [
        metrics.NPS,
        metrics.PERCENT_OF_CUSTOMERS_WITH_ADVOCATES,
        metrics.CUSTOMER_LIFETIME_VALUE,
        metrics.ACTIVE_USERS,
        metrics.USER_RETENTION,
      ],
    },
    {
      key: priorities.RETENTION.key,
      challenge: priorities.RETENTION.name,
      color: priorities.RETENTION.color,
      outcomes: [
        outcomes.INCREASE_RENEWAL_FORECAST_ACCURACY,
        outcomes.REDUCE_RISK_CHURN,
        outcomes.IMPROVE_HEALTH_SCORE_PREDICTIVENESS,
        outcomes.IMPROVE_CUSTOMER_ROI,
      ],
      metrics: [
        metrics.GRR,
        metrics.PERCENT_ON_TIME_RENEWALS,
        metrics.AT_RISK_DOLLARS_RECOVERED,
        metrics.ACCOUNT_RATIOS,
        metrics.PERCENT_OF_ACCOUNTS_WITH_VERIFIED_OUTCOME,
      ],
    },
    {
      key: priorities.EXPANSION.key,
      challenge: priorities.EXPANSION.name,
      color: priorities.EXPANSION.color,
      outcomes: [
        outcomes.INCREASE_GROWTH_THROUGH_ADVOCACY,
        outcomes.REDUCE_REVENUE_LEAKAGE,
        outcomes.IMPROVE_EXPANSION_VELOCITY,
        outcomes.INCREASE_EXPANSION_PIPELINE,
      ],
      metrics: [
        metrics.NRR,
        metrics.REVENUE_INFLUENCED_BY_ADVOCATES,
        metrics.PERCENT_WHITESPACE_REMAINING,
        metrics.AVERAGE_TIME_TO_EXPAND,
        metrics.EXPANSION_CLOSE_RATE,
      ],
    },
  ];

  model.forEach((item) => {
    item.metrics.forEach((metric) => {
      metric.color = item.color;
    });
  });


  return {
    model,
    priorities,
    assessment,
    outcomes,
    metrics,
    peerScores,
    version,
  };
}


