// import {ASSESSMENT, PRIORITY, SEQUENCE} from '../../defaults';
import priorities from './priorities';
import {ICompletedAssessment} from '@data/model/interfaces';
import {SEQUENCE} from '@data/model/defaults';

export const getOutcomes = ($: any) => {
  const outcomes = {

    IDENTIFY_AND_RESOLVE_RISKS: {
      outcome: 'Identify and Resolve Risks',
      capabilities: [
        'Types of risk are defined with clear criteria and specific ways to address.',
        'Processes to regularly review risks, understand progress and escalate as needed.',
        'Incorporate additional automated risk signals and resolution programs.',
      ],
      sequence: SEQUENCE.FOUNDATION,
    },

    VALUE_REALIZATION: {
      outcome: 'Value Realization',
      capabilities: [
        'Programmatic ways to collect and review customer goals.',
        'Processes and tools to track and share progress towards goals, and document ROI.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.RETENTION).inTop(3) &&
          $(d).isAssessmentAll().greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },

    SYSTEMATIC_RENEWAL_PROCESS_AND_FORCASTING: {
      outcome: 'Systematic Renewal Process and Forecasting',
      capabilities: [
        'Defined processes for managing individual renewals.',
        'Give greater visibility of the renewal pipeline, and improve forecasts.',
        'Automation to streamline the renewal journey.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.RETENTION).inTop(3) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(66)
        ) ? SEQUENCE.INTERMEDIATE: SEQUENCE.ADVANCED;
      },
    },

    SINGLE_SOURCE_OF_TRUTH: {
      outcome: 'Single Source of Truth',
      capabilities: [
        'Bring customer data and workflows into Gainsight to reduce switching between systems.',
        'Document key exchanges, goals, and attributes to create a more complete customer record.',
        'Customized views and dashboards to serve the needs of specific roles and functions.',
      ],
      sequence: SEQUENCE.FOUNDATION,
    },

    ACCURATELY_GAUGE_CUSTOMER_HEALTH: {
      outcome: 'Accurately Gauge Customer Health',
      capabilities: [
        'Use manual and automatically calculated inputs to create an actionable health score.',
        'Score certain customers differently based on stage or partner involvement.',
      ],
      sequence: SEQUENCE.FOUNDATION,
    },

    INSIGHTS_SHARED_ACROSS_COMPANY_AND_WITH_CUSTOMERS: {
      outcome: 'Insights Shared Across Company and With Customers',
      capabilities: [
        'Share Gainsight workflows, insights, and metrics with other groups and tools.',
        'Facilitate greater customer alignment and collaboration.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.VISIBILITY).inTop(3) &&
          $(d).isAssessmentAll().greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },

    DISCOVER_AND_QUALIFY_CUSTOMER_LEADS: {
      outcome: 'Discover and Qualify Customer Leads',
      capabilities: [
        'Use a multi-threaded approach to inform customers of new use cases and solutions, and programmatically collect expansion leads.',
        'Use customer data to identify areas of opportunity and likelihood to expand.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
            $(d).isPriority(priorities.EXPANSION).inTop(3) &&
            $(d).isAssessmentAll().greaterThan(33)
          ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },

    IMPROVE_CLOSE_RATES_AND_SPEED: {
      outcome: 'Improve Close Rates and Speed',
      capabilities: [
        'Involve multiple teams and handoffs in the expansion strategy for individual customers.',
        'Nurture strong relationships with economic buyers.',
        'Use real-world success stories and advocates to highlight tangible value.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
            $(d).isPriority(priorities.EXPANSION).inTop(3) &&
            $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
            $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
            $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
            $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
            $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
          ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },

    UNDERSTAND_WHY_CUSTOMERS_DONT_EXPAND: {
      outcome: 'Understand Why Customers Don\'t Expand',
      capabilities: [
        'Identify the most common reasons customers don&quot;t expand, according to the customers themselves.',
        'Research in-depth customer and market trends, and share the results.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
              $(d).isPriority(priorities.EXPANSION).inTop(3) &&
              $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
              $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
              $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
              $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
              $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
            ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },

    },

    UNDERSTAND_ADOPTION_TRENDS: {
      outcome: 'Understand Adoption Trends',
      capabilities: [
        'Analyze and normalize patterns in usage data to define and track optimal in-product behavior.',
        'Publish trending usage data for internal review and action.',
        'Visualize provisioned vs purchased licenses in a Deployment Health Score.',
        'Visualize active/healthy vs provisioned licenses in a Depth of Adoption Health Score.',
        'Gauge the degree of active/healthy usage at the feature level with a set of Breadth of Adoption Health Scores.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
            $(d).isPriority(priorities.ADOPTION).inTop(3) &&
            $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
            $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
            $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
            $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
          ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },

    PROACTIVE_ENABLEMENT: {
      outcome: 'Proactive Enablement',
      capabilities: [
        'Guide new users/customers to use cases aligned with their goals and maturity.',
        'Drive awareness and use of new and existing features with personalized self-service enablement.',
        'Demonstrate value of paid tiers and services.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.ADOPTION).inTop(3) &&
          $(d).isAssessmentAll().greaterThan(33)
        ) ? SEQUENCE.INTERMEDIATE: SEQUENCE.ADVANCED;
      },
    },

    COURSE_CORRECT_ADOPTION_ISSUES: {
      outcome: 'Course Correct Adoption Issues',
      capabilities: [
        'Proactively research customer product sentiment and business needs.',
        'Address changing trends in usage or support tickets.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
            $(d).isPriority(priorities.ADOPTION).inTop(3) &&
            $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
            $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
            $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
            $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
          ) ? SEQUENCE.INTERMEDIATE: SEQUENCE.ADVANCED;
      },
    },

    CONSISTENTLY_DELIVER_LIFECYCLE: {
      outcome: 'Consistently Deliver Lifecycle',
      capabilities: [
        'Key “Moments of Truth” with desired customer experience, specific CS activities and anticipated results, are defined and tracked.',
        'Blend automated and human actions to consistently deliver Executive Business Reviews, from high touch to tech touch.',
        'Encourage consistent stakeholder participation with streamlined Executive Engagement.',
        'Ensure CSMs are regularly engaging with their entire portfolio.',
        'Quickly drive new customers to first value.',
      ],
      sequence: SEQUENCE.FOUNDATION,
    },

    UNDERSTAND_AND_ACT_ON_CUSTOMER_SENTIMENT: {
      outcome: 'Understand and Act On Customer Sentiment',
      capabilities: [
        'Gather feedback (NPS, CSAT, CES) from users and customers across channels and within your product, and systematically respond to users with predefined actions based on sentiment.',
        'Identify experience trends across channels to guide cross-functional improvements.',
        'Preemptively work with customers to understand and prepare for larger market and business landscape trends.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
            $(d).isPriority(priorities.EXPERIENCE).inTop(3) &&
            $(d).isAssessmentAll().greaterThan(33)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },

    MOBILIZE_ADVOCATES_FOR_GROWTH: {
      outcome: 'Mobilize Advocates for Growth',
      capabilities: [
        'Identify potential advocates based on survey responses, CSM input and other user engagement data.',
        'Attribute monetary value to advocacy and reference programs.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPERIENCE).inTop(3) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(66) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
        ) ? SEQUENCE.INTERMEDIATE: SEQUENCE.ADVANCED;
      },
    },

    OPTIMIZE_CSM_WORKFLOWS: {
      outcome: 'Optimize CSM Workflows',
      capabilities: [
        'Embed CS insights and workflows in the productivity tools your CSMs already use including email, Slack, mobile, and CRM.',
        'Arm CSMs with standardized playbooks, customizable email templates, and pre-designed slides that incorporate customer data.',
      ],
      sequence: SEQUENCE.FOUNDATION,
    },

    BLEND_TECH_AND_HUMAN_TOUCHES: {
      outcome: 'Blend Tech and Human Touches',
      capabilities: [
        'Automated motions to drive new customers to first value.',
        'Automated motions to support customer alignment.',
        'Automation to streamline the renewal journey.',
        'Guide customer and user journeys with email and in-product engagements, and trigger interventions when customer or user behavior changes.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
          $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33) &&
          $(d).isPriority(priorities.SCALE).inTop(3)
        ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },

    AUTOMATED_SIGNALS_AND_ALERTS: {
      outcome: 'Automated Signals and Alerts',
      capabilities: [
        'Automated and predictive alerts and health scoring identify areas of risk and opportunity within an account.',
        'Give other teams visibility and actionable insights from Gainsight.',
      ],
      rule: (d: ICompletedAssessment) => {
        return (
            $(d).isPriority(priorities.SCALE).inTop(3) &&
            $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
            $(d).isAssessment('PROCESS_WORFLOWS').greaterThan(66) &&
            $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
            $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
          ) ? SEQUENCE.INTERMEDIATE: SEQUENCE.ADVANCED;
      },
    },
  };
  return outcomes;
};

