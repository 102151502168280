import React, {useContext} from 'react';
import styled from 'styled-components';
import Dimension from '@views/Assess/Dimension';

import {AssessContext} from '../useHook.Assess';
import {AssessTooltip} from '@components/MyToolTip';
import {QUERIES} from '@constants/style';
import {sortByKeyAsc} from '@utils/misc';

export const DimensionList = () => {
  const {questions} = useContext(AssessContext);

  return (
    <Wrapper>
      { questions.sort(sortByKeyAsc).map((q, i) => (<Dimension question={q} key={String(i)} />)) }
      <AssessTooltip id='assessTT'/>
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  padding: 0 40px;  
  @media ${QUERIES.TABLET} {    
    padding: 10px;
  }  
`;
