import {ENV, VERSION} from '@constants/config';
import {IDataSourceItem, IDemoRecord, IResultRecord} from '@data/model/interfaces';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {fetchAssessmentByUUIDAndEmail, postAssessmentRecord, postDemoRecord} from '@services/HTTPServices';
import {init as initAssess, update as updateAssess, clear as clearAssess} from './slices/assessSlice';
import {init as initDemo, update as updateDemo, clear as clearDemo} from './slices/demoSlice';
import {init as initPriorities, update as updatePriorities, clear as clearPriorities} from './slices/prioritiesSlice';
import {init as initDataSources, update as updateDataSources, clear as clearDataSources} from './slices/dataSourcesSlice';
import {init as initUUID, update as updateUUID, clear as clearUUID, PREVIOUS_MODEL_VERSION} from './slices/uuidSlice';

export const initAsync = createAsyncThunk('main/init', async (_, thunkAPI) => {
  loadAndDispatch(thunkAPI, 'priorities', initPriorities);
  loadAndDispatch(thunkAPI, 'dimensions', initAssess);
  loadAndDispatch(thunkAPI, 'demo', initDemo);
  loadAndDispatch(thunkAPI, 'dataSources', initDataSources);
  loadAndDispatch(thunkAPI, 'uuid', initUUID);
  console.log(`App (${VERSION}) Initialized Successfully (env: ${ENV})`);
});

export const clearDataAsync = createAsyncThunk('main/clearData', async (_, thunkAPI) => {
  clearAndDispatch(thunkAPI, 'priorities', clearPriorities);
  clearAndDispatch(thunkAPI, 'dimensions', clearAssess);
  clearAndDispatch(thunkAPI, 'demo', clearDemo);
  clearAndDispatch(thunkAPI, 'dataSources', clearDataSources);
  clearAndDispatch(thunkAPI, 'uuid', clearUUID);
  console.log('All user data cleared');
});

export const loadAssessmentByUUIDAndEmail = createAsyncThunk('main/fetchAssessmentByUUID', async ({uuid, email} : {uuid: string, email: string}, thunkAPI) => {
  const {versions, demo, assessment, priorities, sources} = await fetchAssessmentByUUIDAndEmail({uuid, email});

  thunkAPI.dispatch(updateAssess(assessment));
  thunkAPI.dispatch(updateDemo(demo));
  thunkAPI.dispatch(updateDataSources(sources));
  thunkAPI.dispatch(updatePriorities(priorities));
  thunkAPI.dispatch(updateUUID({
    uuid,
    clientVersion: versions.clientVersion,
    serverVersion: versions.serverVersion,
    modelVersion: versions.modelVersion || PREVIOUS_MODEL_VERSION,
  }));
});

export const postAssessmentAsync = createAsyncThunk('main/post', async (_, thunkAPI) => {
  const {assess, demo, priorities, dataSources, uuid} = thunkAPI.getState() as any;
  const record = {
    ...demo.data,
    assessment: assess.answers,
    priorities: priorities.data,
    sources: dataSources.data.map((s: IDataSourceItem) => s.label),
    version_model: uuid.modelVersion,
  } as IResultRecord;

  const resp = await postAssessmentRecord(record);

  thunkAPI.dispatch(updateUUID({
    uuid: resp.uuid,
    modelVersion: uuid.modelVersion,
  }));
});

export const postDemoAsync = createAsyncThunk('main/postDemo', async ({phone}: any, thunkAPI) => {
  const {demo, uuid} = thunkAPI.getState() as any;

  const record: IDemoRecord = {
    email: demo.data.email,
    firstName: demo.data.firstName,
    lastName: demo.data.lastName,
    companyName: demo.data.companyName,
    number_of_employees: demo.data.number_of_employees,
    phone,
    uuid: uuid.uuid,
  };

  await postDemoRecord(record);
});

function loadAndDispatch(thunkAPI: any, key: string, init: any) {
  try {
    const str = window.localStorage.getItem(key);
    if (!str) return;
    const data = JSON.parse(str);
    thunkAPI.dispatch(init(data));
  } catch (e) {
    console.log(e);
  }
}

function clearAndDispatch(thunkAPI: any, key: string, clear: any) {
  try {
    window.localStorage.removeItem(key);
    thunkAPI.dispatch(clear());
  } catch (e) {
    console.log(e);
  }
}
