import {useEffect, useState} from 'react';
import Cookies from 'js-cookie';
import {logAnayltics} from '@services/FirebaseHelper';
import {EVENTS} from 'ui/misc/Analytics';

function useHook() {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const consentValue = Cookies.get('cookieConsent');
    if (consentValue) {
      setAccepted(true);
    }
  }, []);

  const setAccept = () => {
    Cookies.set('cookieConsent', String(true), {expires: 365});
    setAccepted(true);
    logAnayltics(EVENTS.COOKIE_CONSENT);
  };
  return {setAccept, accepted};
}

export default useHook;
