import {COLORS} from '@constants/style';
import {PrioritesMapType} from '../interfaces';

const version = 2;

const priorities = {
  SCALE_EFFICIENCY: {
    key: 'SCALE_EFFICIENCY',
    name: 'Increase Scale & Efficiency',
    prefix: '',
    desc: 'Align business around a single source of truth, automate actions, and orchestrate customer engagements to ensure value delivery at scale.',
    color: COLORS.PRIMARY.BLUE,
    icon: 'scale.png',
  },
  EXPERIENCE_ADOPTION: {
    key: 'EXPERIENCE_ADOPTION',
    name: 'Improve User Experience & Product Adoption',
    prefix: '',
    desc: 'Analyze usage and sentiment data to create targeted in-app engagements to drive behaviors, deliver on feedback, and drive growth.',
    color: COLORS.PRIMARY.DARK_YELLOW,
    icon: 'adoption.png',
  },
  RETENTION: {
    key: 'RETENTION',
    name: 'Improve Retention',
    prefix: '',
    desc: 'Deeply understand your customers to proactively guide them to value, identify early signals of risk, and effectively mitigate churn at scale.',
    color: COLORS.PRIMARY.PURPLE,
    icon: 'retention.png',
  },
  EXPANSION: {
    key: 'EXPANSION',
    name: 'Increase Expansion',
    prefix: '',
    desc: 'Use predictive analytics & workflows to scale and prioritize efforts around renewing and expanding successful customers and decreasing churn.',
    color: COLORS.PRIMARY.GREEN,
    icon: 'expansion.png',
  },
};


// export function generatePrioritiesFromKeys(keys: string[]): IPriority[] {
//   const returnPriorities: IPriority[] = [];
//   keys.forEach((key) => {
//     if (key in prioritiesAsMap) {
//       returnPriorities.push(prioritiesAsMap[key]);
//     }
//   });
//   return returnPriorities;
// }

export const peerScores = {
  SCALE_EFFICIENCY: 1,
  RETENTION: 2,
  EXPERIENCE_ADOPTION: 3,
  EXPANSION: 4,
};

// /**
//  * order priorities based on user input
//  * @param {PRIORITY[]} results
//  * @return {PRIORITY[]} priorities
//  */
// export const computePriorityResults = (results: PRIORITY[]): IPriorityResult[] => {
//   const mergedResults : IPriorityResult[] = results.map((result, i) => {
//     const p = prioritiesAsMap[result];
//     const priority = p.label as PRIORITY;
//     return {
//       priority,
//       label: p.label,
//       yourScore: i+1,
//       color: p.color,
//       peerScore: PeerScores[priority],
//     };
//   });
//   return mergedResults;
// };


export default priorities;
