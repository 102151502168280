import React from 'react';
import {PageWrapper} from '@components/PageWrapper';
import {COLORS} from '@constants/style';

import {BOLD, LI1, LI, LIA, OL, UL, P, Subtitle, Title, Wrapper} from './Styles';

export const Terms = () => (
  <PageWrapper shadow color={COLORS.WHITE}>
    <Wrapper>
      <Title>Terms Of Use</Title>

      <Subtitle>INTRODUCTION</Subtitle>

      <P>These Terms of Use (the “Terms” or “this Agreement”) are a legal agreement between you
        and Dev Garage LLC (the “Company”, “we”, “our”, or “us”) governing your access to and use
        of the Company’s website located at <a href="https://gainsight.valueplan.io">https://gainsight.valueplan.io</a>,
        additional sites connecting to these Terms of Use (the “Site”) and our related services, including without limitation
        any web and mobile applications that we own or control now existing or implemented in
        the future and the services which Com</P>

      <P>If you are accessing the Site or using the Service on behalf of a third party that has entered into
        a separate agreement with the Company in connection with the Services (“Services Agreement”), your
        use of the Site and Services will also be subject to the terms and conditions of the Services Agreement.</P>

      <P>PLEASE BE ADVISED THAT THESE TERMS OF USE CONTAIN PROVISIONS THAT GOVERN HOW CLAIMS YOU AND WE HAVE AGAINST
        EACH OTHER ARE RESOLVED (SEE DISCLAIMERS (SECTION 7), INDEMNITY (SECTION 10) AND LIMITATION OF LIABILITY (SECTION 11)
        PROVISIONS BELOW).</P>

      <P>THESE TERMS ALSO CONTAIN AN ELECTION TO ARBITRATE, WHICH WILL, UPON ELECTION BY YOU OR US, REQUIRE YOU TO SUBMIT CLAIMS
        YOU HAVE AGAINST US TO BINDING AND FINAL ARBITRATION, UNLESS YOU OPT OUT OF THE ARBITRATION AGREEMENT (SEE DISPUTE RESOLUTION
        AND ARBITRATION AGREEMENT, SECTION 12.3).  UNLESS YOU OPT OUT, IN THE EVENT OF AN ELECTION BY THE COMPANY TO ARBITRATE YOUR
        CLAIMS: (1) DIPUTES BETWEEN YOU AND THE COMPANY WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND (2) YOU WILL ONLY
        BE PERMITTED TO SEEK RELIEF ON AN INDIVIDUAL BASIS AND YOU WAIVE YOUR RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN
        ANY CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.</P>

      <P>BY ACCESSING THE SITE OR USING ANY OF THE SERVICES YOU AGREE TO BE BOUND BY THESE TERMS AND THE CODE OF CONDUCT.  YOU UNDERSTAND
        THAT BY ACCESSING OR USING THE SITE OR SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO BE LEGALLY BOUND
        BY AND COMPLY WITH THESE TERMS OF USE AND THE CODE OF CONDUCT.  IF YOU DO NOT OR CANNOT AGREE WITH ANY PART OF THESE TERMS OF
        USE AND THE CODE OF CONDUCT YOU MAY NOT USE THE SITE OR ANY SERVICES PROVIDED ON OR THROUGH THE SITE.</P>

      <ol style={{counterReset: 'counter', paddingLeft: '1rem'}}>

        <LI1>
          <P><BOLD> Privacy.</BOLD> The Company respects the privacy of is users. The Company collects,
          uses and discloses information about you in accordance with the Company&aops;s Privacy Policy
          (“<a href="/privacy/">Privacy Policy</a>”). Your privacy is very important to the Company. We do not
          collect personally identifiable information from any child under the age of eighteen without
          verifiable parental consent. We do not share your information except under certain circumstances
          as outlined in the Privacy Policy. You understand and agree that, notwithstanding any measures
          taken to prevent unauthorized disclosure, use of or connection to the Internet provides the
          opportunity for unauthorized third parties to circumvent such precautions and illegally gain access
          to confidential information. Accordingly, the Company cannot and does not guarantee the privacy,
          security or authenticity of any information so transmitted over or stored in any system connected to the Internet.
          </P>

        </LI1>

        <LI1>
          <BOLD>Registration.</BOLD> You may access certain online features of the Services through your account on the
          Site (your “Account”). You may log into your Account using a unique username and password. You are responsible
          for safeguarding your password and you agree not to disclose your password to any third party. You are solely
          responsible for any actions taken under your username. You agree that the information that you provide to the
          Company about yourself upon registration of your Account, and at all other times, (“Customer Data”) will be true,
          accurate, current and complete, and you further agree that you will maintain and promptly update the Customer Data
          to ensure that it remains true, accurate, current and complete. You may not impersonate any other person or use a
          username or password that you are not authorized to use. the Company reserves the right to require you to change
          your password for any reason at any time. If you become aware of any unauthorized use of your Account, you agree
          to notify the Company immediately at privacy@dgarage.io.
        </LI1>

        <LI1>
          <BOLD>Agreement for Use.</BOLD> You warrant that you are at least 18 years of age and possess the legal authority
            to enter into this Agreement and to use the Site and the Services with all terms therein.  You agree to be financially
            responsible for all of your use of the Site and the Services, as well as for the use of your account by others to whom
            you provide access.  For any services for which fees may be applied, you agree to abide by the terms and conditions of
            purchase imposed including payment of all amounts when due. You agree that you are responsible for all charges, fees and
            taxes arising out of the use of the Site and the Services.
        </LI1>

        <LI1>
          <BOLD>Compliance With Terms and Code of Conduct.</BOLD> We may review your conduct for compliance with these Terms and
          our Code of Conduct However, we aren’t responsible for your compliance with these Terms or the Code of Conduct, or any
          other party’s compliance with these Terms or the Code of Conduct.
        </LI1>

        <LI1>
          <BOLD>Third Party Software and Linking.</BOLD> Although we may make software, hyperlinks, and other products of third-party
          companies available to you, your use of such products is subject to the respective terms and conditions imposed by the third
          party owning, manufacturing or distributing such products, and the agreement for your use will be between you and such third
          party. The Company makes no warranty with regard to the products or websites of any other entity. The Company has no control
          over the content or availability of any third-party software or website. In particular, (a) the Company makes no warranty
          that any third-party software you download or web site you visit will be free of any contaminating or destructive code,
          such as viruses, worms or Trojan horses and (b) it is your responsibility to become familiar with any web site&apos;s privacy
          and other policies and terms of service, and to contact that site&apos;s webmaster or site administrator with any concerns.
        </LI1>


        <LI1>
          <BOLD>Suspension and Termination.</BOLD> The Company reserves the right to suspend, modify, or terminate your access to and use
           of the Site and Services, at any time, without notice. Further, you agree that the Company shall not be liable to you or any
           third party for any termination, modification, or suspension of your access to the Site or Services.
        </LI1>

        <LI1>
          <BOLD>Disclaimers.</BOLD>
          <P>THE SITE AND ALL SERVICES AVAILABLE THROUGH THE SITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND WITHOUT WARRANTIES
            OF ANY KIND. THE COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO THE SITE, INCLUDING,
            BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. </P>

          <P>THE COMPANY DOES NOT GUARANTEE THAT THE SITE OR THE SERVICES WILL BE SECURE, UNINTERRUPTED AND OPERATE WITHOUT ERRORS OR
            THAT THE SITE AND THE SERVICES ARE FREE OF COMPUTER VIRUSES OR OTHER MALWARE. YOU AGREE THAT THE COMPANY WILL NOT BE RESPONSIBLE
            FOR ANY ECONOMIC COSTS RELATING TO YOUR USE OF THE SITE OR THE SERVICES.</P>

          <P>THE COMPANY DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE SITE AND THE SERVICES
            IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, TIMELINESS, COMPLETENESS, CURRENTNESS, OR OTHERWISE. YOU ASSUME THE ENTIRE
            COST OF ALL NECESSARY SERVICING, REPAIR, OR CORRECTION RELATING TO YOUR USE OF THE SITE, THE SERVICES AND/OR OTHER CONTENT ON THE
            PLATFORM. APPLICABLE LAW MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT FULLY APPLY TO YOU.</P>

          <P>THE COMPANY SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND THE COMPANY&apos;S REASONABLE
            CONTROL, INCLUDING, WITHOUT LIMITATION, DELAYS AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS.
            THE COMPANY IS NOT RESPONSIBLE FOR ANY DELAYS OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS.</P>
        </LI1>

        <LI1>
          <BOLD>Proprietary and Privacy Protection for Other Users&apos; Content on the Site.</BOLD> The Company hereby notifies you that all the
            information, content, image files, software and materials on the Site may be protected by U.S. and international copyright and other
            intellectual property laws and by other applicable laws, including privacy laws. You may not copy or distribute such material without the
            written consent of the Company.  In addition, information you submit through or which is otherwise connected with the live chat service on
            the Site shall be owned by the Company.
        </LI1>

        <LI1><BOLD>Copyright Infringement.</BOLD>
          <OL>
            <LI>
              <P><BOLD>Claims of Copyright Infringement.</BOLD>The Company reserves the right to remove any content that allegedly infringes another
              person&apos;s copyright. In appropriate circumstances, we will terminate the accounts of users who infringe copyright. Notices to the
              Company regarding any alleged copyright infringement should be directed to the Company via email at:</P>
              <UL style={{padding: '20px 40px', listStyle: 'none'}}>
                <li>Service Provider: Dev Garage LLC</li>
                <li>Copyright Agent: Dev Garage LLC</li>
                <li>Email: copyright@dgarage.io</li>
              </UL>
            </LI>
            <LI>
              <BOLD>Notice of Infringement.</BOLD>
              To be effective, the notification must be a written communication that includes the following:
              <ol style={{padding: '20px 40px', listStyle: 'none'}}>
                <LIA>A physical or electronic signature of the person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</LIA>
                <LIA>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</LIA>
                <LIA>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material; </LIA>
                <LIA>Information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an email address at which the complaining party may be contacted; </LIA>
                <LIA>A statement that the complaining party has a good-faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</LIA>
                <LIA>A statement that the information in the notification is accurate, and, under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</LIA>
              </ol>
            </LI>
            <LI>
              <BOLD>Takedown Notices.</BOLD> We may give you notice that we have removed or disabled access to certain material (which may include your content) by
          means of a general notice on the Service, electronic mail to your email address in our records, or by written communication sent by first-class mail
          to your physical address in our records.
            </LI>
          </OL>
        </LI1>

        <LI1>
          <BOLD>Indemnity.</BOLD> You agree to indemnify, and hold the Company, its officers, directors, employees and agents, harmless from and against any
          claims, liabilities, damages, losses, and expenses, including without limitation reasonable attorney&apos;s fees and costs, arising out of or in any way
          connected with (a) your access to or use of the Site or Services; (b) your violation of these Terms; (c) your violation of any third party right,
          including without limitation any intellectual property right or any publicity, confidentiality, property or privacy right; (d) any claim that your
          use of the Site or Services caused damage to a third party, or (e) any incorrect or fraudulent statements or information you provide in connection
          with the Services.
        </LI1>

        <LI1>
          <P><BOLD>Limitation of Liability.</BOLD> TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT FOR ANY LIABILITY FOR THE COMPANY&apos;S FRAUD,
          FRAUDULENT MISREPRESENTATION, OR GROSS NEGLIGENCE, IN NO EVENT WILL THE COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES AGENTS, AFFILIATES,
           SUPPLIERS OR DISTRIBUTORS BE LIABLE FOR: (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR (B)
           ANY LOSS OF USE, DATA, BUSINESS, OR PROFITS, REGARDLESS OF LEGAL THEORY.</P>

          <P>THIS LIMITATION APPLIES REGARDLESS OF WHETHER OR NOT THE COMPANY OR ANY OF ITS OFFICERS, DIRECTORS, EMPLOYEES AGENTS, AFFILIATES,
            SUPPLIERS OR DISTRIBUTORS HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</P>

          <P>THE COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES AGENTS, AFFILIATES, SUPPLIERS AND DISTRIBUTORS WILL NOT BE LIABLE TO YOU FOR ANY
            REASON IF YOU KNOWINGLY ALLOW SOMEONE TO CREATE A PROFILE FOR YOU ON THEIR ACCOUNT.</P>

          <P>ADDITIONALLY, THE COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES AGENTS, AFFILIATES, SUPPLIERS AND DISTRIBUTORS WILL NOT BE LIABLE
            FOR AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE SERVICES FOR MORE THAN THE GREATER OF TWENTY DOLLARS ($20) OR THE AMOUNTS
            PAID BY YOU TO THE COMPANY FOR THE PAST TWELVE (12) MONTHS OF THE SERVICES IN QUESTION.</P>

          <P>Some places don&apos;t allow the types of limitations in this paragraph, so they may not apply to you.</P>

        </LI1>

        <LI1><BOLD>General.</BOLD>
          <OL>
            <LI>
              <BOLD>Modification.</BOLD> The Company may modify these Terms at any time. Modifications become effective immediately
               upon your first access to or use of the Site or Services after the “Last Revised” date at the top of these Terms.
               Your continued access to or use of the Site or Services after the modifications have become effective will be deemed
               your conclusive acceptance of the modified Terms. If you do not agree with the modifications, do not access or use the
               Site or Services.
            </LI>
            <LI>
              <BOLD>Applicable Law.</BOLD> These Terms shall be governed by the laws of the State of Colorado without giving effect
              to any conflict of laws principles that may require the application of the law of another jurisdiction.
            </LI>
            <LI>
              <P><BOLD>Dispute Resolution and Arbitration Agreement.</BOLD>  If you believe that the Company has not adhered to these Terms, please
              contact us by e-mail at privacy@dgarage.io and we will do our best to address your concerns. If you feel that your complaint has been
              addressed incompletely, we invite you to let us know for further investigation.</P>

              <P>IF YOU AND THE COMPANY ARE UNABLE TO REACH A RESOLUTION TO THE DISPUTE, YOU AND THE COMPANY AGREE THAT EITHER YOU OR THE COMPANY MAY
              ELECT TO SETTLE THE DISPUTE EXCLUSIVELY UNDER THE RULES OF THE AMERICAN ARBITRATION ASSOCIATION (WWW.ADR.ORG) AT ITS BOULDER, COLORADO
              OFFICE (THE “ARBITRATION AGREEMENT”). ANY ELECTION TO ARBITRATE BY ONE PARTY WILL BE FINAL AND BINDING ON THE OTHER. </P>

              <P>YOU UNDERSTAND THAT IF EITHER PARTY ELECTS TO ARBITRATE, NEITHER PARTY WILL HAVE THE RIGHT TO SUE IN COURT OR HAVE A JURY TRIAL.
              YOU AND THE COMPANY AGREE THAT ANY ARBITRATION WILL BE LIMITED TO THE DISPUTE BETWEEN THE COMPANY AND YOU INDIVIDUALLY. YOU ACKNOWLEDGE
              AND AGREE THAT YOU AND THE COMPANY ARE EACH WAIVING THE RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION
              OR REPRESENTATIVE PROCEEDING. FURTHER, UNLESS BOTH YOU AND THE COMPANY OTHERWISE AGREE IN WRITING, AN ARBITRATOR MAY NOT CONSOLIDATE
              MORE THAN ONE PERSON&apos;S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF ANY CLASS OR REPRESENTATIVE PROCEEDING. REGARDLESS OF ANY
              STATUTE OR LAW TO THE CONTRARY, ANY CLAIM OR CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE USE OF THE SITE OR THE SERVICES OR THESE
              TERMS MUST BE BROUGHT, IF AT ALL, WITHIN ONE YEAR FROM THE ACCRUAL OF THE CLAIM OR CAUSE OF ACTION OR BE FOREVER BARRED.</P>

              <P>You may opt out of this Arbitration Agreement.  If you do, neither you nor the Company can require the other to participate in arbitration
              proceedings.  To opt out, you must notify the Company in writing within 30 days of the date that you first became subject to this arbitration
              provision, which shall be the date you first visited the Site.  You must use this address to opt out privacy@dgarage.io.
              </P>
              <P>You must include your name and residence address, the email address you use for your account, and a clear statement that you want to opt
                out of the arbitration agreement.</P>
            </LI>
            <LI>
              <BOLD>Contact.</BOLD> Any questions, comments or suggestions, including any report of violation of these Terms should be provided to the
              Administrator via email at privacy@dgarage.io.
            </LI>
            <LI>
              <BOLD>Entire Agreement.</BOLD> These Terms, along with any other applicable agreement referenced herein, constitutes the entire
          agreement between you and the Company and govern your use of the Site and the Services, superseding any prior agreements between
          you and the Company. The failure of the Company to exercise or enforce any right or provision of these Terms shall not constitute
          a waiver of such right or provision. If any provision of these Terms is found by a court of competent jurisdiction to be invalid,
          you and the Company nevertheless agree that the court should endeavor to give effect to the parties&apos; intentions as reflected in the
          provision, and the other provisions of these Terms shall remain in full force and effect.
            </LI>
          </OL>
        </LI1>
      </ol>
    </Wrapper>
  </PageWrapper>
);
