
import {ROUTE_PATH} from '@constants/routes';
import {isWholeAssessmentComplete} from '@data/validation';
import {useAppDispatch, useAppSelector} from '@services/store/hooks';
import {clearDataAsync} from '@services/store/thunks';
import {logAnayltics} from '@services/FirebaseHelper';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {EVENTS} from 'ui/misc/Analytics';
import useModel from '@data/model/useModel';

function useHook() {
  const model = useModel();
  const {uuid, main, assess, demo, priorities, dataSources} = useAppSelector((store) => store);
  const [isCompleted, setIsCompleted] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [modal, setModal] = useState<string>('reload');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (main.isInitialized) {
      const answers = {
        demo: demo.data,
        assessment: assess.answers,
        priorities: priorities.data,
        sources: dataSources.data,
      };

      setIsCompleted(isWholeAssessmentComplete({model, answers}));
    }
  }, [main.isInitialized]);

  function openModal(modal: string) {
    setModal(modal);
    setShowDialog(true);
    logAnayltics(EVENTS.MODAL_OPEN, {modal});
  }

  const close = () => {
    setShowDialog(false);
    logAnayltics(EVENTS.MODAL_CLOSE, {modal});
  };

  const onClick = () => {
    if (isCompleted) {
      openModal('reload');
      return;
    }
    logAnayltics(EVENTS.SURVEY_STARTED);
    navigate(ROUTE_PATH.DEMO);
  };

  const onCreateNew = () => {
    setShowDialog(false);
    dispatch(clearDataAsync());
    logAnayltics(EVENTS.CREATE_NEW);
    logAnayltics(EVENTS.SURVEY_STARTED);
    navigate(ROUTE_PATH.DEMO);
  };
  const onLoadExisting = () => {
    setShowDialog(false);
    logAnayltics(EVENTS.LOAD_EXISTING);
    navigate(ROUTE_PATH.DASHBOARD);
  };

  function onShowSample() {
    openModal('sample');
  }

  return {onClick, close, open, onShowSample, onCreateNew, onLoadExisting, dialogBody: modal, showDialog};
}

export default useHook;
