import React from 'react';
import Icons from '@components/Icons';
import {COPY} from '@constants/copy';
import {COLORS} from '@constants/style';
import {AssessmentResults, IAssessmentResult} from '@data/model/interfaces';
import ResultsTile from '@views/Results/ResultsTile';
import styled from 'styled-components';
import {PeerResultBar} from '../PeerResultBar/PeerResultBar';
import {ResultBar} from '../ResultBar/ResultBar';

interface AssessmentResultsTileProps {
  assess: AssessmentResults;
  showResources?: boolean;
  liteHeader?: boolean;
}

export const AssessmentResultsTile = ({assess, liteHeader=false, showResources=true}: AssessmentResultsTileProps) => {
  return (
    <ResultsTile
      style={{gridArea: 'ASSESSMENT_RESULT'}}
      title={COPY.DASHBOARD_ASSESSMENT_TILE_TITLE}
      subtitle={liteHeader ? undefined : COPY.DASHBOARD_ASSESSMENT_TILE_SUBTITLE}
      icon={liteHeader ? undefined : <Icons.CheckedCircle />}>
      {assess !== undefined && Object.keys(assess).sort().map((key, i) => {
        const result: any = assess[key];
        return (
          <AssessmentRow key={i} data={result} showResources={showResources}/>
        );
      })}
    </ResultsTile>
  );
};

const AssessmentRow = ({data, showResources}: {data: IAssessmentResult, showResources: boolean}) => (
  <RowWrapper>
    <RowTop>
      <Title>
        {data.title}
      </Title>
      <Results>
        <ResultBar value={data.score} />
        <PeerResultBar min={data.peerLow} max={data.peerHigh} />
      </Results>
    </RowTop>
    <ResourceList>
      {showResources && data.assets.map((resource, i) => (
        <ResourceItem key={i}>
          <a target="_blank" href={resource.uri} rel="noreferrer"><Icons.Resource />{resource.title}</a>
        </ResourceItem>
      ))}
    </ResourceList>
  </RowWrapper>
);

const RowWrapper = styled.div`
  padding-bottom: 8px;
`;

const RowTop = styled.div`
  background: ${COLORS.LIGHTS.LIGHT_GREY};
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  flex: 1;
  letter-spacing: -0.02rem;  
  color: ${COLORS.PRIMARY.NAVY};
  font-size: clamp(1rem, 2vw, 1.2rem); 
  font-weight: 600;
`;

const Results = styled.div`
  --result-height: 14px;
  --result-radius: calc(var(--result-height));  
  padding: 2px 10px;  
  flex: 1;
`;

const ResourceList = styled.ul`
  margin: 10px;
  padding: 0px;
  list-style: none;
  color: ${COLORS.DARKS.CHARCOAL};
  font-size: 0.8rem;
`;

const ResourceItem = styled.li`
  padding: 2px 0;
  a{
    font-size: 0.8rem;
    text-decoration: none;
    color: ${COLORS.DARKS.CHARCOAL};
    font-weight: 400;
    display: flex;
    gap: 8px;
    &:hover{
      text-decoration: underline;
    }
  }
`;
