import React from 'react';

import styled from 'styled-components';
import {COLORS} from '@constants/style';

interface ResultBarProps {
  value?: number;
}

export const ResultBar = ({value}: ResultBarProps) => {
  if (typeof value != 'number') return <></>;

  return (
    <Wrapper>
      <Inner value={value}>
        <TabWrapper>
          <Label>{Math.round(value/10)}</Label>
          <Tab>
            <Triangle />
          </Tab>
        </TabWrapper>
      </Inner>
    </Wrapper>
  );
};

const Wrapper = styled.div`        
  height: 10px;
  width: 100%;    
  margin-top: 30px;
  border-radius: 5px;
  background-color: ${COLORS.WHITE};      
`;

const Inner = styled.div<ResultBarProps>`    
  height: 100%;
  border-radius: 5px;
  background-color:  ${COLORS.PRIMARY.BLUE};  
  width: ${({value}) => value+`%`};
`;

const TabWrapper = styled.div`
  --size: 24px;
  height: var(--size);
  width: var(--size);
  position: relative;  
  left: calc(100% - var(--size)/2);
  top: calc((-1 * var(--size)) - 10px);
  color: ${COLORS.WHITE};
`;

const Tab = styled.div`
  height: var(--size);
  width: var(--size);
  border-radius: 4px;
  background-color:  ${COLORS.PRIMARY.BLUE};
  display: grid;
  place-content: center;
  position: absolute;
`;

const Label = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 0.8rem;
  margin-top: 6px;
  font-size: 0.8rem;
  font-weight: 600;
  z-index: 1;
`;

const Triangle = styled.div`  
  background-color:  ${COLORS.PRIMARY.BLUE};
  height: calc(var(--size)/2);
  width: calc(var(--size)/2);
  position: relative;
  top: 140%;  
  clip-path: polygon(25% 0, 50% 50%, 75% 0);
`;
