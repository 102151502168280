import React from 'react';

import {VERSION} from '@constants/config';

import styled from 'styled-components';
import {NoBreak} from 'ui/misc/SharedWrappers';
import {FONTS, WIDTHS, QUERIES, COLORS} from '@constants/style';

export const Footer = () => {
  return (
    <Wrapper>
      <TopWrapper>
        <InnerWrapper>
          <ContentWrapper>
            <li>
              <a href="https://www.gainsight.com" target="_blank" rel="noreferrer">
                <Strong>© 2022 Gainsight, The Customer Success Company. All rights reserved.</Strong>
              </a>
            </li>
            <li>350 Bay Street, Suite 100, San Francisco, CA 94133</li>
            <li>
              <NoBreak>+1 (888) 623-8562</NoBreak>
            </li>
          </ContentWrapper>
        </InnerWrapper>
      </TopWrapper>
      <AppFooter>
        Value Plan v{VERSION}x by <a href="https://www.valueplan.io" target="_blank" rel="noreferrer">www.valueplan.io</a>
      </AppFooter>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  width: 100%;
`;

const TopWrapper = styled.div`  
  padding: 32px 20px;
  @media ${QUERIES.TABLET} {   
    padding: 32px 8vw;
  } 
  background: #153d5e !important;    
  display: flex;
`;

const InnerWrapper = styled.div`  
  margin: auto;
  
  @media ${QUERIES.TABLET} {    
    margin-left: 0;     
  }
`;

const ContentWrapper = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  max-width: ${WIDTHS.BASE};
  margin: 0 auto;  
  padding: 0;  
  color: ${COLORS.WHITE};
  font-size: ${FONTS.FLEX_SMALL};
  line-height: 2;
  @media ${QUERIES.TABLET} {    
    flex-direction: column;    
  }
  @media (min-width: 993px) {
    li{
      padding-right: 10px;
      &::after{
        content: "|";
        padding-left: 10px;
        color: ${COLORS.PRIMARY.BLUE};
      }
      &:last-child::after{
        content: none;    
      }
    } 
  }
  a{
    font-size: 0.8rem;
    line-height: 2;
    color: ${COLORS.WHITE};
    text-decoration: none;
    &:hover{
      text-decoration: underline;
    }
  }
`;


const Strong = styled.span`
  font-weight: 700;
  font-size: ${FONTS.FLEX_SMALL}};
  a {
    font-weight: 700;
  }
`;


const AppFooter = styled.div`
  height: 60px;  
  color: ${COLORS.DARKS.CHARCOAL};
  font-size: 0.8rem;
  display: flex;
  padding: 20px;
  justify-content: center;
  a{
    font-size: 0.8rem;    
    color: ${COLORS.DARKS.CHARCOAL};
    text-decoration: none;
    left: 4px;
    position: relative;
  }
  
`;
