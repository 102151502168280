import {createContext, useEffect, useState} from 'react';
import {arrayMoveImmutable} from 'array-move';
import {DropResult} from 'react-beautiful-dnd';
import {useAppDispatch, useAppSelector} from '@services/store/hooks';
import {useNavigate} from 'react-router-dom';

import {IPriority} from '@data/model/interfaces';
import {update} from '@services/store/slices/prioritiesSlice';
import {postAssessmentAsync} from '@services/store/thunks';
import {ROUTE_PATH} from '@constants/routes';
import {logAnayltics} from '@services/FirebaseHelper';
import {EVENTS} from 'ui/misc/Analytics';
import useModel from '@data/model/useModel';

export function useHook() {
  const model = useModel();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [startList, setStartList] = useState([] as IPriority[]);
  const [endList, setEndList] = useState([] as IPriority[]);
  const priorities = useAppSelector((store) => store.priorities.data);

  useEffect(() => {
    if (priorities.length === model.prioritesAsList().length) {
      setEndList(model.generatePrioritiesFromKeys(priorities));
      setStartList([]);
      return;
    }
    setStartList(model.prioritesAsList());
  }, [priorities]);

  useEffect(() => {
    setIsDisabled(startList.length !== 0);
  }, [startList, endList]);

  function backClick() {
    navigate(ROUTE_PATH.ASSESS);
  }

  function onDragEnd(result: DropResult) {
    const destination = result.destination;
    if (!destination) return;

    const sourceId = result.source.droppableId;
    const destinationId = destination.droppableId;

    const item = model.prioritesAsList().filter((i) => i.key === result.draggableId)[0];

    // shuffle within start
    if (sourceId === 'start' && destinationId === 'start') {
      shuffleWithinList(setStartList);
    }

    // shuffle within end
    if (sourceId === 'end' && destinationId === 'end') {
      shuffleWithinList(setEndList);
    }

    // move between start and drop
    if (sourceId === 'start' && destinationId === 'end') {
      removeFromList(setStartList);

      // add to end list
      addToList(setEndList);
    }

    // move between start and drop
    if (sourceId === 'end' && destinationId === 'start') {
      removeFromList(setEndList);

      // add to end list
      addToList(setStartList);
    }

    function shuffleWithinList(setList: any) {
      if (!destination) return;
      setList((list: any) => {
        return arrayMoveImmutable(list, result.source.index, destination.index);
      });
    }

    function removeFromList(setList: any) {
      setList((list: any) => {
        return list.filter((i: any) => i.key !== item.key);
      });
    }

    function addToList(setList: any) {
      if (!destination) return;
      setList((list: any) => {
        if (!list.includes(item)) {
          list.splice(destination?.index, 0, item);
        }
        return [...list];
      });
    }
  }

  function nextClick() {
    setIsProcessing(true);

    const saveList = endList.map((item) => item.key);

    dispatch(update(saveList));

    dispatch(postAssessmentAsync());

    logAnayltics(EVENTS.SURVEY_COMPLETED);

    setTimeout(() => navigate(ROUTE_PATH.DASHBOARD), 8000);
  }

  return {
    isDisabled,
    isProcessing,
    startList,
    endList,
    onDragEnd,
    backClick,
    nextClick,
  };
}

export interface IPrioritiesContext {
  startList: IPriority[];
  endList: IPriority[];
  onDragEnd: (result: DropResult) => void;
}

export const defaultContext: IPrioritiesContext = {
  startList: [],
  endList: [],
  onDragEnd: () => {},
};

export const PrioritiesContext = createContext<IPrioritiesContext>(defaultContext);
