
import {ICompletedAssessmentDisplay, IDemographics, IResultRecord, ISummaryContentModel} from '@data/model/interfaces';
import {getModel} from '@data/model/model';
import {fetchReportByUUID} from '@services/DBServices';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

export default function useHook() {
  const {id} = useParams();
  const [isNotFound, setIsNotFound] = useState<boolean>(false);
  const [userDataModel, setUserDataModel] = useState<ICompletedAssessmentDisplay | null>(null);
  const [summaryModel, setSummaryModel] = useState<ISummaryContentModel>();

  useEffect(() => {
    (async () => {
      if (id) {
        const tmp: IResultRecord[] = await fetchReportByUUID(id);

        if (tmp.length === 0) {
          setIsNotFound(true);
          return;
        }
        const result = tmp[0];

        const model = getModel(result.version_client || null);

        console.log({model});

        const demo: IDemographics = {
          firstName: result.firstName,
          lastName: result.lastName,
          title: result.title,
          email: result.email,
          companyName: result.companyName,
          consent: result.consent,
          industry: result.industry,
          number_of_csms: result.number_of_csms,
          number_of_employees: result.number_of_employees,
          teams_using_gainsight: result.teams_using_gainsight,
          users_of_gainsight: result.users_of_gainsight,
        };

        result.assessment;

        setUserDataModel({
          demo,
          assessment: model.computeAssessmentResults(result.assessment),
          priorities: model.computePriorityResults(result.priorities),
          sources: result.sources,
          createdAt: result.createdAt,
        });

        const localModel = model.createSummaryModel({
          priorities: result.priorities,
          assessment: result.assessment,
          demo: demo,
          sources: result.sources,
        });
        setSummaryModel(localModel);
      }
    })();
  }, []);

  return {userDataModel, summaryModel, isNotFound, id};
}
