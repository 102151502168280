import {COLORS} from '@constants/style';
import React from 'react';
import {ClipLoader} from 'react-spinners';
import styled from 'styled-components';

export const Loading = ({color=COLORS.PRIMARY.BLUE, size='60px'}: {color?: string, size?: string}) => {
  return (
    <Wrapper>
      <ClipLoader color={color} size={size} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
`;
