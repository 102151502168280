import Button from '@components/Button';
import {COPY} from '@constants/copy';
import {QUERIES, WIDTHS} from '@constants/style';
import {useSetPageTitle} from '@utils/hooks';
import DataSourceResultsTile from '@views/Results/DataSourceResultsTile';
import {ResultsContext} from '@views/Results/useHook.Results';
import React, {useContext} from 'react';
import styled from 'styled-components';
import DemographicsTile from './DemographicsTile';
import GoBack from './GoBack';
import {useHook} from './useHook.Profile';


export const Profile = () => {
  useSetPageTitle('Profile');
  const {clickDashboard} = useHook();
  const {userDataModel, isSecure} = useContext(ResultsContext);

  return (
    <Wrapper>
      <Head>
        <h1>{COPY.PROFILE_TITLE}</h1>
        <Button onClick={clickDashboard}>{COPY.PROFILE_DASHBOARD_CTA}</Button>
      </Head>

      <DemographicsTile demo={userDataModel.demo}/>

      <Bottom>
        <DataSourceResultsTile
          title={COPY.PROFILE_DATASOURCE_TILE_TITLE}
          dataSources={userDataModel.sources}
        />
        {!isSecure && <GoBack />}
      </Bottom>
    </Wrapper>
  );
};


const Wrapper = styled.div`
    padding: 0px 20px 80px;
    display: flex;
    flex-direction: column;
    gap: 10px;    
    max-width: ${WIDTHS.BASE};
    margin: 0 auto;
`;


const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 10px;
`;

const Bottom = styled.div`  
  display: grid;
  grid-template-columns: 50% 50%;
  @media ${QUERIES.TABLET} {  
    grid-template-columns: 100%;
  }
`;
