import {createContext, useEffect, useState} from 'react';
import {DropResult} from 'react-beautiful-dnd';

import {update} from '@services/store/slices/dataSourcesSlice';
import {THIRD_PARTY_SOURCES} from '@data/dataSources';
import {arrayMoveImmutable} from 'array-move';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from '@services/store/hooks';
import {ROUTE_PATH} from '@constants/routes';
import {IDataSourceItem} from '@data/model/interfaces';

export function useHook() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selected, setSelected] = useState<IDataSourceItem[]>([]);
  const [isNextEnabled, setIsNextEnabled] = useState(false);

  const data = useAppSelector((store) => store.dataSources.data);

  useEffect(() =>{
    setIsNextEnabled(selected.length === 0);
  }, [selected]);

  useEffect(() =>{
    setSelected(data);
  }, [data]);

  function onDragEnd(result: DropResult) {
    const sourceId = result.source.droppableId;
    const destination = result.destination;
    const destinationId = result.destination?.droppableId;

    if (!destination) return;

    // shuffle w/in priorities
    if (destinationId === 'priorities' && sourceId === 'priorities') {
      console.log('reorder...');
      setSelected((prev) => {
        return arrayMoveImmutable(prev, Number(result.source.index), Number(result.destination?.index));
      });
      return;
    }

    // move from sources to priorites
    if (sourceId !== 'priorities' && destinationId === 'priorities') {
      if (!selected.map((s) => s.id).includes(result.draggableId) && selected.length < 10) {
        setSelected((prev) => {
          const temp = [...prev];
          temp.splice(destination.index, 0, getItem(result.draggableId));
          return temp;
        });
      }
    }
  }

  function remove(id: string) {
    setSelected((prev) => {
      return prev.filter((i) => i.id !== id);
    });
  }

  const nextClick = () => {
    navigate(ROUTE_PATH.ASSESS);
    dispatch(update(selected));
  };
  return {selected, isNextEnabled, remove, onDragEnd, nextClick};
}

function getItem(id: string) {
  return Object.values(THIRD_PARTY_SOURCES).filter((i) => i.id === id)[0];
}

export interface IDataSourcesContext {
  selected: {id: string, label: string}[];
  isNextEnabled: boolean;
  remove: (id: string) => void;
  onDragEnd: (result: DropResult) => void;
}

export const defaultContext: IDataSourcesContext = {
  selected: [],
  isNextEnabled: false,
  remove: (id: string) => {},
  onDragEnd: () => {},
};

export const DataSourcesContext = createContext<IDataSourcesContext>(defaultContext);
