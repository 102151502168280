import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';

import {COLORS, QUERIES} from '@constants/style';
import {SHADOWS} from '@constants/style';
import {WithStyleProps} from '@data/model/interfaces';


interface ResultsTileProps extends WithStyleProps {
  icon?: any,
  cta?: any,
  title: string,
  subtitle?: string,
  style?: any,
};

export const ResultsTile = ({title, subtitle, icon, cta, children, ...props}: PropsWithChildren<ResultsTileProps>) => (
  <Wrapper {...props}>
    <Header>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <HeaderBody>
        {subtitle && <p>{subtitle}</p>}
        <h3>{title}</h3>
      </HeaderBody>
      {cta}
    </Header>
    <Body>
      {children}
    </Body>
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 8px;
  margin: 2px;  
  border: 1px solid ${COLORS.LIGHTS.GREY2};  
  box-shadow: ${SHADOWS.SECONDARY};
  margin: 0px;
  width: 100%;
`;

const IconWrapper = styled.div`
  @media (max-width: 460px) {
    display: none;
  } 
`;

const Header = styled.div`  
  border-bottom: 1px solid ${COLORS.LIGHTS.GREY2};  
  display: flex;  
  gap: 14px;
  padding: 20px;
  @media ${QUERIES.MOBILE}{
    gap: 6px;
    padding: 14px;
  }
  p{
    font-size: clamp(.7rem, 1.8vw, 1rem); 
    line-height: 1.2rem;
    color: ${COLORS.PRIMARY.BLUE};     
  }
  h3{
    font-size: clamp(1rem, 2.4vw, 2rem); 
    line-height: 1.8rem;    
    font-weight: 800;
    color: ${COLORS.PRIMARY.NAVY};
  }
`;
const HeaderBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Body = styled.div`
  padding: 10px 20px 20px;
  @media ${QUERIES.MOBILE}{    
    padding: 10px 10px 10px;
  }
`;
