import React, {useContext} from 'react';
import {LargeHeroHeader} from '@components/HeroHeader';
import {COPY} from '@constants/copy';
import {QUERIES} from '@constants/style';
import {useSetPageTitle} from '@utils/hooks';
import styled from 'styled-components';
import {AssessmentResultsTile} from '../AssessmentResultsTile/AssessmentResultsTile';
import {JourneySummaryTile} from '../JourneySummaryTile/JourneySummaryTile';
import {PrioritiesResultTile} from '../PrioritiesResultTile/PrioritiesResultTile';
import {ResultsContext} from '../useHook.Results';


export const Dashboard = () => {
  useSetPageTitle(COPY.DASHBOARD_TITLE);

  const {onModalOpen, userDataModel: model, isSecure, summaryModel} = useContext(ResultsContext);
  if (!model) return <></>;
  return (
    <Grid>
      <LargeHeroHeader
        ctaClick={() => onModalOpen()}
        ctaCopy={COPY.CTA_CTA}
        heroTitle={COPY.parse(COPY.DASHBOARD_HERO_TITLE, model.demo)}
        heroSubtitle={COPY.DASHBOARD_HERO_SUBTITLE}
        heroCopy={COPY.DASHBOARD_HERO_COPY}
        pageTitle={COPY.DASHBOARD_TITLE}
        pageSubtitle={model.demo.companyName || ''}
        isDisabled={false}
        image={COPY.DASHBOARD_HERO_IMG}
        isSecure={isSecure}
      />

      {summaryModel && <JourneySummaryTile /> }

      <AssessmentResultsTile assess={model.assessment} />
      <PrioritiesResultTile priorities={model.priorities}/>
    </Grid>
  );
};


const Grid = styled.div`      
  /* width: 100%; */
  display: grid;
  gap: 10px;
  margin-right: 10px;
  grid-template-columns: 50% 50%;
  grid-template-areas: 
  'PAGE_HEAD PAGE_HEAD'
  'JOURNEY_SUMMARY JOURNEY_SUMMARY' 
  'ASSESSMENT_RESULT BUSINESS_PRIORITIES';  

  @media ${QUERIES.TABLET} {    
    grid-template-columns: 100%;
    grid-template-areas:
      'PAGE_HEAD'
      'JOURNEY_SUMMARY' 
      'ASSESSMENT_RESULT'
      'BUSINESS_PRIORITIES';
  }
`;
