
import {useState, useEffect, createContext} from 'react';
import {useAppDispatch, useAppSelector} from '@services/store/hooks';
import {useNavigate} from 'react-router-dom';

import {update} from '@services/store/slices/assessSlice';
import {DefaultAssessmentAnwsers} from '@data/model/defaults';
import {ROUTE_PATH} from '@constants/routes';
import {AssessmentAnswers, IAssessment} from '@data/model/interfaces';
import useModel from '@data/model/useModel';

export const useHook = () => {
  const model = useModel();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const answers = useAppSelector((store) => store.assess.answers);
  const [results, setResults] = useState<AssessmentAnswers>(answers);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() =>{
    const sum = Object.values(results).reduce((a, v) => a+=v, 0);
    setIsDisabled(sum === 0);
  }, [results]);

  useEffect(() => {
    setResults(answers);
  }, [answers]);

  const updateResult = (assessmentKey: string, value: number) => {
    setResults((prev) => {
      const old = {...prev};
      old[assessmentKey] = value;
      return old;
    });
  };

  const backClick = () => {
    navigate(ROUTE_PATH.SOURCES);
  };

  const nextClick = () => {
    navigate(ROUTE_PATH.PRIORITIES);
    dispatch(update(results));
  };

  return {
    questions: model.assessmentsAsList(),
    results,
    updateResult,
    backClick,
    nextClick,
    isDisabled,
  };
};

export interface IAssessContext {
  results: AssessmentAnswers;
  questions: IAssessment[];
  updateResult: (assessmentKey: string, value: number) => void;
}

export const defaultContext: IAssessContext = {
  results: DefaultAssessmentAnwsers,
  questions: [],
  updateResult: (assessmentKey: string, value: number) => {},
};

export const AssessContext = createContext<IAssessContext>(defaultContext);
