import React from 'react';
import styled from 'styled-components';

import Button from '@components/Button';
import {SurveyPageWrapper} from '@components/SurveyPageWrapper';
import {COPY} from '@constants/copy';
import {COLORS, QUERIES, WIDTHS} from '@constants/style';
import {useSetPageTitle} from '@utils/hooks';
import DimensionList from '@views/Assess/DimensionList';

import {AssessContext, useHook} from './useHook.Assess';


export const Assess = () => {
  useSetPageTitle('Assess');

  const {questions, results, updateResult, nextClick} = useHook();

  return (
    <AssessContext.Provider value={{questions, results, updateResult}}>
      <SurveyPageWrapper
        title={COPY.ASSESS_PAGE_TITLE}
        subtitle={COPY.ASSESS_PAGE_SUBTITLE}
        copy={COPY.ASSESS_PAGE_COPY}
        color={COLORS.PRIMARY.BLUE}
        nextButton={<Button onClick={nextClick}>{COPY.ASSESS_SUBMIT}</Button>}
      >

        <DimensionList />

      </SurveyPageWrapper>
    </AssessContext.Provider>
  );
};

const Wrapper = styled.div`  
  padding: 20px 40px 40px;  
  @media ${QUERIES.TABLET} {    
    padding: 10px;
  }  
  display: flex;
  flex-direction: column;
  gap: 40px;
  max-width: ${WIDTHS.BASE};
  margin: auto;
`;

const Bottom = styled.div`  
  display: grid;
  place-content: center;
`;
