import {ROUTE_TOKEN} from '@constants/routes';
import {useEffect} from 'react';

import {useNavigate} from 'react-router-dom';

export function useHook() {
  const navigate = useNavigate();

  function clickDashboard() {
    // this works while clicking from either profile page (sales & prospect view)
    const loc = window.location.pathname.replace('profile', '') + ROUTE_TOKEN.DASHBOARD;
    navigate(loc);
  }

  return {clickDashboard};
}
