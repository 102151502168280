import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {auth} from '@services/FirebaseHelper';
import {onAuthStateChanged} from '@services/AuthHelper';
import {ORIGINAL_DESTINATION} from '@constants/config';
import {ROUTE_PATH} from '@constants/routes';
import {isDefined} from '@utils/misc';


export default function useHook() {
  const [isLoggedin, setIsLoggedIn] = useState<boolean|null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (isDefined(user)) {
        setIsLoggedIn(true);

        const od = window.localStorage.getItem(ORIGINAL_DESTINATION);
        if (od) {
          console.log('navigate to: ', `${ROUTE_PATH.SECURE_PLAN}/${od}`);
          navigate(`${ROUTE_PATH.SECURE_PLAN}/${od}`);
          setTimeout(() => window.localStorage.removeItem(ORIGINAL_DESTINATION), 5000);
        }
      } else {
        setIsLoggedIn(false);
        console.log('User is not logged in...');
      }
    });
  }, []);

  return {isLoggedin};
}
