import {createContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DefaultDemographics} from '@data/model/defaults';
import {ICompletedAssessmentDisplay, ISummaryContentModel} from '@data/model/interfaces';
import SummaryModel from '@data/model/summaryModel';
import {isWholeAssessmentComplete} from '@data/validation';
import {useAppSelector} from '@services/store/hooks';
import {getModel} from '@data/model/model';
import useModel from '@data/model/useModel';


export default function useHook(): IResultsProps {
  const model = useModel();
  const {uuid, main, assess, demo, priorities, dataSources} = useAppSelector((store) => store);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isCompleted, setIsCompleted] = useState<Boolean|null>(null);
  const [summaryModel, setSummaryModel] = useState<ISummaryContentModel>();
  const navigate = useNavigate();

  useEffect(() => {
    if (main.isInitialized) {
      const answers = {
        demo: demo.data,
        assessment: assess.answers,
        priorities: priorities.data,
        sources: dataSources.data,
      };

      setIsCompleted(isWholeAssessmentComplete({model, answers}));

      const summaryModel = model.createSummaryModel(answers);
      setSummaryModel(summaryModel);
    }
  }, [main.isInitialized]);

  // useEffect(() => {
  //   const localModel = model.createSummaryModel({
  //     priorities: priorities.data,
  //     assessment: assess.answers,
  //     demo: demo.data,
  //     sources: dataSources.data,
  //   });

  //   setSummaryModel(localModel);
  // }, [priorities.data, demo.data, assess.answers, dataSources.data]);

  function updateNavigation(location: string): void {
    navigate(location);
  }

  function onModalClose() {
    setIsShowModal(false);
  }

  function onModalOpen() {
    setIsShowModal(true);
  }

  return {
    userDataModel: {
      priorities: model.computePriorityResults(priorities.data),
      assessment: model.computeAssessmentResults(assess.answers),
      demo: demo.data,
      sources: dataSources.data,
      createdAt: undefined,
    },
    summaryModel,
    isCompleted,
    isInitialized: main.isInitialized,
    isShowModal,
    onModalClose,
    onModalOpen,
    isSecure: false,
    updateNavigation};
}

export interface IResultsProps{
  isSecure: boolean,
  userDataModel: ICompletedAssessmentDisplay,
  summaryModel: ISummaryContentModel | undefined,
  updateNavigation: (location: string) => void,
  onModalOpen: () => void,
  onModalClose: () => void,
  isInitialized: Boolean;
  isCompleted: Boolean | null;
  isShowModal: Boolean;
}

const DefaultAssessmentResults = {
};

export const defaultContext: IResultsProps = {
  userDataModel: {
    demo: DefaultDemographics,
    assessment: DefaultAssessmentResults,
    priorities: [],
    sources: [],
    createdAt: undefined,
  },
  summaryModel: new SummaryModel().model,
  updateNavigation: (location) => {/* no op */},
  onModalOpen: () => {/* no op */},
  onModalClose: () => {/* no op */},
  isCompleted: null,
  isInitialized: false,
  isShowModal: false,
  isSecure: false,
};

export const ResultsContext = createContext<IResultsProps>(defaultContext);
