import React, {useEffect} from 'react';

import {ROUTE_PATH, ROUTE_TOKEN} from '@constants/routes';
import NotFound from '@views/NotFound';
import {Navigate, Route, Routes, useNavigate, useParams} from 'react-router-dom';
import useHook from './useHook.Secure';
import Login from './Login/';
import Auth from './Auth';
import {ORIGINAL_DESTINATION} from '@constants/config';
import {isDefined} from '@utils/misc';
import Loading from '@components/Loading';
import SecurePlan from './SalesView';


export const Secure = () => {
  const {isLoggedin} = useHook();

  if (!isDefined(isLoggedin)) return <Loading />;

  return (

    <Routes>
      {/* only show these routes if user is loggedin */}
      {isLoggedin && (
        <>
          {/* <Route path={`${ROUTE_TOKEN.PLAN}/:id`} element={<SecurePlan />}/> */}
          <Route path={`plan/:id/*`} element={<SecurePlan />}/>
          <Route path={'*'} element={<NotFound />} />
        </>
      )}
      {!isLoggedin && (
        <>
          <Route index element={<Navigate replace to={ROUTE_PATH.LOGIN} />} />
          <Route path={ROUTE_TOKEN.LOGIN} element={<Login />}/>
          <Route path={ROUTE_TOKEN.AUTH} element={<Auth />}/>
          <Route path={`${ROUTE_TOKEN.PLAN}/:id`} element={<NotLoggedIn />}/>
          <Route path={'*'} element={<NotFound />} />
        </>
      )}

    </Routes>


  );
};

/**
 * NON-UI component stores the ORignal destination in local storage
 * @return {null}
 */
const NotLoggedIn = () =>{
  const navigate = useNavigate();
  const {id} = useParams();
  useEffect(() => {
    if (id && id.length > 0 ) {
      window.localStorage.setItem(ORIGINAL_DESTINATION, id);
    }
    navigate(ROUTE_PATH.LOGIN);
  }, []);
  return null;
};
