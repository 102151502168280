import {useAppDispatch} from '@services/store/hooks';
import {postDemoAsync} from '@services/store/thunks';
import {useContext, useState} from 'react';
import {ResultsContext} from '../useHook.Results';

export function useHook() {
  const [phone, setPhone] = useState('');
  const [isError, setIsError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {onModalClose} = useContext(ResultsContext);
  const dispatch = useAppDispatch();

  const onChange = (value: string) => {
    setPhone(value);
    const isValid = value.replace(/\D/g, '').length < 11;
    setIsDisabled(isValid);
  };

  const submit = () => {
    dispatch(postDemoAsync({phone}));
    setIsSubmitted(true);
  };

  return {
    phone,
    onChange,
    setIsError,
    submit,
    isSubmitted,
    onModalClose,
    isDisabled,
  };
}
