
import React from 'react';
import styled from 'styled-components';

import {
  EMPLOYEE_SIZE,
  INDUSTRIES,
  CUSTOMER_SUCCESS_EMPLOYEES,
  EMPLOYEES_USING_GAINSIGHT,
  TEAMS_USING_GAINSIGHT,
  JOB_TITLES,
} from '@data/demographicConstants';

import useHook from './useHook.DemographicsForm';
import {COPY} from '@constants/copy';
import {DEMO_FIELDS, DEMO_FIELDS_LABELS} from '@constants/fields';
import {IDemographics} from '@data/model/interfaces';

import FormCell from '@components/FormCell';
import {QUERIES, COLORS} from '@constants/style';
import SelectTeams from '@components/SelectTeams';
import {ErrorItem} from '@data/model/defaults';

export interface DemoFormProps {
  onCompleted: any;
  data: IDemographics;
}

export const DemographicsForm = (props: DemoFormProps) => {
  const {demo, update, onBlurTextInput, onBlurEmailInput, onBlurSelect, handleMultiSelect, errors} = useHook(props);

  return (
    <Wrapper>
      <DemoFormGrid>
        <FormCell field={DEMO_FIELDS.FIRST_NAME}>
          <FormInput
            placeholder={DEMO_FIELDS_LABELS[DEMO_FIELDS.FIRST_NAME]}
            type='text'
            value={demo.firstName || ''}
            onChange={(e) => update('firstName', e.target.value)}
            onBlur={onBlurTextInput('firstName')}
            isError={errors.firstName}
          />
        </FormCell>

        <FormCell field={DEMO_FIELDS.LAST_NAME} >
          <FormInput
            type='text'
            placeholder={DEMO_FIELDS_LABELS[DEMO_FIELDS.LAST_NAME]}
            value={demo.lastName || ''}
            onChange={(e) => update('lastName', e.target.value)}
            onBlur={onBlurTextInput('lastName')}
            isError={errors.lastName}
          />
        </FormCell>

        <FormCell field={DEMO_FIELDS.EMAIL} error={errors.email}>
          <FormInput
            placeholder={DEMO_FIELDS_LABELS[DEMO_FIELDS.EMAIL]}
            value={demo.email || ''}
            onChange={(e) => update('email', e.target.value)}
            onBlur={onBlurEmailInput('email')}
            type='email'
            isError={errors.email}
          />
        </FormCell>


        <FormCell field={DEMO_FIELDS.COMPANY_NAME}>
          <FormInput
            type='text'
            placeholder={DEMO_FIELDS_LABELS[DEMO_FIELDS.COMPANY_NAME]}
            value={demo.companyName || ''}
            onChange={(e) => update('companyName', e.target.value)}
            onBlur={onBlurTextInput('companyName')}
            isError={errors.companyName}
          />
        </FormCell>

        <FormCell field={DEMO_FIELDS.TITLE}>
          <SelectWrapper>
            <SelectInput
              value={demo.title || ''}
              onChange={(e) => update('title', e.target.value)}
              onBlur={onBlurSelect('title')}
              isError={errors.title}>
              <Option value='Select One'>{COPY.DEMO_DEFAULT_SELECT_VALUE}</Option>
              {JOB_TITLES.map((title, i) => (
                <Option value={title} key={i}>{title}</Option>
              ))}
            </SelectInput>
          </SelectWrapper>
        </FormCell>


        <FormCell field={DEMO_FIELDS.TOTAL_EMPLOYEES}>
          <SelectWrapper>
            <SelectInput
              value={demo.number_of_employees || ''}
              onChange={(e) => update('number_of_employees', e.target.value)}
              onBlur={onBlurSelect('number_of_employees')}
              isError={errors.number_of_employees}>
              <Option value='Select One'>{COPY.DEMO_DEFAULT_SELECT_VALUE}</Option>
              {EMPLOYEE_SIZE.map((size, i) => (
                <Option value={size} key={i}>{size}</Option>
              ))}
            </SelectInput>
          </SelectWrapper>
        </FormCell>

        <FormCell field={DEMO_FIELDS.CUSTOMER_SUCCESS_EMPLOYEES}>
          <SelectWrapper>
            <SelectInput
              value={demo.number_of_csms || ''}
              onChange={(e) => update('number_of_csms', e.target.value)}
              onBlur={onBlurSelect('number_of_csms')}
              isError={errors.number_of_csms}>
              <Option value='Select One'>{COPY.DEMO_DEFAULT_SELECT_VALUE}</Option>
              {CUSTOMER_SUCCESS_EMPLOYEES.map((size, i) => (
                <Option value={size} key={i}>{size}</Option>
              ))}
            </SelectInput>
          </SelectWrapper>
        </FormCell>

        <FormCell field={DEMO_FIELDS.INDUSTRY}>
          <SelectWrapper>
            <SelectInput
              value={demo.industry || ''}
              onChange={(e) => update('industry', e.target.value)}
              onBlur={onBlurSelect('industry')}
              isError={errors.industry}>
              <Option value='Select One'>{COPY.DEMO_DEFAULT_SELECT_VALUE}</Option>
              {INDUSTRIES.map((industry, i) => (
                <Option value={industry} key={i}>{industry}</Option>
              ))}
            </SelectInput>
          </SelectWrapper>
        </FormCell>

        <FormCell field={DEMO_FIELDS.TEAMS_USE_GAINSIGHT}>
          {/* <MultiSelect
            multiple
            value={demo.teams_using_gainsight || []}
            onChange={handleMultiSelect('teams_using_gainsight')}
            onBlur={onBlurSelect('teams_using_gainsight')}
            isError={errors.teams_using_gainsight}>
            {TEAMS_USING_GAINSIGHT.map((team, i) => (
              <Option value={team} key={i}>{team}</Option>
            ))}
          </MultiSelect> */}
          <SelectTeams
            options={TEAMS_USING_GAINSIGHT}
            selected={demo.teams_using_gainsight || []}
            isError={errors.teams_using_gainsight}
            onChange={handleMultiSelect}
          />
        </FormCell>

        <FormCell field={DEMO_FIELDS.EMPLOYEES_USE_GAINSIGHT}>
          <SelectWrapper>
            <SelectInput
              value={demo.users_of_gainsight || ''}
              onChange={(e) => update('users_of_gainsight', e.target.value)}
              onBlur={onBlurSelect('users_of_gainsight')}
              isError={errors.users_of_gainsight}>
              <Option value='Select One'>{COPY.DEMO_DEFAULT_SELECT_VALUE}</Option>
              {EMPLOYEES_USING_GAINSIGHT.map((size, i) => (
                <Option value={size} key={i}>{size}</Option>
              ))}
            </SelectInput>
          </SelectWrapper>
        </FormCell>


      </DemoFormGrid>

      <ConsentRow>
        <ConsentLabel>
          <Checkbox
            type={'checkbox'}
            name='consent'
            checked={demo.consent === 'yes'}
            onChange={(e) => {
              update('consent', e.target.checked ? 'yes' : 'no');
            }}
          />
          {COPY.DEMO_CONSENT}
        </ConsentLabel>
      </ConsentRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-size: 0.8rem;  
  width: 100%;  
`;

export const DemoFormGrid = styled.div<{wide?: boolean}>`  
  max-width: ${({wide}) => wide ? 'revert': '960px'};
  display: grid;    
  gap: 8px;
  margin: 10px auto;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 
  "${DEMO_FIELDS.FIRST_NAME} ${DEMO_FIELDS.LAST_NAME}"
  "${DEMO_FIELDS.EMAIL} ${DEMO_FIELDS.COMPANY_NAME}"
  "${DEMO_FIELDS.TITLE} ${DEMO_FIELDS.TOTAL_EMPLOYEES}"
  "${DEMO_FIELDS.CUSTOMER_SUCCESS_EMPLOYEES} ${DEMO_FIELDS.INDUSTRY}"
  "${DEMO_FIELDS.TEAMS_USE_GAINSIGHT} ${DEMO_FIELDS.EMPLOYEES_USE_GAINSIGHT}"
  ;

  @media ${QUERIES.MOBILE} {  
    grid-template-columns: 1fr;
    grid-template-areas: 
      "${DEMO_FIELDS.FIRST_NAME}" 
      "${DEMO_FIELDS.LAST_NAME}"
      "${DEMO_FIELDS.EMAIL}" 
      "${DEMO_FIELDS.COMPANY_NAME}"
      "${DEMO_FIELDS.TITLE}" 
      "${DEMO_FIELDS.TOTAL_EMPLOYEES}"
      "${DEMO_FIELDS.CUSTOMER_SUCCESS_EMPLOYEES}" 
      "${DEMO_FIELDS.INDUSTRY}"
      "${DEMO_FIELDS.TEAMS_USE_GAINSIGHT}" 
      "${DEMO_FIELDS.EMPLOYEES_USE_GAINSIGHT}";
  }
`;


export const FormInput = styled.input<{isError: ErrorItem}>`  
  height: 50px;
  border: 1px solid ${({isError})=> isError.status? 'red' : COLORS.LIGHTS.GREY3};
  border-radius: 4px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding-left: 12px;
  color: ${COLORS.BLACK};
  &::placeholder {
    color: ${COLORS.DARKS.DARK_GREY};
  }
`;
const SelectInput = styled.select<{isError: ErrorItem}>`  
  height: 50px;
  border: 1px solid ${({isError})=> isError.status ? 'red' : COLORS.LIGHTS.GREY3};
  border-radius: 3px;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding-left: 12px;
  width: 100%;  
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
`;

const MultiSelect = styled(SelectInput)`
  height: revert;
  padding-left: 0;
  margin-left: 0;
  -webkit-appearance: none;
  border: 1px solid ${({isError})=> isError? 'red' : COLORS.LIGHTS.GREY3};  
  
  @supports (-webkit-touch-callout: none) {
    height: 50px;
    padding-left: 12px;  
    font-size: 16px;
    line-height: 24px;
  }
  option{
    padding: 6px 12px;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  &::after {
    pointer-events: none;
    font: var(--fa-font-solid);
    content: "\f0dd";
    font-size: 20px;
    color: ${COLORS.DARKS.DARK_GREY};
    position: absolute;
    top: calc(50% - 14px);
    right: 10px;
  }
`;

const ConsentRow = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: center;
`;

const ConsentLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  /* border: 1px solid red; */
`;

const Checkbox = styled.input`
  position: relative;
  width: 20px;
  height: 20px;
  @media ${QUERIES.MOBILE} {
    width: 40px;
    height: 40px;
  }
`;

const Option = styled.option``;
