
import {SEQUENCE} from '@data/model/defaults';
import {ICompletedAssessment} from '@data/model/interfaces';

import priorities from './priorities';

const version = 1;

export function getMetrics($: any) {
  const metrics = {
    GRR: {
      metric: 'GRR (Gross Renewal Rate)',
      description: 'Improve GRR with better risk resolution, managing to customer goals and disciplined renewals.',
      sequence: SEQUENCE.FOUNDATION,
    },
    RECOVERED_REVENUE: {
      metric: 'Recovered Revenue',
      description: 'Increase revenue retained from accounts that had a risk that is resolved.',
      sequence: SEQUENCE.FOUNDATION,
    },
    CSM_RATIOS: {
      metric: 'CSM Ratios',
      description: 'Should increase with fast, easy access to accurate customer data.',
      sequence: SEQUENCE.FOUNDATION,
    },
    CUSTOMER_SATISFACTION: {
      metric: 'Customer CSAT/CSM Satisfaction',
      description: 'Customers should have improved experience when CSMs are better prepared',
      sequence: SEQUENCE.FOUNDATION,
    },
    NUMBER_OF_TOOLS: {
      metric: 'Number of Tools CSMs Need Direct Access',
      description: 'CSMs should be able to complete as much of their work as possible within Gainsight with data',
      sequence: SEQUENCE.FOUNDATION,
    },
    NET_RETENTION_RATE: {
      metric: 'Net Retention Rate',
      description: 'This metric measures the total value of your renewed contracts as well as the revenue gained through upsells and cross-sells.',
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(3) &&
          $(d).isAssessment('CUSTOMER_DATA').greaterThan(66) &&
          $(d).isAssessment('CUSTOPROCESS_WORFLOWSMER_DATA').greaterThan(66) &&
          $(d).isAssessment('ORG_ALIGNMENT').greaterThan(33) &&
          $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
          $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    CS_COST_PERCENTAGE_OF_REVENUE: {
      metric: 'CS Cost Percentage of Revenue',
      description: 'The CSM cost per dollar of revenue should drop as your CS team becomes more operationally efficient',
      sequence: SEQUENCE.FOUNDATION,
    },
    TRUE_NPS_SCORE: {
      metric: 'True NPS Score',
      description: 'True NPS, on a scale of -100 to 100',
      sequence: SEQUENCE.FOUNDATION,
    },
    EXPANSION_REVENUE_SOURCED_BY_CS: {
      metric: 'Expansion Revenue Sourced by CS',
      description: 'The amount expansion revenue attributed to CS.',
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPANSION).inTop(3) &&
          $(d).isAssessmentAll().greaterThan(33)
        ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    PERCENT_OF_ARR_THAT_IS_MAU: {
      metric: 'Percent of ARR that is MAU',
      description: 'Monthly active users (MAU) will increase as a percent of annual recurring revenue (ARR).',
      rule: (d: ICompletedAssessment) => {
        return (
        $(d).isPriority(priorities.ADOPTION).inTop(3) &&
        $(d).isAssessmentAll().greaterThan(33)
      ) ? SEQUENCE.FOUNDATION: SEQUENCE.INTERMEDIATE;
      },
    },
    AVERAGE_ADOPTION_DEPTH: {
      metric: 'Average Adoption Depth',
      description: 'For license-based models, the percentage of licenses that are being used daily vs weekly vs monthly.',
      rule: (d: ICompletedAssessment) => {
        return (
        $(d).isPriority(priorities.ADOPTION).inTop(3) &&
        $(d).isAssessmentAll().greaterThan(33)
      ) ? SEQUENCE.INTERMEDIATE: SEQUENCE.ADVANCED;
      },
    },
    AVERAGE_ADOPTION_BREADTH: {
      metric: 'Average Adoption Breadth',
      description: 'If the product/solution has multiple features, how many of those features are being used in a healthy manner AND whether the right set of features are actively leveraged.',
      rule: (d: ICompletedAssessment) => {
        return (
        $(d).isPriority(priorities.ADOPTION).inTop(3) &&
        $(d).isAssessmentAll().greaterThan(33)
      ) ? SEQUENCE.INTERMEDIATE: SEQUENCE.ADVANCED;
      },
    },
    REVENUE_INFLUENCED_BY_ADVOCATES: {
      metric: 'Revenue Influenced by Advocates',
      description: 'Value of Closed Won Opportunities where a reference (or other type of proof point) was used',
      rule: (d: ICompletedAssessment) => {
        return (
        $(d).isPriority(priorities.EXPERIENCE).inTop(3) &&
        $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
        $(d).isAssessment('CUSTOPROCESS_WORFLOWSMER_DATA').greaterThan(33) &&
        $(d).isAssessment('MEASURES_METRICS').greaterThan(66) &&
        $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33)
      ) ? SEQUENCE.INTERMEDIATE: SEQUENCE.ADVANCED;
      },
    },
    NPS_CORRELATION_TO_RENEWAL_OUTCOME: {
      metric: 'NPS Correlation to Renewal Outcome',
      description: '% of closed-won renewals where an NPS promoter response was received within 6 months (either side) of renewal',
      rule: (d: ICompletedAssessment) => {
        return (
          $(d).isPriority(priorities.EXPERIENCE).inTop(3) &&
          $(d).isAssessmentAll().greaterThan(33)
      ) ? SEQUENCE.INTERMEDIATE : SEQUENCE.ADVANCED;
      },
    },
    PERCENTAGE_OF_CUSTOMERS_MANAGED: {
      metric: 'Percentage of Customers Managed',
      description: 'As CSM ratios improve and tech-touch strategies are implemented, a larger percentage of your customer base will be managed directly',
      rule: (d: ICompletedAssessment) => {
        return (
        $(d).isAssessment('CUSTOMER_DATA').greaterThan(33) &&
        $(d).isAssessment('CUSTOPROCESS_WORFLOWSMER_DATA').greaterThan(66) &&
        $(d).isAssessment('MEASURES_METRICS').greaterThan(33) &&
        $(d).isAssessment('SKILLS_RESOURCING').greaterThan(33) &&
        $(d).isPriority(priorities.SCALE).inTop(3)
      ) ? SEQUENCE.FOUNDATION : SEQUENCE.INTERMEDIATE;
      },
    },
  };
  return metrics;
}
