// import {MATURITY_LEVELS} from '@constants/logic';
// import {ASSESSMENT, MATURITY} from '@data/model/defaults';
import {AssessmentAnswers} from '@data/model/interfaces';


export const scaleTo5 = (v: number): number => {
  return Math.round((v / 20) * 10) / 10;
};

export function randomInteger(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function calculateMaturity(assessment: AssessmentAnswers) {
  throw Error('unused, unimplemented');

  // const categories = Object.keys(assessment) as ASSESSMENT[];

  // const sumOfAnswers: number = categories
  //     .reduce((a, category) => Number(a) + Number(assessment[category]), 0);

  // let maturity = MATURITY.Foundation;

  // if (sumOfAnswers > MATURITY_LEVELS.Intermediate) {
  //   maturity = MATURITY.Intermediate;
  // }
  // if (sumOfAnswers > MATURITY_LEVELS.Advanced) {
  //   maturity = MATURITY.Advanced;
  // }
  // return maturity;
}
