import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';

import {DEMO_FIELDS, DEMO_FIELDS_LABELS} from '@constants/fields';
import {FONTS, QUERIES, COLORS} from '@constants/style';
import {ErrorItem} from '@data/model/defaults';
interface AppProps {
  field: DEMO_FIELDS;
  error?: ErrorItem;
  style?: any;
}

export const FormCell = ({children, field, style, error}: PropsWithChildren<AppProps>) => {
  return (

    <Wrapper style={{gridArea: String(field), ...style}} >
      <CellLabel htmlFor={DEMO_FIELDS_LABELS[field]}>{DEMO_FIELDS_LABELS[field]}</CellLabel>
      {children}
      <ErrorRow>{error && error.status && error.msg}</ErrorRow>
    </Wrapper>

  );
};

const Wrapper = styled.div`  
  display: flex;
  flex-direction: column;  
`;

const CellLabel= styled.label`    
  /* border: 1px solid red; */
  padding-bottom: 8px;
  font-size: ${FONTS.FLEX_SMALL};
  @media ${QUERIES.MOBILE} {
    font-size: ${FONTS.FLEX_MEDIUM};
  }
  font-weight: 600;
  color: ${COLORS.DARKS.DARK_GREY};
`;

const ErrorRow = styled.div`
  /* border: 1px solid red; */
  padding-left: 6px;
  height: 20px;
  color: red;
`;
