import React from 'react';
import styled from 'styled-components';

import Button from '@components/Button';
import {COPY} from '@constants/copy';
import {COLORS} from '@constants/style';
import useHook from './useHook.ErrorHandler';


export const ErrorHandler = ({error}: any) => {
  const {onClick} = useHook();
  return (
    <Wrapper>
      <InnerWrapper>
        <h1>{COPY.ERROR_TITLE}</h1>
        <h4>{COPY.ERROR_SUBTITLE}</h4>
        <p>Message: {error.message}</p>
        <Button onClick={onClick}>{COPY.ERROR_CTA}</Button>
      </InnerWrapper>
    </Wrapper>
  );
};


const Wrapper = styled.div`    
    width: 100%;
    height: 66%;
    display: grid;
    place-content: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  p{
    color: ${COLORS.DARKS.DARK_GREY};
  }
`;
