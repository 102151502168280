/**
 * Option values for the dropdown menus in the Demographics form
 */

export const INDUSTRIES = [
  'Agriculture',
  'Home & Garden',
  'Mining',
  'Energy & Utilities',
  'Construction',
  'Food & Beverage',
  'Textiles',
  'Apparel',
  'Manufacturing',
  'Furniture',
  'Media & Entertainment',
  'Printing & Publishing',
  'Biotech',
  'Pharmaceuticals',
  'Consumer Goods & Services',
  'Automotive',
  'Hardware',
  'Recreation',
  'Software & Technology',
  'Business Services',
  'Retail & Distribution',
  'Telecommunications',
  'Aerospace & Defense',
  'Transportation & Logistics',
  'Healthcare & Medical',
  'Hospitality & Travel',
  'Real Estate',
  'Government',
  'Financial Services',
  'Education',
  'Associations',
  'Unclassified',
].sort();


export const EMPLOYEE_SIZE = [
  '1-10',
  '11-50',
  '51-100',
  '101-500',
  '501-1,000',
  '1001-5,000',
  '5001-10,000',
  '10,000+',
];

/**
 * Send a digit not a range to the backend
 */
export const EMPLOYEE_SIZE_VALUES = {
  '1-10': 10,
  '11-50': 50,
  '51-100': 100,
  '101-500': 500,
  '501-1,000': 1000,
  '1001-5,000': 5000,
  '5001-10,000': 10000,
  '10,000+': 100001,
};

/**
 * To display it correctly we need to convert it to a range
 */
export const REVERSE_EMPLOYEE_SIZE_VALUES = {
  10: '1-10',
  50: '11-50',
  100: '51-100',
  500: '101-500',
  1000: '501-1,000',
  5000: '1001-5,000',
  10000: '5001-10,000',
  100001: '10,000+',
};


export const CUSTOMER_SUCCESS_EMPLOYEES = [
  '1-10',
  '11-25',
  '26-50',
  '50+',
];

export const EMPLOYEES_USING_GAINSIGHT = [
  '1-10',
  '11-25',
  '26-50',
  '50+',
];

export const TEAMS_USING_GAINSIGHT = [
  'Customer Success',
  'Marketing',
  'Product Management',
  'Sales',
  'Services',
  'Technical Support',
];

export const JOB_TITLES = [
  'CEO/President',
  'CSM - Individual Contributor',
  'CTO/CIO',
  'Customer Success - Dir/VP/CCO',
  'Customer Success - Mgr',
  'Operations - Dir/VP/COO',
  'Product Management - Dir/VP/CPO',
  'Product Management - Individual Contributor',
  'Product Management - Mgr',
  'Professional Services - Dir/VP',
  'Sales - Dir/VP/CSO',
  'Other',
];
