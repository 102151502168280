import {useAppDispatch} from '@services/store/hooks';
import {ChangeEvent, useEffect, useState} from 'react';
import {DemoFormProps} from './DemographicsForm';
import {clearDataAsync} from '@services/store/thunks';
import {COPY} from '@constants/copy';
import {DEMO_FIELD_VALIDATOR} from '@constants/fields';
import {defaultDemographicsError} from '@data/model/defaults';
import {MAX_FIELD_LENGTH} from '@constants/config';


export default function useHook({data, onCompleted}: DemoFormProps) {
  const [demo, setDemo] = useState(data);
  const [errors, setErrors] = useState({...defaultDemographicsError});

  const dispatch = useAppDispatch();

  useEffect(() => {
    // see if any field has a null value (null is the default value in the model)
    const hasNulls = Object.values(demo).includes(null);

    // count the number of errors
    const errorCount = Object.values(errors).reduce((a, err) => a += err.status ? 1 : 0, 0);

    // Must have no nulls, and error count must be 0
    onCompleted(!hasNulls && errorCount === 0, demo );
  }, [demo, onCompleted]);

  useEffect(() => {
    setDemo(data);
  }, [data]);

  function update(field: string, value: any) {
    let v = value !== COPY.DEMO_DEFAULT_SELECT_VALUE ? String(value) : null;

    // support multiselect options
    if (v != null) {
      const v2 = (' ' + value).slice(1);
      v = v2.length === 0 ? null : value;
    }
    setDemo((prev) => {
      return {...prev, [field]: v};
    });
  }

  function onBlurTextInput(field: string ) {
    return (e: ChangeEvent<HTMLInputElement>) =>{
      const value: string = e.target.value;
      const cleanValue = cleanField(value);
      update(field, cleanValue);
      updateErrors(field, cleanValue);
    };
  }

  function onBlurEmailInput(field: string ) {
    return (e: ChangeEvent<HTMLInputElement>) =>{
      const value: string = e.target.value;
      const cleanValue = cleanField(value.toLowerCase());
      update(field, cleanValue);
      updateErrors(field, cleanValue);
    };
  }

  function updateErrors(field: string, cleanValue: string) {
    const validator = DEMO_FIELD_VALIDATOR[field as keyof typeof DEMO_FIELD_VALIDATOR];
    setErrors((prev) => {
      const localErrors = {...prev};
      localErrors[field as keyof typeof defaultDemographicsError] = validator(cleanValue);
      return localErrors;
    });
  }


  function onBlurSelect(field: string) {
    return (e: ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      const errorState = value === COPY.DEMO_DEFAULT_SELECT_VALUE || value === '';
      setErrors((prev) =>{
        prev[field as keyof typeof defaultDemographicsError].status = errorState;
        return {...prev};
      });
    };
  }

  function cleanField(value: string): string {
    return value
        .replace(/\s+/, ' ')
        .trim()
        .substring(0, MAX_FIELD_LENGTH);
  }

  function clear() {
    dispatch(clearDataAsync());
  }

  function handleMultiSelect(selected: string) {
    setDemo((prev) => {
      let local = [...prev.teams_using_gainsight || []];
      if (local.includes(selected)) {
        local = local.filter((item) => item !== selected);
      } else {
        local.push(selected);
      }

      setMultiSelectError(local.length === 0);

      return {...prev, teams_using_gainsight: local};
    });
  }

  function setMultiSelectError(isError: boolean) {
    setErrors((prev) => {
      prev.teams_using_gainsight.status = isError;
      return {...prev};
    });
  }

  return {demo, update, onBlurSelect, onBlurEmailInput, handleMultiSelect, onBlurTextInput, errors, clear};
}
