import React from 'react';

import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';

import Home from '@views/Home';
import Assess from '@views/Assess';
import Demographics from '@views/Demographics';
import Prioritize from '@views/Priorities';
import Results from '@views/Results';
import CookieConsent from '@components/CookieConsent';

import ScrollToTop from '../../misc/ScrollToTop';
import Analytics from 'ui/misc/Analytics';

import Data from '@views/DataSources';
import NotFound from '@views/NotFound';
import RouteGuard from 'ui/misc/RouteGuard';
import {ROUTE_BASE, PLAN_BASE, ROUTE_PATH, SURVEY_BASE, SECURE_BASE} from '@constants/routes';
import Secure from '@views/Secure';
import {Privacy, Terms} from '@views/Legal';
import LoadPlanById from '@views/Results/LoadPlanById';

export const Router = () => {
  return (
    <BrowserRouter>
      <CookieConsent />
      <RouteGuard />
      <Routes>
        <Route path={ROUTE_PATH.HOME} element={<Home/>} />
        <Route path={ROUTE_PATH.PRIVACY} element={<Privacy/>} />
        <Route path={ROUTE_PATH.TERMS} element={<Terms/>} />
        <Route path={SURVEY_BASE}>
          <Route index element={<Navigate replace to={ROUTE_PATH.DEMO} />} />
          <Route path={ROUTE_PATH.DEMO} element={<Demographics />} />
          <Route path={ROUTE_PATH.SOURCES} element={<Data />} />
          <Route path={ROUTE_PATH.ASSESS} element={<Assess/>} />
          <Route path={ROUTE_PATH.PRIORITIES} element={<Prioritize />} />
        </Route>
        <Route path={`${SECURE_BASE}/*`} element={<Secure />} />
        <Route path={`_${PLAN_BASE}/:id`} element={<LoadPlanById />} />
        <Route path={`${PLAN_BASE}/*`} element={<Results />} />
        <Route path={`${ROUTE_BASE}/*`} element={<NotFound/>} />
      </Routes>

      <ScrollToTop />
      <Analytics />

    </BrowserRouter>
  );
};
