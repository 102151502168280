
import React from 'react';
import Loading from '@components/Loading';
import Modal from '@components/Modal';
import {PageWrapper} from '@components/PageWrapper';
import {ROUTE_TOKEN} from '@constants/routes';
import {COLORS} from '@constants/style';
import Profile from '@views/Profile';
import {Navigate, Route, Routes} from 'react-router-dom';
import {Dashboard} from './Dashboard/Dashboard';
import {IncompleteData} from './IncompleteData/IncompleteData';
import LoadPlanById from './LoadPlanById';
import {NavPage} from './NavPage/NavPage';
import {PlanViewLater, PlanViewNow, PlanViewSoon} from './PlanView';
import RequestDemoForm from './RequestDemoForm';
import {IResultsProps, ResultsContext} from './useHook.Results';

export const ResultsRouter = ({isInitialized, isSecure, isCompleted, userDataModel, summaryModel, isShowModal, onModalOpen, onModalClose, updateNavigation}: IResultsProps) => {
  // make sure it's initialized before rendering
  if (!isInitialized) return <>isInit? <Loading /></>;

  // handle model loading
  if (!userDataModel || !summaryModel) return <Loading />;

  // handle model loading
  if (isCompleted === null) return <Loading />;
  if (isCompleted === false) return <IncompleteData/>;

  return (
    <ResultsContext.Provider value={{onModalClose, isSecure, userDataModel, summaryModel, onModalOpen, updateNavigation, isCompleted, isInitialized, isShowModal}}>
      <PageWrapper color={COLORS.LIGHTS.LIGHT_GREY} shadow showProfileButton>
        <Routes>
          <Route path='/' element={<NavPage />}>
            <Route index element={<Navigate replace to={`./${ROUTE_TOKEN.DASHBOARD}`} />} />
            <Route path={ROUTE_TOKEN.DASHBOARD} element={<Dashboard /> }/>
            <Route path={ROUTE_TOKEN.NOW} element={<PlanViewNow />}/>
            <Route path={ROUTE_TOKEN.SOON} element={<PlanViewSoon />} />
            <Route path={ROUTE_TOKEN.LATER} element={<PlanViewLater />} />
          </Route>
          <Route path={ROUTE_TOKEN.PROFILE} element={<Profile />} />
          <Route path='/:id' element={<LoadPlanById />} />
        </Routes>
      </PageWrapper>
      <Modal
        showDialog={isShowModal}
        close={onModalClose}
        label={'Request a Demo'}
        body={<RequestDemoForm />}
      />
    </ResultsContext.Provider>
  );
};


