import {FirebaseApp, initializeApp} from 'firebase/app';
import {Analytics, getAnalytics, logEvent} from 'firebase/analytics';
import {connectFirestoreEmulator, Firestore, getFirestore} from 'firebase/firestore';
import {Auth, connectAuthEmulator, getAuth} from 'firebase/auth';
import {ENV, ENVIRONMENTS} from '@constants/config';

import config from './FirebaseConfig';

/** Initialize Firebase **/
export let app: FirebaseApp;
export let db: Firestore;
export let auth: Auth;
export let analytics: Analytics;

function init() {
  app = initializeApp(config);
  db = getFirestore();
  auth = getAuth();

  // if running w/ local emulator running
  if (ENV === ENVIRONMENTS.EMULATOR) {
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectAuthEmulator(auth, 'http://localhost:9099');
  }
  analytics = getAnalytics();
}

export function logAnayltics(event: string, params?: any) {
  logEvent(analytics, event, params);
}

init();

