

import {ENV} from './config';

const BASE_URL = ENV === 'emulator' ? 'http://localhost:3000' : 'https://gainsight.valueplan.io';

const API_BASE_URL = ENV === 'emulator' ? 'http://localhost:5001/value-plan/us-central1' : 'https://gainsight.valueplan.io';

const API_BASE = `${API_BASE_URL}/api/v1`;

enum API_ROUTE {
  ASSESSMENT,
  DEMO
}

const API_URLS = {
  [API_ROUTE.ASSESSMENT]: `${API_BASE}/assessment`,
  [API_ROUTE.DEMO]: `${API_BASE}/demo`,
};

const REQUEST_DEMO_URL = 'https://info.gainsight.com/demo/';

const CALCULATOR_URL = 'https://www.gainsight.com/customer-success-metrics/';

export {
  API_ROUTE,
  BASE_URL,
  API_URLS,
  REQUEST_DEMO_URL,
  CALCULATOR_URL,
};

